import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import CarCard from "../widgets/CarCard";
import UspCard from "../widgets/UspCard";
import BlankCard from "../widgets/BlankCard";
import Usefull from "../snippets/Usefull";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (<div  role="tabpanel"
			      hidden={value !== index}
			      id={`vertical-tabpanel-${index}`}
			      aria-labelledby={`vertical-tab-${index}`}
			      style={{width: "100%"}}
			      {...other}
		    >
		      {value === index && (
		        <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
		          <Box sx={{fontSize: "14px"}}>{children}</Box>
		        </Box>
		      )}
			</div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};

function ModelContent(props) {
	let model = props.props.model;
 
	const { t, i18n } = useTranslation("",{lng: props.language});
	const [anim, setAnim] = React.useState("animate__animated animate__fadeInUp");
	const [anim1, setAnim1] = React.useState("animate__animated animate__fadeInUp");


  	let navigate = useNavigate(); 

  	const resetAnim = (event) => {
		setAnim("animate__animated animate__fadeOutDown");
		setAnim1("animate__animated animate__fadeOutRight ");
  	};  	

  	const handleChange = (event, newValue) => {
		setAnim("animate__animated animate__fadeInUp");
		setAnim1("animate__animated animate__fadeInRight animate__delay-1s animate__faster");
  	};
  	
	const [ref0, inView0] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	
  	
	
  	const getTab = (which) => {
		let tab;
		model.tabs.forEach((value, key) => {
			if(key == which)
		  		tab = value;
	  })
	  return tab.label;
  	};  	
  	
  	  	

  	return (<>
				<span ref={ref0} />

				<Box sx={{backgroundColor: "rgb(247, 250, 252)", marginTop: "30px"}}>
	  				<Box className="containerT" sx={{paddingTop: "0px", marginTop: "20px"}} > 
						<Swiper	direction= 'vertical'								        
								slidesPerView={1}
						        freeMode={true}
						        speed={1600}
						        pagination={{
						          clickable: true,
						          renderBullet: function (index, className) {
								    return '<span class="' + className + '"><span class="leftSpace"><nobr>' + getTab(index) + '</nobr></span></span>';
								  }
						        }}
						        onSlideChangeTransitionEnd={e => handleChange(e)}
						        onSlideChangeTransitionStart={e => resetAnim(e)}
						        
						        modules={[ Pagination]}
						        className="timeline"
						        style={{height: "700px",background: "#eee"}}
						      >
							{model.tabs.map((tab, id) => (
					        	<SwiperSlide style={{backgroundImage: "url('" +tab.heroImage+ "')", height: "600px;"}}>
									<div class="swiper-slide-content">
										<span class="timeline-year">{model.label}</span>
						            	<h4 class="timeline-title">{tab.label}</h4>
						            	<p class="timeline-text">{tab.description}</p>
						          	</div>	
										{id == 0 ? (<>											            	
														<Box  sx={{marginTop: "30px",  height: "100%"}}>
															<Box  sx={{position: "absolute", bottom: "10px"}}>
																{anim == "" ? (<></>) : (<>
																	<div class={anim} style={{ marginLeft: "10px"}}>
																		<Stack spacing={{ xs: 1, sm: 2, marginBottom: "10px", marginLeft: "10px" }} direction="row" useFlexGap flexWrap="wrap">

																				 <Card sx={{ minWidth: 550 }}>
																				     	<CardHeader 
																					        title={t("UnsereEinschätzungFürVerschiedeneSzenarien")}
																					        subheader={""}
																				      	/>
									      												<Box sx={{border: "0px solid #aaa"}}>
																							<Box sx={{paddingLeft: "30px"}}>
																								<Usefull rating={model.rating}/>
																							</Box>
																						</Box>	
																				      </Card>																											        		
																		</Stack >
																	</div >																
																</>
																)}
															</Box>
														</Box>
													</>) : (<></>)
										}


										{id == 1 ? (<>											            	
														<Box  sx={{marginTop: "30px",  height: "100%"}}>
															<Box  sx={{position: "absolute", bottom: "10px"}}>
																{anim == "" ? (<></>) : (
																	<div class={anim} style={{ marginLeft: "10px"}}>
																		<Stack spacing={{ xs: 1, sm: 2, marginBottom: "10px", marginLeft: "10px" }} direction="row" useFlexGap flexWrap="wrap">
																			{tab.usps.map((usp) => (
																				<UspCard title={usp.label} slogan={usp.slogan} image={usp.image} description={usp.description}/>
																			))}																							
																		</Stack >
																	</div >
																)}
															</Box>
														</Box>
													</>) : (<></>)
										 }
						          	
										{id == 2 ? (<>											            	
														<Box  sx={{marginTop: "30px",  height: "100%"}}>
															<Box  sx={{position: "absolute", bottom: "10px"}}>
																{anim == "" ? (<></>) : (<>
																	<div class={anim} style={{ marginLeft: "10px"}}>
																		<Stack spacing={{ xs: 1, sm: 2, marginBottom: "10px", marginLeft: "10px" }} direction="row" useFlexGap flexWrap="wrap">

																				 <Card sx={{ maxWidth: 345 }}>
																				     	<CardHeader 
																					        title={t("Grundriss")}
																					        subheader=""
																				      	/>
									      												<Box sx={{border: "0px solid #aaa"}}>

																							<ReactCompareSlider
																							  ref={{
																							    current: '[Circular]'
																							  }}
																							  boundsPadding={0}
																							  itemOne={<ReactCompareSliderImage alt="Image one" src={tab.imageDay}/>}
																							  itemTwo={<ReactCompareSliderImage alt="Image two" src={tab.imageNight} style={{backgroundColor: 'white', backgroundImage: '\n linear-gradient(45deg, #ccc 25%, transparent 25%),\n linear-gradient(-45deg, #ccc 25%, transparent 25%),\n linear-gradient(45deg, transparent 75%, #ccc 75%),\n linear-gradient(-45deg, transparent 75%, #ccc 75%)', backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px', backgroundSize: '20px 20px'}}/>}
																							  keyboardIncrement="5%"
																							  position={50}									
																							  transition=".75s ease-in-out"
																							/>
																						</Box>	
																				      	<CardContent>
																				        	<Typography variant="body2" color="text.secondary">
																				          		{t("Grundriss Tag / Nacht")}
																				        	</Typography>
																				      	</CardContent>
																				      </Card>																											        		
																		</Stack >
																	</div >																
																</>
																)}
															</Box>
														</Box>
													</>) : (<></>)
										}
						          	
						          </SwiperSlide>									
							))}					
						</Swiper>
		    		</Box>
				</Box>  	
			</>
   	)
}

export default function ModelFamilie(props) {
  return <ModelContent props={props}/>;
}