import {msgSend} from "../../helper/utils";


class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    console.log(message);
    const lowercase = message.toLowerCase();

    if (lowercase.includes("hello")) {
      this.actionProvider.greet();
    }

    else if (lowercase.includes("öffnungszeiten")) {
      this.actionProvider.storeOpenInfo();
    }

    else if (lowercase.includes("geöffnet")) {
      this.actionProvider.storeOpenInfo();
    }

    else if (lowercase.includes("lieferzeiten")) {
      this.actionProvider.deleiveryInfo();
    }

    else if (lowercase.includes("javascript") || lowercase.includes("js")) {
      this.actionProvider.handleJavascriptQuiz();
    }
    
    else {
	    let x = msgSend(lowercase);
		this.actionProvider.returnResult(x);
//		this.actionProvider.greet();
	}
    
    
  }
}

export default MessageParser;