import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


import ConfigurationTable from './partials/ConfigurationTable';


export default function ListLeads(props) {

  	return (<>
  				<Paper >
	  				<Box sx={{maxHeight: "80vH", minHeight: "80vH"}}>


						<ConfigurationTable />
		  					
				    </Box>	   			
				</Paper>

   			</>
  	);
}