import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import ServiceCard from '../widgets/ServiceCard';

export default function (props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	let navigate = useNavigate(); 
	
	const callUrl = (url) => {
		navigate(url);
		window.scrollTo(0, 0);
	};

  	return (
		  	<>		
				<section class="about">	  
					<Box className="box-container">
						<picture>
							<source media="(max-width: 390px)"
								       srcSet="assets/responsive/common/Camper-mit-rotem-Van-am-See.jpg"
								       sizes="390px"
								    />
							<source media="(max-width: 768px)"
								       srcSet="assets/responsive/Camper-mit-rotem-Van-am-See.jpg 768w"
								       sizes="768px"
								    />
							<source media="(max-width: 1024px)"
								       srcSet="assets/responsive/common/Camper-mit-rotem-Van-am-See.jpg"
								       sizes="1024px"
								    />



					  		<img src="/assets/responsive/common/Camper-mit-rotem-Van-am-See.jpg" />
	
						</picture>
			  			<Box className="content" sx={{width: {	xs: '70%',
																sm: '60%',  
																md: '30%',  
																lg: '20%',  
																xl: '20%',  																		
															},
													  maxWidth: {	xs: '20rem !important',
																sm: '20rem !important',  
																md: '40rem',  
																lg: '40rem',  
																xl: '40rem',  																		
															},
														clipPath: {	xs: 'none !important',
															},

													}}
						>
		  					<div class="text">
		  						<div class="heading">
		  							<center>
			  							<span>{t("ImeigenenCamper")}</span>
			  							<h3>{t("Urlaubindividuellerleben")}</h3>
			  						</center>
		  						</div> 			  				 
		  						<p>{t("descriptionBuyOwn")}</p> 
		  						<a class="btn headlineNewPoint" onClick={e => callUrl("/modelfamilie")}>{t("buttonBuyOwn")}</a> 
		  					</div>
			  			</Box>
				  	</Box>
					
					<center>
						<h2 style={{paddingTop: "40px"}} class="headlineNewPoint">{t("OurService")}</h2>
					</center>
										
					<Box sx={{	display: 'flex', 
								flexDirection: 'row', 
								pt: 2,display: {
									xs: 'none',
									sm: 'none',  
									md: 'block',  
									lg: 'block',  
									xl: 'block',  
									
							}  }}>
			      		<Grid container style={{paddingTop: "0px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<ServiceCard usp="1" header={t("Usp1Header")} description={t("Usp1Description")}/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<ServiceCard usp="2" header={t("Usp2Header")} description={t("Usp2Description")}/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<ServiceCard usp="3" header={t("Usp3Header")} description={t("Usp3Description")}/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<ServiceCard usp="4" header={t("Usp4Header")} description={t("Usp4Description")}/>					        				
					        			</Paper>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>		
					</Box>
					
					<Box sx={{	display: 'flex', 
								flexDirection: 'row', 
								pt: 2,display: {
									xs: 'block',
									sm: 'block',  
									md: 'none',  
									lg: 'none',  
									xl: 'none',  
									
							}  }}>
		        			<Paper >
		        				<ServiceCard usp="1" header={t("Usp1Header")} description={t("Usp1Description")}/>					        				
		        			</Paper>
		        			<Paper sx={{marginTop: 4}}>
		        				<ServiceCard usp="2" header={t("Usp2Header")} description={t("Usp2Description")}/>					        				
		        			</Paper>
		        			<Paper sx={{marginTop: 4}}>
		        				<ServiceCard usp="3" header={t("Usp3Header")} description={t("Usp3Description")}/>					        				
		        			</Paper>
		        			<Paper sx={{marginTop: 4}}>
		        				<ServiceCard usp="4" header={t("Usp4Header")} description={t("Usp4Description")}/>					        				
		        			</Paper>
					</Box>	
					
					
						
			  	</section>
		  </>
	  )
}