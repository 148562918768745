import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';

import DisplayBlockTeaser from "./DisplayBlockTeaser";
import DisplayBlogImgRight from "./DisplayBlogImgRight";
import DisplayBlogImgLeft from "./DisplayBlogImgLeft";
const heights = [320, 100, 230, 70, 330, 100, 320, 320];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function AboutCamping() {
  return (	<Box sx={{  minHeight: 677 }}>
      			<Masonry columns={3} spacing={{ xs: 1, sm: 2, md: 3 }}>
        			{heights.map((height, index) => (
				          <Item key={index} sx={{ height }}>
							{index == 0 ? (<DisplayBlockTeaser title="Dein Camping-Sommer" image="/assets/camping-sommer.jpg" /> ) : (<></>)}
							{index == 2 ? (<DisplayBlogImgRight title="Frischwasser im Camper"  image="/assets/freshwater.jpg"/>) : (<></>)}
							{index == 4 ? (<DisplayBlogImgLeft title="Rein mit der Campingbox & raus aus dem Alltag!"  image="/assets/campingbox.jpg"/>) : (<></>)}
							{index == 6 ? (<DisplayBlockTeaser title="Camping mit Hunden" image="/assets/camping-with-dog.jpg" />) : (<></>)}
							{index == 7 ? (<DisplayBlockTeaser title="*Frühlingserwachen*" image="/assets/spring.jpg" />) : (<></>)}
				          </Item>
			        ))}
      </Masonry>
    </Box>
  );
}