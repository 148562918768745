import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import TopContent from './TopContent';

export default function TopDrawer(props) {
  	const [state, setState] = React.useState({
    	top: false,
    	left: false,
    	bottom: false,
    	right: false,
  	});

	const change = (e, which) => {
		props.change(which)
	}

  	const toggleDrawer = (anchor, open) => (event) => {
    	if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    	  	return;
    	}

    	setState({ ...state, [anchor]: open });
	};




  	const list = (anchor, page) => (
		<>
	    	<Box
		      sx={{backgroundColor: "rgba(0,0,0,.8)",  width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
		      role="presentation"
		      onClick={toggleDrawer(anchor, false)}
		      onKeyDown={toggleDrawer(anchor, false)}
		    >
  			{props.embedded == "true" ? (<></>) : (
				<TopContent embedded={props.embedded}/>
				)}
		    </Box>
	    </>
  );

  return (<>		  	
  			{props.embedded == "true" ? (<></>) : (
		  	<Drawer	variant="persistent"
		            anchor="top"
		            open={props.show}
		            onClose={toggleDrawer("top", false)}
          	>
            	{list("top", props.page)}
          	</Drawer>
          	)}
		  </>
  );
}
