import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useInView } from "react-intersection-observer";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';


export default function Advice(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	let navigate = useNavigate(); 
	
	const [ref0, inView0] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});

	
  	return (<>
				<Box sx={{paddingTop: "40px", display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							}}}> 
					<Box class="fixBGAdvice">					
						<Grid container style={{paddingBottom: "20px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={2}>
						     	   </Grid>
					        		<Grid item xs={8}>
							      		<Grid container >
							        		<Grid item xs={12}>
									      		<Grid container spacing={2}>
									        		<Grid item xs={12}>
															<>
																<center>
																	<Box sx={{fontSize: "64px", color: "white", paddingTop: "110px"}} className="animate__animated animate__rubberBand">
																	Finden Sie jetzt ihren Reimo-Van
																	</Box>	
																	<Box sx={{fontSize: "48px", color: "white", paddingTop: "10px"}} className="animate__animated animate__jackInTheBox animate__delay-2s">
																	Vollausgebauter Minicamper oder Bus
																	</Box>	
																</center>
															</>
										     	   </Grid>
									     	   </Grid>
								     	   </Grid>
							     	   </Grid>
						     	   </Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>				
					</Box>					
				</Box>				


				<Box sx={{paddingTop: "40px", display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							}}}> 
					<Box class="fixBGAdvice">					
						<Grid container style={{paddingBottom: "20px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={12}>
							      		<Grid container >
											<Grid item xs={12}>
												<center>
													<Box sx={{fontSize: "64px", color: "white", paddingTop: "110px"}}
														 className="animate__animated animate__rubberBand">
														Finden Sie jetzt ihren Reimo-Van
													</Box>
													<Box sx={{fontSize: "48px", color: "white", paddingTop: "10px"}}
														 className="animate__animated animate__jackInTheBox animate__delay-2s">
														Vollausgebauter Minicamper oder Bus
													</Box>
												</center>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12}>
												<span ref={ref0}/>
												{inView0 === true ? (
													<>
														<center>
															<Box sx={{
																fontSize: "32px",
																color: "white",
																paddingTop: "10px"
															}} className="animate__animated animate__rubberBand">
																Need more BBQ in your life?
															</Box>
															<Box sx={{fontSize: "24px", color: "white", paddingTop: "10px"}} className="animate__animated animate__jackInTheBox animate__delay-2s">
																	enjoy camping!
																	</Box>	
																</center>
															</>
														) : (<></>)}
								     	   </Grid>
							     	   </Grid>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>				
					</Box>					
				</Box>				


			</>
	  )
}