import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Menu from './Menu';


export default function TopContent(props) {
  	let navigate = useNavigate(); 
	
	const callUrl = (url) => {
		navigate(url);
		window.scrollTo(0, 0);
	};


  return (<>		  	
		    	<Box sx={{	height: "100px", 
		    				padding: "0px", 
		    				backgroundColor: "rgba(100,100,100,0.5)", 
		    					display: {  xs: 'none',
											sm: 'none',  
											md: 'block',  
											lg: 'block',  
											xl: 'block',  							
							}}}
				>
		      		<Grid container spacing={2} sx={{padding: "0px"}}>
		        		<Grid item xs={3} sx={{paddingTop: 0}}>
							<a onClick={e => callUrl("/")}>
								<img src="/assets/logo-vanconcept.png" width="200" style={{marginLeft: "30px"}}/>
							</a>        		
				        </Grid>
			        	<Grid item xs={9}  sx={{ textAlign: "center1", float: "right" }}>
							<Menu embedded={props.embedded}/>
			        	</Grid>
		      		</Grid>      		
			    </Box>
		  </>
  );
}
