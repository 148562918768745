import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function UspCard(props) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
	  <Card sx={{ maxWidth: 345 }}>
     	<CardHeader 
        	action={
          		<IconButton aria-label="settings">
            		<MoreVertIcon />
          		</IconButton>
        	}
	        title={props.title}
	        subheader={props.slogan}
      	/>
		<div class="hover14 column">
			<div>
				<figure>
			    	<CardMedia 	component="img"
			        			height="194"
			        			image={props.image}
			        			alt="Paella dish"
			      	/>
		      	</figure>
		  	</div>
		</div>    
		      
      	<CardContent>
        	<Typography variant="body2" color="text.secondary">
          		{props.description}
        	</Typography>
      	</CardContent>
      	<CardActions disableSpacing>
        	<ExpandMore expand={expanded}
          				onClick={handleExpandClick}
          				aria-expanded={expanded}
          				aria-label="show more"
        	>
          		<ExpandMoreIcon />
        	</ExpandMore>
      	</CardActions>
      	<Collapse in={expanded} timeout="auto" unmountOnExit>
      		<CardContent>
        		<Typography paragraph>Method:</Typography>
          		<Typography paragraph>
            		Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            		aside for 10 minutes.
          		</Typography>
        	</CardContent>
      	</Collapse>
    </Card>
  );
}