import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

import { useTranslation } from 'react-i18next';

import EmailIcon from '@mui/icons-material/Email';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SettingsCellIcon from '@mui/icons-material/SettingsCell';



export default function ContactPersons(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	return (	<><Box className="contactBar" sx={{paddingTop: "0px", display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							}}}>
							{props.profil == "pan" ? (							<Box class="highlightContainer" style={{textAlign: "center", color: "#000",paddingTop: "10px"}}>					
								<h2 class="highlightText headlineNewPoint">{t("ReimoAnsprechpartner")}</h2>

								<Grid container style={{paddingBottom: "20px"}}>
			        				<Grid item xs={12}>
					      				<Grid container spacing={2}>
					        				<Grid item xs={4}>
						     	   			</Grid>
					        				<Grid item xs={4}>
												<Grid container style={{paddingBottom: "20px"}}>
									        		<Grid item xs={12}>
											      		<Grid container spacing={2}>
											        		<Grid item xs={6}>
																<h3 style={{padding: "0px", margin: "0px"}}>Rolf Stümper</h3>
																<ul class="empty" style={{listStyleType: "none", marginLeft: "0px"}}>
																	<li><EmailIcon sx={{fontSize: "1.5em"}}/><a href="mailto:info@reimo-pan.de">info@reimo-pan.de</a></li>
																	<li><PhoneInTalkIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 234 891 8913">+49 234 891 8913</a></li>
																</ul>
											        		</Grid>
											        		<Grid item xs={6} className="txtLeft">
											        			<Avatar sx={{width: "100px", height: "100px", border: "1px solid #000", backgroundColor: "#fff"}} alt="Rolf Stümper" src="/assets/rolf-stuemper.png" />
											        		</Grid>
						     	   						</Grid>
						     	   					</Grid>
					     	   					</Grid>
						     	   			</Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>
					</Box>

							
							) :(
							<Box class="highlightContainer" style={{textAlign: "center", color: "#000",paddingTop: "10px"}}>					
								<h2 class="highlightText headlineNewPoint">{t("ReimoAnsprechpartner")}</h2>

								<Grid container style={{paddingBottom: "20px"}}>
			        				<Grid item xs={12}>
					      				<Grid container spacing={2}>
					        				<Grid item xs={2}>
						     	   			</Grid>
					        				<Grid item xs={4}>
												<Grid container style={{paddingBottom: "20px"}}>
									        		<Grid item xs={12}>
											      		<Grid container spacing={2}>
											        		<Grid item xs={6}>
																<h3 style={{padding: "0px", margin: "0px"}}>Jan Schelper</h3>
																<ul class="empty" style={{listStyleType: "none", marginLeft: "0px"}}>
																	<li><EmailIcon sx={{fontSize: "1.5em"}}/><a href="mailto:jschelper@reimo.com">jschelper@reimo.com</a></li>
																	<li><PhoneInTalkIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 6103 4005-18">+49 6103 4005-18</a></li>
																	<li><SettingsCellIcon sx={{fontSize: "1.5em"}}/><a href="tel:+49 151-14664676">+49 151-146 646 76</a></li>
																</ul>
											        		</Grid>
											        		<Grid item xs={6} className="txtLeft">
											        			<Avatar sx={{width: "100px", height: "100px", border: "1px solid #000", backgroundColor: "#fff"}} alt="Jan Schelper" src="/assets/jan-schelper.png" />
											        		</Grid>
						     	   						</Grid>
						     	   					</Grid>
					     	   					</Grid>
						     	   			</Grid>
					        				<Grid item xs={4}>
												<Grid container style={{paddingBottom: "20px"}}>
									        		<Grid item xs={12}>
											      		<Grid container spacing={2}>
											        		<Grid item xs={6}>
																<h3 style={{padding: "0px", margin: "0px"}}>Andreas Stopp</h3>
																<ul className="empty" style={{listStyleType: "none", marginLeft: "0px"}}>
																	<li><EmailIcon sx={{fontSize: "1.5em"}}/><a href="mailto:astopp@reimo.com">astopp@reimo.com</a></li>
																	<li><PhoneInTalkIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 6103 4005-24">+49 6103 4005-24</a></li>
																	<li><SettingsCellIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 151 62415178">+49 151 624 151 78</a></li>
																</ul>											        			
											        		</Grid>
											        		<Grid item xs={6} className="txtLeft">
																<Avatar sx={{width: "100px", height: "100px", border: "1px solid #000", backgroundColor: "#fff"}} alt="Andread Stopp" src="/assets/andreas-stopp.png" />
											        		</Grid>
						     	   						</Grid>
						     	   					</Grid>
					     	   					</Grid>
						     	   			</Grid>
					        		<Grid item xs={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>
					</Box>
					)}
				</Box>
				
				<Box className="contactBar" sx={{paddingTop: "0px",paddingBottom: "3px", display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							}}}>
				        		<Grid item xs={12}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={8}>
						        		<center>
											<h3 style={{padding: "0px", margin: "0px"}}>Jan Schelper</h3>
											<ul class="empty" style={{listStyleType: "none", paddingLeft: "10px"}}>
												<li><EmailIcon sx={{fontSize: "1.5em"}}/><a href="mailto:jschelper@reimo.com">jschelper@reimo.com</a></li>
												<li><PhoneInTalkIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 6103 4005-18">+49 6103 4005-18</a></li>
												<li><SettingsCellIcon sx={{fontSize: "1.5em"}}/><a href="tel:+49 151-14664676">+49 151-146 646 76</a></li>
											</ul>
										</center>
						        		</Grid>
						        		<Grid item xs={4} className="txtLeft">
						        			<Avatar sx={{width: "100px", height: "100px", border: "1px solid #000", backgroundColor: "#fff"}} alt="Jan Schelper" src="/assets/jan-schelper.png" />
						        		</Grid>
	     	   						</Grid>
	     	   					</Grid>
							
				        		<Grid item xs={12} sx={{marginTop: "20px"}}>
						      		<Grid container spacing={2}>
						        		<Grid item xs={8}>
											<center>
											<h3 style={{padding: "0px", margin: "0px"}}>Andreas Stopp</h3>
											<ul className="empty" style={{listStyleType: "none", paddingLeft: "10px"}}>
												<li><EmailIcon sx={{fontSize: "1.5em"}}/><a href="mailto:astopp@reimo.com">astopp@reimo.com</a></li>
												<li><PhoneInTalkIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 6103 4005-24">+49 6103 4005-24</a></li>
												<li><SettingsCellIcon sx={{fontSize: "1.5em"}}/><a href="tel: +49 151 62415178">+49 151 624 151 78</a></li>
											</ul>	
											</center>										        			
						        		</Grid>
						        		<Grid item xs={4} className="txtLeft">
											<Avatar sx={{width: "100px", height: "100px", border: "1px solid #000", backgroundColor: "#fff"}} alt="Andread Stopp" src="/assets/andreas-stopp.png" />
						        		</Grid>
	     	   						</Grid>
	     	   					</Grid>
							<br/><br/><br/>
				</Box>								
				</>
	  )
}