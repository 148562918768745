import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';


import {formatNumber} from "../helper/utils";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ModelProductCard(props) {
	const [expanded, setExpanded] = React.useState(false);
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const handleExpandClick = () => {
    	setExpanded(!expanded);
  	};

  	return (
		<Card sx={{ minWidth: 240 }}>
	    	<CardHeader title={props.item.label}
	        			subheader={props.item.slogan}
			/>
	      	{props.item.image ? ( 
				<picture>
					{props.item.image.map((image)  => (			
						<img src={image.src}/>
					))}
				</picture>
				) : (<></>)
			}
			<CardContent>
		  		<Stack  direction="row" justifyContent="space-between" alignItems="center">
	        		<Typography gutterBottom variant="body2" component="div">
	            		{props.item.priceLabel}
	          		</Typography>
	          		<Typography gutterBottom variant="body2" component="div">
	          			{formatNumber("de_DE", props.item.price)}
	          		</Typography>
	        	</Stack>
			</CardContent>
	      	<CardActions disableSpacing>
				<Checkbox 	data-type="carRoofs" 
							checked={props.checked}
							onClick={e => props.changeItem(e, props.uuid, props.currentArtnr)} />
	        	<ExpandMore	expand={expanded}
	          				onClick={handleExpandClick}
	          				aria-expanded={expanded}
	          				aria-label="show more"
	        	>
	          		<ExpandMoreIcon />
	        	</ExpandMore>
			</CardActions>
      		<Collapse in={expanded} timeout="auto" unmountOnExit>
        		<CardContent>
          			<Typography paragraph>{t("Zusatzinfo")}:</Typography>
          			<Typography paragraph>
						{props.item.description}
          			</Typography>
          
        	</CardContent>
      	</Collapse>
      </Card>
  );
}