import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs   } from "react-pdf";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Toaster from './Toaster';

import {formatBytes} from "../../../helper/utils";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;





const columns = [
	{ field: 'id', headerName: 'ID', width: 90 },
  	{
    	field: 'used',
    	headerName: 'Verwendung',
    	width: 150,
    	editable: false,
  	},
  	{
    	field: 'preview',
    	headerName: 'Vorschau',
    	width: 100,
    	editable: false,
		renderCell: (params) => {
		        return (
		          <>
					{params.formattedValue.split(/[#?]/)[0].split('.').pop().trim() == "pdf" ? (
						<div class="thumbnailPdf" style={{overflow: "hide", height: "50px", width: "35px"}}>					                                       		
				            <Document class="thumbnailPdf" file={params.formattedValue}
				            >
				                <Page 	pageNumber={1}  
				            			style={{height: "50px"}}
			
				                		renderTextLayer={false}/>
				            </Document>          
			          	</div>
						) :(
							<img src={params.formattedValue} style={{height: "48px"}}/>			
						)	          	
		          	}
		          </>			
        );
      }    	
    	
  	},
  	{
    	field: 'name',
    	headerName: 'Filename',
    	width: 300,
    	editable: false,
  	},
  	{
    	field: 'path',
    	headerName: 'Pfad',
    	width: 500,
    	editable: false,
  	},
  	{
    	field: 'size',
    	headerName: 'Größe',
    	width: 100,
    	editable: false,
  	},
  	{
    	field: 'date',
    	headerName: 'Datum',
    	width: 100,
    	editable: false,
  	},
  	{
    	field: 'author',
    	headerName: 'Author',
    	width: 100,
    	editable: false,
  	},
];

export default function FilesTable(props) {

	const files = props.files;

	const [openMsg, setOpenMsg] = React.useState(false);
	const [toasterMsg, setToasterMsg] = React.useState("");	
  	const [iso, setIso] = React.useState('de');
  	const [isoDest, setIsoDest] = React.useState('all');
  	const [openBD, setOpenBD] = React.useState(false);
  	const [type, setType] = React.useState("missing");
  	const [openChooseLng, setOpenChooseLng] = React.useState(false);
	const { t, i18n } = useTranslation("");
  
	let r = [];

	Object.keys(files).forEach(function (key, i){
		files[key].forEach((file, index) => {
			let o = {};
			o.id = "" + i + index;
			o.used = key;

			o.preview = file.path;
			o.name = file.name;
			o.path = file.path;
			o.size = formatBytes(file.size);
			o.date = file.date;
			o.author = file.author;
			r.push(o);
		})
	});
	
/*	
	files.forEach((file, index) => {
		let o = {};
		o.id = index;
		o.key = file.name;
		o.value = file.path;
		r.push(o);
	})
*/	
  	const [rows, setRows] = React.useState(r);
/*  	
  	const handleChange = (event) => {
    	setIso(event.target.value);

		let r1 = [];
		Object.keys(initR[event.target.value].translation).forEach((key, index) => {
			let o = {};
			o.id = index;
			o.key = key;
			o.value = initR[event.target.value].translation[key];
			r1.push(o);
		})
    	setRows(structuredClone(r1));
  	};
*/  	
  	const handleCloseChooseLng = () => {
    	setOpenChooseLng(false);
  	};
  	
  	const handleIsoDest = (event) => {
    	setIsoDest(event.target.value);
  	};
  	
  	const handleType = (event) => {
    	setType(event.target.value);
  	};


	const handleProcessRowUpdate = (updatedRow, oldRow) => {
/*
		initR.de.translation[updatedRow.key] = updatedRow.value; 
	  	const rowIndex = rows.findIndex((row) => row.id === updatedRow.id);
	  	const updatedRows = [...rows];
	  	updatedRows[rowIndex] = updatedRow;
	  	setRows(updatedRows);
	  	return updatedRow;
*/	

	}
	return (<>
				<Box sx={{ height: '60vH', width: '100%' }}>
					<DataGrid 	rows={rows}
	        					columns={columns}
								initialState={{
	          						pagination: {
	            						paginationModel: {
	              							pageSize: 10,
	            						},
	          						},
	        					}}
	        					processRowUpdate={handleProcessRowUpdate}
								pageSizeOptions={[10]}
	        					disableRowSelectionOnClick
	      			/>
	      			<hr/>
					<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
				    	<Typography gutterBottom variant="h6" component="div">

		      			</Typography>
				        <Typography gutterBottom variant="h6" component="div">
				       	</Typography>
				  	</Stack>      
	    		</Box>

				<Dialog open={openChooseLng}
        				onClose={handleCloseChooseLng}
        				
      			>
        			<DialogTitle>Auswahl</DialogTitle>
        			<DialogContent sx={{minWidth: "640px", minHeight: "320px"}}>
        			</DialogContent>
        			<DialogActions>
          				<Button onClick={handleCloseChooseLng}>Abbruch</Button>
        			</DialogActions>
      			</Dialog>	    		
	    		
	    		
				<Toaster open={openMsg} msg={toasterMsg} handleClose={setOpenMsg}/>
		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>  				
			</>
  );
}