import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import CompareIcon from '@mui/icons-material/Compare';
import models from '../db/models.json';

import 'swiper/css';
import 'swiper/css/navigation';

export default function (props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	const [ leftCar, setLeftCar] = React.useState("caddy-camp2");
  	const [ rightCar, setRightCar] = React.useState("caddy-weekender2");

  	let navigate = useNavigate(); 


	
	const callUrl = (url) => {
		window.scrollTo(0, 0);
		let data = {};
		data.leftCar = leftCar;
		data.rightCar = rightCar;
		navigate(url, {state: data});
	};

  	return (
		  	<>		
				<center>
					<h2 style={{paddingTop: "40px"}} class="headlineNewPoint">{t("VergleichenSlogan")}</h2>
				</center>
				<Box sx={{display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							}}}>
				<Grid container >
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={2}>
							</Grid>
							<Grid item xs={8}>

								<Grid container >
									<Grid item xs={12}>
										<Grid container spacing={2}>
											<Grid item xs={5} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)", borderRadius: "5px"}}>
		
												<Swiper style={{height: "360px"}}
														direction={'vertical'}
												        slidesPerView={1}
												        spaceBetween={30}
														navigation={true}
														onRealIndexChange={(e) => {
														        setLeftCar(models.cars[e.activeIndex].url);
														      }}

												        pagination={{
												          clickable: true,
												        }}
												        modules={[ Navigation]}
		
												>
													{models.cars.map((model) => (
											        	<SwiperSlide>
											        	
											        		<center>
																<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >
																	<img src={model.imagePlain} width="300"/>
			
													      			<CardContent sx={{minHeight: "40px"}}>
																        <Typography variant="h5" color="text.secondary">
																			{model.label}
																        </Typography>
																	</CardContent>
			
												        	
												        		</Card>
												        	</center>

											        	
				
											        	</SwiperSlide>
											        ))}
		
												</Swiper>		
		
											</Grid>		
											
											<Grid item xs={2}>
											</Grid>		
																							
											<Grid item xs={5} sx={{backgroundColor: "hsla(0,0%,94.9%,.5)",borderRadius: "5px"}}>
		
												<Swiper style={{height: "360px"}}
														direction={'vertical'}
												        slidesPerView={1}
												        initialSlide="1"
												        spaceBetween={30}
														navigation={true}
														onRealIndexChange={(e) => {
														        setRightCar(models.cars[e.activeIndex].url);
														      }}
														
												        pagination={{
												          clickable: true,
												        }}
												        modules={[ Navigation]}
		
												>
													{models.cars.map((model) => (
											        	<SwiperSlide>
											        		<center>
																<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >
																	<img src={model.imagePlain} width="300"/>
			
													      			<CardContent sx={{minHeight: "40px"}}>
																        <Typography variant="h5" color="text.secondary">
																			{model.label}
																        </Typography>
																	</CardContent>
			
												        	
												        		</Card>
												        	</center>
				
											        	</SwiperSlide>
											        ))}
		
												</Swiper>	
		
		
											</Grid>														
										</Grid>														
									</Grid>														
								</Grid>


							</Grid>
							<Grid item xs={2}>
							</Grid>

						</Grid>
					</Grid>
				</Grid>
				<br/><br/>						
				<center>
        			<Button aria-label="add to favorites"
        					variant="contained"
							onClick={e => callUrl("/compare")}
        					endIcon={<CompareIcon />}>
          				{t("Vergleichen")}
        			</Button>
        		</center>	
        		</Box>	
				<Box sx={{display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							}}}>						

								<Swiper style={{height: "360px"}}
										direction={'vertical'}
								        slidesPerView={1}
								        spaceBetween={30}
										navigation={true}
										onRealIndexChange={(e) => {
										        setLeftCar(models.cars[e.activeIndex].url);
										      }}

								        pagination={{
								          clickable: true,
								        }}
								        modules={[ Navigation]}

								>
									{models.cars.map((model) => (
							        	<SwiperSlide>
							        	
							        		<center>
												<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >
													<img src={model.imagePlain} width="300"/>

									      			<CardContent sx={{minHeight: "40px"}}>
												        <Typography variant="h5" color="text.secondary">
															{model.label}
												        </Typography>
													</CardContent>

								        	
								        		</Card>
								        	</center>

							        	

							        	</SwiperSlide>
							        ))}

								</Swiper>	
									
								<Swiper style={{height: "360px"}}
										direction={'vertical'}
								        slidesPerView={1}
								        initialSlide="1"
								        spaceBetween={30}
										navigation={true}
										onRealIndexChange={(e) => {
										        setRightCar(models.cars[e.activeIndex].url);
										      }}
										
								        pagination={{
								          clickable: true,
								        }}
								        modules={[ Navigation]}

								>
									{models.cars.map((model) => (
							        	<SwiperSlide>
							        		<center>
												<Card sx={{border: "none", boxShadow: "none", backgroundColor: "inherit", paddingTop: "30px"}} >
													<img src={model.imagePlain} width="300"/>

									      			<CardContent sx={{minHeight: "40px"}}>
												        <Typography variant="h5" color="text.secondary">
															{model.label}
												        </Typography>
													</CardContent>

								        	
								        		</Card>
								        	</center>

							        	</SwiperSlide>
							        ))}

								</Swiper>
					<center>
	        			<Button aria-label="add to favorites"
	        					variant="contained"
								onClick={e => callUrl("/compare")}
	        					endIcon={<CompareIcon />}>
	          				{t("Vergleichen")}
	        			</Button>
	        		</center>	
												
													

        		</Box>	
						
						
												
		  </>
	  )
}