import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import CompareIcon from '@mui/icons-material/Compare';


export default function ServiceCard(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	let navigate = useNavigate(); 

	const callUrl = (url) => {
		window.scrollTo(0, 0);
		let data = {};
		data.leftCar = props.leftcar;
		data.rightCar = props.rightcar;
		navigate(url, {state: data});
	};
  	

  	return (
<>
			<Card sx={{ maxWidth: 345,  
						border: "none", 
						boxShadow: "none", 
					    height: "100%",
					    display: "flex",
					    flexDirection: "column",
						
						}} 
					className="centerCarCard"
				>

      			<CardContent sx={{minHeight: "40px"}}>
			        <Typography variant="h5" color="text.secondary">
Beide Autos vergleichen.
			        </Typography>
			        
        			<Button aria-label="add to favorites"
        					variant="contained"
        					onClick={e => callUrl("/compare")}
        					endIcon={<CompareIcon />}>
          				{t("vergleichen")}
        			</Button>
				</CardContent>

    		</Card>
    		</>
  );
}