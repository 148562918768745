import React from "react";
import Chatbot from "react-chatbot-kit";
import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

import config from "./configs/config";
import ActionProvider from "./configs/ActionProvider";
import MessageParser from "./configs/MessageParser";

function Campy(props) {

  	return (<>
  				{props.expanded === true ? (
			  		<Box role="presentation"
					     sx={{
					            position: "fixed",
					            bottom: 50,
					            right: 120,
					            zIndex: 10000,
					            bgcolor: 'ochre.main'
				     		}}
				  	>
						<IconButton variant="contained"
									onClick={e => props.setChatStatus(false)}
									sx={{	position: "fixed",
								            right: 100,
						           			zIndex: 10005,
						           			marginTop: "-20px",
						           			border: "1px solid black",
						           			bgcolor: 'info.main',
								      '&:hover': {
								        bgcolor: 'info.main',
								      }
									}}
						>			
								<CloseIcon sx={{fontSize: '20px', color: 'white'}}/>
						</IconButton>
						<div style={{ maxWidth: "380px", border: "1px solid rgba(0,0,0,.4)" }}>
							        <Chatbot  config={config}
									          actionProvider={ActionProvider}
									          messageParser={MessageParser}
							        />

						</div>
					</Box>
				) : (<></>)}
			</>
  			);
}

export default Campy;