import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";


import AnchorIcon from '@mui/icons-material/Anchor';
import BuildIcon from '@mui/icons-material/Build';
import EngineeringIcon from '@mui/icons-material/Engineering';
import RecommendIcon from '@mui/icons-material/Recommend';

export default function ServiceCard(props) {
	const [expanded, setExpanded] = React.useState(false);
  	let navigate = useNavigate(); 


	const callModel = (url) => {
		navigate(url);
	};


  	return (
			<Card sx={{ border: "none", boxShadow: "none",
			maxWidth: {
							xs: 245,
							sm: 245,  
							md: 245,  
							lg: 245,  
							xl: 290,  							
							},
			minHeight: {
							xs: 245,
							sm: 245,  
							md: 245,  
							lg: 245,  
							xl: 200,  
							
							}
							
			
			
			}} className="centerCarCard">
				<Box sx={{width: "40px", height: "40px", float: "right"}}>
					{props.usp == 1 ? (<AnchorIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
					{props.usp == 2 ? (<EngineeringIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
					{props.usp == 3 ? (<BuildIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
					{props.usp == 4 ? (<RecommendIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
					
				</Box>

      			<CardHeader avatar={
					           <Avatar sx={{ bgcolor: "#fff" }} aria-label="recipe" >
					          </Avatar>
					        }
					        titleTypographyProps={{variant:'h6' }}
        					title={props.header}
							subheader=""
      			/>
      			<CardContent>
			        <Typography variant="body2" color="text.secondary">
						{props.description}
			        </Typography>
				</CardContent>
    		</Card>
  );
}