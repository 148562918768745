import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';


export default function Footer(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	return (<>
	  			<Box style={{background: "#383850", borderTop: "2px solid #000", marginTop: "10px", height: "200px"}}>
					<Box sx={{ flexGrow: 1, display: {
								xs: 'block',
								sm: 'block',  
								md: 'none',  
								lg: 'none',  
								xl: 'none',  
								
								} }}
					>
						<center>		

									<h3 style={{color: "#fff", marginTop: "5px", marginBottom: "5px"}}>Service Hotline</h3>
									<Box style={{color: "#fff", fontSize: "16px"}}>{t("BestellannahmeundAuftragsbearbeitung")}</Box>
									<Box style={{color: "#fff", fontSize: "16px"}}> + 49 6150 - 8662 330  10:00 - 17:00</Box>
							<hr class="gradieantSpacer"/>
						</center>

					</Box>

					<Box sx={{ flexGrow: 1, display: {
								xs: 'none',
								sm: 'none',  
								md: 'block',  
								lg: 'block',  
								xl: 'block',  
								
								} }}
					>
			 			<Grid container style={{height: "160px"}}>
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={1}>
									</Grid>
									<Grid item xs={3}>
									<h3 style={{color: "#fff", marginTop: "5px", marginBottom: "5px"}}>Service Hotline</h3>
									<Box style={{color: "#fff", fontSize: "16px"}}>{t("BestellannahmeundAuftragsbearbeitung")}</Box>
									<Box style={{color: "#fff", fontSize: "16px"}}> + 49 6150 - 8662 330  10:00 - 17:00</Box>
									</Grid>
									<Grid item xs={4}>
									x
									</Grid>
									<Grid item xs={3}>
										<ul class="empty" style={{listStyleType: "none", marginLeft: "0px;", fill: "white"}}>
											<li style={{float: "left",marginLeft: "5px"}}> 
												<a href="https://de-de.facebook.com/ReimoCom/">
													<img class="socialIcon" src="/assets/addons/facebook.png"/>
												</a>
											</li>
											<li style={{float: "left"}}> 
												<a href="https://www.instagram.com/reimo_com/">
													<img class="socialIcon" src="/assets/addons/instagram.png"/>
												</a>
											</li>				
											<li style={{float: "left"}}> 
												<a href="https://www.youtube.com/channel/UC1PqbYUdp8drXIb7yF5DMZw">
													<img class="socialIcon" src="/assets/addons/youtube.png"/>
												</a>
											</li>				
											<li style={{float: "left"}}> 
												<a href="https://www.pinterest.de/reimo_com/">
													<img class="socialIcon" src="/assets/addons/pinterest.png"/>
												</a>
											</li>				
											<li style={{float: "left"}}> 
												<a href="https://www.linkedin.com/company/reimo-reisemobil-center-gmbh">
													<img class="socialIcon" src="/assets/addons/linkedin.png"/>
												</a>
											</li>				
											<li style={{float: "left"}}> 
												<a href="https://twitter.com/reimo_com">
													<img class="socialIcon" src="/assets/addons/twitter.png"/>
												</a>
											</li>				
										</ul>
									</Grid>
									<Grid item xs={1}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<center>		
							<hr class="gradieantSpacer"/>
						</center>
					</Box>
				</Box>
			</>
	  )
}