import React from 'react';
import { useInView } from "react-intersection-observer";
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import Header from "./common/Header2";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ContactPersons from "../widgets/ContactPersons";
import RecommendationCard from "../widgets/RecommendationCard";

import models from '../db/models.json';


const images = [
  'Slide1HeroConsiderations',
  'Slide1HeroConsiderations',
  'Slide1HeroConsiderations'
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
    	id: `vertical-tab-${index}`,
    	'aria-controls': `vertical-tabpanel-${index}`,
  	};
}

function CampingContent(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
  	const [value, setValue] = React.useState(0);
	const [anim, setAnim] = React.useState("animate__animated animate__fadeInUp");




	const [ref0, inView0] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref1, inView1] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref2, inView2] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref3, inView3] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref4, inView4] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref5, inView5] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref6, inView6] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref7, inView7] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	const [ref8, inView8] = useInView({
	    triggerOnce: true,
	    rootMargin: '0px 0px',
	});
	



  	const resetAnim = (event) => {
		setAnim("animate__animated animate__fadeOutDown");
  	};  	

  	const handleChange = (event, newValue) => {
		setAnim("animate__animated animate__fadeInUp");
  	};  	

  	const getModel = (which) => {
		let model;
		models.cars.forEach((value, keys) => {
			console.log(keys);
			if(value.url == which)
		  		model = value;
	  })
	  return model;
  	};
  	
  	return (<>
			  	<Header heroImages={images} label={t("ÜberlegungenbeimKauf")} slogan={t("WoraufSieachtensollten")} breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>

				<Box sx={{ flexGrow: 1 }}
				>
	  				<Box  sx={{paddingTop: "0px", marginTop: "0px"}} > 
						<IntroDescriptionPage description={t("KaufberatungDescription")}/>

			      		<Grid container style={{paddingBottom: "20px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={1}>
						     	   </Grid>
					        		<Grid item xs={10}>
										<div class="ps-section__content" style={{marginBottom: "20px"}}>
						        			<span ref={ref1} />
											<div class="ps-block--vendor-milestone">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungFahrzeugHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungFahrzeug")}
													</Typography>

												</div>
												<div class="ps-block__right">
													{inView1 === true ? (<>
															<Box className="animate__animated animate__slideInRight">
																<Stack direction="row" spacing={2}>	
																	<RecommendationCard title="sfgsdf" slogan="ewrtwert" image="/assets/items/grills.jpg" description="tralala"/>																			
																	<RecommendationCard title="sfgsdf" slogan="ewrtwert" image="/assets/items/grills.jpg" description="tralala"/>																			
																	<RecommendationCard title="sfgsdf" slogan="ewrtwert" image="/assets/items/grills.jpg" description="tralala"/>																			
																</Stack>
															</Box>												
													</>) : (<></>)}												
												</div>
												<div class="ps-block__number">
													<span>1</span>
												</div>
											</div>
						        			<span ref={ref2} />
											<div class="ps-block--vendor-milestone reverse">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungSchlafbankHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungSchlafbank")}
													</Typography>

												</div>
												<div class="ps-block__right">
													{inView2 === true ? (<>
														<Box className="animate__animated animate__slideInLeft" style={{textAlign: "left"}}>	
															<RecommendationCard title="Sitzbank Variotech® 3000" slogan="Verschiebbar somit flexibel" image="/assets/basecars/bank-triostyle.jpeg" description="tralala"/>																			
														</Box>												
													</>) : (<></>)}												
												</div>
												<div class="ps-block__number">
													<span>2</span>
												</div>
											</div>
						        			<span ref={ref3} />
											<div class="ps-block--vendor-milestone">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungKücheHeader")}</h2>
										        	<Typography variant="p" color="text.secondary">
														{t("KaufberatungKüche")}
													</Typography>
												

												</div>
												<div class="ps-block__right">
													{inView3 === true ? (<>
														<Box className="animate__animated animate__slideInRight" style={{textAlign: "left"}}>	
															<RecommendationCard title="Entnehmbare Küche" slogan="" image="/assets/basecars/kueche-weekender.jpg" description="Besonders macht den Weekender vor allem das optionale ausschwenk- und entnehmbare Küchenmodul. Bei schönem Wetter können Sie den Küchenblock einfach heraus schwenken und ihr Essen im Freien zubereiten. So bleibt der Fahrzeuginnenraum frei von Essensgerüchen."/>																			
														</Box>												
													</>) : (<></>)}												
												</div>
												<div class="ps-block__number">
													<span>3</span>
												</div>
											</div>
						        			<span ref={ref4} />
											<div class="ps-block--vendor-milestone reverse">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungSchlafdachHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungSchlafdach")}
													</Typography>

												</div>
												<div class="ps-block__right">
													{inView4 === true ? (<>
														<Box className="animate__animated animate__slideInLeft">	
															<RecommendationCard title="Entnehmbare Küche" slogan="" image="/assets/basecars/kueche-weekender.jpg" description="Besonders macht den Weekender vor allem das optionale ausschwenk- und entnehmbare Küchenmodul. Bei schönem Wetter können Sie den Küchenblock einfach heraus schwenken und ihr Essen im Freien zubereiten. So bleibt der Fahrzeuginnenraum frei von Essensgerüchen."/>																			
														</Box>												
													</>) : (<></>)}												
												</div>
												<div class="ps-block__number">
													<span>4</span>
												</div>
											</div>
						        			<span ref={ref5} />											
											<div class="ps-block--vendor-milestone">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungStauraumHeader")}</h2>
										        	<Typography variant="p" color="text.secondary">
														{t("KaufberatungStauraum")}
													</Typography>
												</div>
												<div class="ps-block__right">
													{inView5 === true ? (<>
														<Box className="animate__animated animate__slideInRight">	
															<img src="https://reactstorefronts.com/static/img/vendor/milestone-3.png" alt="martfury"/>																			
														</Box>												
													</>) : (<></>)}	
													
												</div>
												<div class="ps-block__number">
													<span>5</span>
												</div>
											</div>
						        			<span ref={ref6} />
											<div class="ps-block--vendor-milestone reverse">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungHeizungHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungHeizung")}
													</Typography>


												</div>
												<div class="ps-block__right">
													{inView6 === true ? (<>
															<Box className="animate__animated animate__slideInLeft" style={{textAlign: "left"}}>	
																<RecommendationCard title="Diesel Standheizung - 4 KW" slogan="tbd" image="/assets/addons/48183_schwarz.jpeg" description="tralala"/>																			
															</Box>											
													</>) : (<></>)}	
												
												</div>
												<div class="ps-block__number">
													<span>6</span>
												</div>
											</div>
						        			<span ref={ref7} />											
											<div class="ps-block--vendor-milestone">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungStromHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungStrom")}
													</Typography>
												</div>
												<div class="ps-block__right">
													{inView7 === true ? (<>
														<Box className="animate__animated animate__slideInRight">
															<Stack direction="row" spacing={2}>	
																<RecommendationCard title="Sinus Inverter" slogan="ewrtwert" image="/assets/addons/m82277.jpeg" description="tralala"/>																			
																<RecommendationCard title="Solar Komplettset" slogan="ewrtwert" image="/assets/addons/85303.jpeg" description="tralala"/>																			
															</Stack>
														</Box>												
													</>) : (<></>)}	
												
												</div>
												<div class="ps-block__number">
													<span>7</span>
												</div>
											</div>			
						        			<span ref={ref8} />								
											<div class="ps-block--vendor-milestone reverse">
												<div class="ps-block__left">
													<h2 class="highlightText headlineNewPoint">{t("KaufberatungFensterHeader")}</h2>
										        	<Typography variant="p" color="text.secondary" className="kaufberatungFliess">
														{t("KaufberatungFenster")}
													</Typography>

												</div>
												<div class="ps-block__right">
													{inView8 === true ? (<>
														<Box className="animate__animated animate__slideInLeft">
															<Stack direction="row" spacing={2}>	
																<RecommendationCard title="Fenster" slogan="ewrtwert" image="/assets/addons/fenster.jpg" description="tralala"/>																			
																<RecommendationCard title="Licht" slogan="ewrtwert" image="/assets/items/grills.jpg" description="tralala"/>																			
																<RecommendationCard title="Verdunklung" slogan="ewrtwert" image="/assets/addons/verdunlung.jpg" description="tralala"/>																			
															</Stack>
														</Box>												
													</>) : (<></>)}	
												</div>
												<div class="ps-block__number">
													<span>8</span>
												</div>
											</div>
																						
										</div>											
						     	   	</Grid>
					        		<Grid item xs={1}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>																		
					</Box>

	  				<Box sx={{paddingTop: "40px"}}> 
						<ContactPersons profil={props.props.profil}/>
					</Box>
   			</Box>
   		</>
   	)
}

export default function Considerations(props) {
  return <CampingContent props={props}/>;
}