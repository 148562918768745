import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
  	return <IconButton {...other} />;
	})(({ theme, expand }) => ({
  		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		    duration: theme.transitions.duration.shortest,
		 }),
}));

export default function DisplayBlockTeaser(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
	
	return (
	    <Card sx={{boxShadow: "none !important", padding: 0}}>
	    	<CardMedia	sx={{ height: 140 }}
				        image={props.image}
				        title="green iguana"
	      	/>
	      	<CardContent>
		        <Typography gutterBottom variant="h5" component="div">
		          {props.title}
		        </Typography>
		        <Typography variant="body2" color="text.secondary">
		          Lizards are a widespread group of squamate reptiles, with over 6,000
		          species, ranging across all continents except Antarctica
		        </Typography>
			</CardContent>
  			<CardActions disableSpacing>
				<Button size="small">{t("Beitraglesen")}</Button>
		        <ExpandMore			        >
		          <ShareIcon />
		        </ExpandMore>
  			</CardActions>
	    </Card>
	  );
}