import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Document, Page, pdfjs   } from "react-pdf";


import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import PersonalInputs from './common/PersonalInputs';
import Alert from './Alert';

import {sendAction, createOfferPdf} from "../helper/utils";

import models from '../db/models.json';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function RequestOffer(props) {
  	const getCar = (which) => {
		let model;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		model = value;
	  })
	  return model;
  	};	
	let cars = [];
	const x = getCar(props.car);
	if(x != undefined)
		cars.push(x.label);	
	else	
		cars.push("Allgemein");
	
	
	const [ userData, setUserData] = React.useState();
  	const [ car, setCar] = React.useState(cars);
  	const [ config, setConfig] = React.useState(props.config);
  	const [ openBD, setOpenBD] = React.useState(false);
  	const [ step, setStep] = React.useState(0);
  	const [ pdf, setPdf] = React.useState();
  	const [ displayAlert, setDisplayAlert] = React.useState(false);
  	const [ alert, setAlert] = React.useState(false);
  	const [ currentPage, setCurrentPage] = React.useState(1);
  	const [ pageNumbers, setPageNumbers] = React.useState(0);
	
  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	
  	const handleOpenBD = () => {
    	setOpenBD(true);
  	};  

	const { t, i18n } = useTranslation("",{lng: props.language});

    const handleClose = () => {
    	props.handleOpen(false);
  	};

	async function doSubmit(value)  {
		let mailAddon = {};
		mailAddon.car = car;  
		mailAddon.config = config;  
		mailAddon.lng = i18n.resolvedLanguage;

		let obj = {};
		obj.configuration = props.userConfig;
		obj.mailAddon = mailAddon
		obj.user = userData;
		if(alert == false && obj.user != undefined) {
			setOpenBD(true);		
			await sendAction(obj, "requestConfig");
	    	props.handleOpen(false);
			setOpenBD(false);
		}
	    else {
			setDisplayAlert(true);
		}		
  	}; 

	async function requestOffer(value)  {
		let mailAddon = {};
		mailAddon.car = car;  
		mailAddon.config = config;  
		mailAddon.lng = i18n.resolvedLanguage;

		let obj = {};
		obj.configuration = props.userConfig;
		obj.mailAddon = mailAddon
		obj.user = userData;

		console.log("requestOffer");
		console.log(obj);
		console.log("=============================================");
		console.log(config);



		setOpenBD(true);
		
		let data = await createOfferPdf(obj, config);
		var db = JSON.stringify(data);
		let result = JSON.parse("{" + data + "}");
		setPdf(result.result);
		setStep(1);

		setOpenBD(false);
  	};  	

    const handleWizzardStepBack = (e) => { 
        if(step > 0)
            setStep((step - 1));       
    }

    const handleWizzardStepForward = (e) => { 
		let x = (step + 1);
		if(x == 1)
			requestOffer("1");
        if(step < 2)
            setStep(x);       
    } 
     	  	
    const handleAlert = (value) => {
    	setAlert(value);
  	};  	  	
  	  	
    const handlePDFStepBack = (e) => { 
        if(currentPage > 1)
            setCurrentPage((currentPage - 1));       
    }

    const handlePDFStepForward = (e) => { 
        if(currentPage < pageNumbers)
            setCurrentPage((currentPage + 1));       
    }  	  	
  	  	
  	const onDocumentLoadSuccess = ({ numPages }) => {
    	setPageNumbers(numPages);
  	};  


  	return (<React.Fragment>
				<BootstrapDialog 	onClose={handleClose}
									aria-labelledby="customized-dialog-title"
 									open={props.open}
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			{t("YourConfig")}
	        		</DialogTitle>
			        <IconButton aria-label="close"
			          			onClick={handleClose}
						        sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						        }}
					>
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<React.Fragment>
 							<Typography variant="h6" gutterBottom>
        						{t("IhreKonfigurationalsPDF", {config: config})}
      						</Typography>
							
							{step == 0 ? (<>
											<PersonalInputs setUserData={setUserData} handleAlert={handleAlert}/>							
										</>) :
										(<>
				                        	{t("Vorschau")}:  
					                        <IconButton variant="contained"
														onClick={e => handlePDFStepBack(e)}
											>			
												<ArrowBackIosIcon sx={{fontSize: '20px', color: 'black'}}/>
											</IconButton>
					                        <IconButton variant="contained"
														onClick={e => handlePDFStepForward(e)}
											>			
												<ArrowForwardIosIcon sx={{fontSize: '20px', color: 'black'}}/>
											</IconButton>

				                            {t("Seite")} {currentPage} {t("von")} {pageNumbers} 					                        
											<Paper style={{overflow: "hide"}}> 
												<div style={{overflow: "scroll"}}>					                                       
						                            <Document file={"data:application/pdf;base64," + pdf}
														onLoadSuccess={onDocumentLoadSuccess}
						                            >
						                                <Page pageNumber={currentPage}  renderTextLayer={false}/>
						                            </Document>
												</div>					                                       
					                        </Paper>
										</>)}
						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus 
								color="error"
								variant="contained"
								onClick={handleClose}
								endIcon={<CancelIcon />}>
				            {t("verwerfen")}
				        </Button>
						<Button autoFocus 
								variant="contained"
								startIcon={<ArrowBackIosIcon />}
								onClick={handleWizzardStepBack}>
				            {t("zurück")}
				        </Button>				        
						{step == 1 ? (
								<Button autoFocus 
										variant="contained"
										endIcon={<SendIcon />}
										onClick={doSubmit}>
						            {t("abschicken")}
						        </Button>				        
							) : (
								<Button autoFocus 
										variant="contained"
										endIcon={<ArrowForwardIosIcon />}
										onClick={e => requestOffer(1)}>
						            {t("weiter")}
						        </Button>				        
								
							)}
			        </DialogActions>
      			</BootstrapDialog>
      			<Alert displayAlertMsg={t("AdviceMailDialog")} display={displayAlert} setDisplayAlert={setDisplayAlert}/>

		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>      			
      			
    </React.Fragment>
  );
}