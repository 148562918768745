import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


import {sendAction} from "../../helper/utils";




export default function PersonalInputs(props) {	
	const { t, i18n } = useTranslation("",{lng: props.language});
	
  	const [ status, setStatus] = React.useState("idle");
  	const [ salutation, setSalutation] = React.useState("Herr");
  	const [ firstName, setFirstName] = React.useState("");
  	const [ firstNameAlert, setFirstNameAlert] = React.useState(false);
  	const [ lastName, setLastName] = React.useState("");
  	const [ lastNameAlert, setLastNameAlert] = React.useState(false);
  	const [ address1, setAddress1] = React.useState("");
  	const [ address1Alert, setAddress1Alert] = React.useState(false);
  	const [ address2, setAddress2] = React.useState("");
  	const [ zip, setZip] = React.useState("");
  	const [ zipAlert, setZipAlert] = React.useState(false);
  	const [ city, setCity] = React.useState("");
  	const [ cityAlert, setCityAlert] = React.useState(false);
  	const [ country, setCountry] = React.useState("");
  	const [ countryAlert, setCountryAlert] = React.useState(false);
  	const [ state, setState] = React.useState("");
  	const [ email, setEmail] = React.useState("");
  	const [ emailAlert, setEmailAlert] = React.useState(false);
  	const [ phone, setPhone] = React.useState("");
  	const [ mobil, setMobil] = React.useState("");
  	const [ dataProtection, setDataProtection] = React.useState(false);
  	const [ dataProtectionAlert, setDataProtectionAlert] = React.useState(false);
  	
  	
  	
  	const [ alert, setAlert] = React.useState(false);
  	const [ openBD, setOpenBD] = React.useState(false);

  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	
  	const handleOpenBD = () => {
    	setOpenBD(true);
  	}; 

  	
  	const handleChangeDataProtection = (event) => {
    	setDataProtection(event.target.checked);
    	handleUserInput();
	}
	
	const handleUserInput = () => {
		let iAlert = false;
		if(firstName == "") {
			iAlert = true;
			setFirstNameAlert(true)
		}
		else {
			setFirstNameAlert(false)
		}		
		if(lastName == "") {
			iAlert = true;
			setLastNameAlert(true)
		}
		else {
			setLastNameAlert(false)
		}		
		if(address1 == "") {
			iAlert = true;
			setAddress1Alert(true)
		}
		else {
			setAddress1Alert(false)
		}		
		if(zip == "") {
			iAlert = true;
			setZipAlert(true)
		}
		else {
			setZipAlert(false)
		}		
		if(city == "") {
			iAlert = true;
			setCityAlert(true)
		}
		else {
			setCityAlert(false)
		}		
		if(country == "") {
			iAlert = true;
			setCountryAlert(true)
		}
		else {
			setCountryAlert(false)
		}		
		if(email == "") {
			iAlert = true;
			setEmailAlert(true)
		}
		else {
			setEmailAlert(false)
		}		

		if(dataProtection == false) {
			iAlert = true;
			setDataProtectionAlert(true)
		}
		else {
			setDataProtectionAlert(false)
		}		

		console.log("emailAlert");
		console.log(emailAlert);

		console.log("countryAlert");
		console.log(countryAlert);

		console.log("cityAlert");
		console.log(cityAlert);

		console.log("zipAlert");
		console.log(zipAlert);

		console.log("address1Alert");
		console.log(address1Alert);

		console.log("lastNameAlert");
		console.log(lastNameAlert);

		console.log("firstNameAlert");
		console.log(firstNameAlert);
				
		if(	emailAlert === true ||				
			countryAlert === true ||
			cityAlert === true ||
			zipAlert === true ||
			address1Alert === true ||
			lastNameAlert === true ||
			firstNameAlert === true)
				iAlert = true;
		else
			iAlert = false;
		let user = {};
		user.salutation = salutation;  
		user.firstName = firstName;  
		user.lastName = lastName;  
		user.address1 = address1;  
		user.address2 = address2;  
		user.zip = zip;  
		user.city = city;  
		user.country = country;  
		user.state = state;  
		user.email = email;  
		user.phone = phone;  
		user.mobil = mobil;  

		
		props.handleAlert(iAlert);
		props.setUserData(user);

  	}; 
    	

  	return (<React.Fragment>
				<hr/>
				<Typography variant="h6" gutterBottom>
    				{t("IhreAdresse")}
  				</Typography>
  				<Grid container spacing={3}>
    				<Grid item xs={12} sm={6}>
						<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
							<InputLabel id="demo-simple-select-standard-label">{t("Anrede")}</InputLabel>
							<Select labelId="demo-simple-select-standard-label"
						          	id="demo-simple-select-standard"
						          	value={salutation}
						          	onChange={e =>  setSalutation(e.target.value)}
						          	label={t("Anrede")}
						    >
						 		<MenuItem value={"Herr"}>
						       		{t("Herr")} 
						        </MenuItem>							          
						        <MenuItem value={"Frau"}>
						       		{t("Frau")} 
						        </MenuItem>
						        <MenuItem value={"Divers"}>
						       		{t("Divers")} 
						        </MenuItem>
						          
						  	</Select>
						</FormControl>
					</Grid>
    				<Grid item xs={12} sm={6}>
					</Grid>


					<Grid item xs={12} sm={6}>
						{firstNameAlert === true ? (
							<TextField	required
										id="firstName"
										error
										name="firstName"
										label={t("firstName")}
										fullWidth
										autoComplete="given-name"
										variant="standard"
										value={firstName}
										helperText={t("Incorrectentry")}
										onChange={e => {setFirstName(e.target.value);handleUserInput()}}
										onBlur={e => {setFirstName(e.target.value);handleUserInput()}}													
			          			/>
			          		):(
							<TextField	required
										id="firstName"
										name="firstName"
										label={t("firstName")}
										fullWidth
										autoComplete="given-name"
										variant="standard"
										value={firstName}
										onChange={e => {setFirstName(e.target.value);handleUserInput()}}	
										onBlur={e => {setFirstName(e.target.value);handleUserInput()}}												
			          			/>
								  
							  )}
					</Grid>
					<Grid item xs={12} sm={6}>
						{lastNameAlert === true ? (
							<TextField	required
										id="lastName"
										error
										name="lastName"
										label={t("lastName")}
										fullWidth
										autoComplete="family-name"
										variant="standard"
										value={lastName}
										helperText={t("Incorrectentry")}
										onChange={e => {setLastName(e.target.value);handleUserInput()}}													
										onBlur={e => {setLastName(e.target.value);handleUserInput()}}												
							/>
							) :(
								<TextField	required
											id="lastName"
											name="lastName"
											label={t("lastName")}
											fullWidth
											autoComplete="family-name"
											variant="standard"
											value={lastName}
											onChange={e => {setLastName(e.target.value);handleUserInput()}}													
											onBlur={e => {setLastName(e.target.value);handleUserInput()}}												
								/>
								
							)}
					</Grid>
					<Grid item xs={12}>
						{address1Alert === true ? (
							<TextField	required
										id="address1"
										error
										name="address1"
										label={t("address1")}
										fullWidth
										autoComplete="shipping address-line1"
										variant="standard"
										helperText={t("Incorrectentry")}
										value={address1}
										onChange={e => {setAddress1(e.target.value);handleUserInput()}}
										onBlur={e => {setAddress1(e.target.value);handleUserInput()}}
							/>
							) :(
								<TextField	required
											id="address1"
											name="address1"
											label={t("address1")}
											fullWidth
											autoComplete="shipping address-line1"
											variant="standard"
											value={address1}
											onChange={e => {setAddress1(e.target.value);handleUserInput()}}
											onBlur={e => {setAddress1(e.target.value);handleUserInput()}}
								/>
								
							)}
					</Grid>
					<Grid item xs={12}>
						<TextField	id="address2"
									name="address2"
									label={t("address2")}
									fullWidth
									autoComplete="shipping address-line2"
									variant="standard"
									value={address2}
									onChange={e => {setAddress2(e.target.value);handleUserInput()}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						{zipAlert === true ? (
							<TextField	required
										id="zip"
										name="zip"
										error
										label={t("zip")}
										fullWidth
										autoComplete="shipping postal-code"
										variant="standard"
										value={zip}
										helperText={t("Incorrectentry")}
										onChange={e => {setZip(e.target.value);handleUserInput()}}
										onBlur={e => {setZip(e.target.value);handleUserInput()}}
							/>
							):(
							<TextField	required
										id="zip"
										name="zip"
										label={t("zip")}
										fullWidth
										autoComplete="shipping postal-code"
										variant="standard"
										value={zip}
										onChange={e => {setZip(e.target.value);handleUserInput()}}
										onBlur={e => {setZip(e.target.value);handleUserInput()}}
							/>
								
							)}
					</Grid>
					<Grid item xs={12} sm={6}>
						{cityAlert === true ? (
							<TextField	required
										id="city"
										error
										name="city"
										label={t("city")}
										fullWidth
										autoComplete="shipping address-level2"
										variant="standard"
										value={city}
										helperText={t("Incorrectentry")}
										onChange={e => {setCity(e.target.value);handleUserInput()}}
										onBlur={e => {setCity(e.target.value);handleUserInput()}}
							/>
						):(
							<TextField	required
										id="city"
										name="city"
										label={t("city")}
										fullWidth
										autoComplete="shipping address-level2"
										variant="standard"
										value={city}
										onChange={e => {setCity(e.target.value);handleUserInput()}}
										onBlur={e => {setCity(e.target.value);handleUserInput()}}
							/>
						)}										
					</Grid>
					<Grid item xs={12} sm={6}>
						{countryAlert === true ? (
							<TextField	required
										id="country"
										error
										name="country"
										label={t("country")}
										fullWidth
										autoComplete="shipping country"
										variant="standard"
										value={country}
										helperText={t("Incorrectentry")}
										onChange={e => {setCountry(e.target.value);handleUserInput()}}
										onBlur={e => {setCountry(e.target.value);handleUserInput()}}
							/>		
						):(
							<TextField	required
										id="country"
										name="country"
										label={t("country")}
										fullWidth
										autoComplete="shipping country"
										variant="standard"
										value={country}
										onChange={e => {setCountry(e.target.value);handleUserInput()}}
										onBlur={e => {setCountry(e.target.value);handleUserInput()}}
							/>		
						
						)}							
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField	id="state"
									name="state"
									label={t("state")}
									fullWidth
									variant="standard"
									value={state}
									onChange={e => {setState(e.target.value);handleUserInput()}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField	
									id="mobil"
									name="mobil"
									label={t("mobil")}
									fullWidth
									autoComplete="mobil"
									variant="standard"
									value={mobil}
									onChange={e => {setMobil(e.target.value);handleUserInput()}}
						/>								
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField	
									id="phone"
									name="phone"
									label={t("phone")}
									fullWidth
									variant="standard"
									value={phone}
									onChange={e => {setPhone(e.target.value);handleUserInput()}}
						/>
					</Grid>
					
					<Grid item xs={12}>
						{emailAlert === true ? (
							<TextField	required
										id="email"
										name="email"
										error
										label={t("email")}
										fullWidth
										autoComplete="email"
										variant="standard"
										helperText={t("Incorrectentry")}													
										value={email}
										onChange={e => {setEmail(e.target.value);handleUserInput()}}
										onBlur={e => {setEmail(e.target.value);handleUserInput()}}
							/>
							):(
							<TextField	required
										id="email"
										name="email"
										label={t("email")}
										fullWidth
										autoComplete="email"
										variant="standard"
										value={email}
										onChange={e => {setEmail(e.target.value);handleUserInput()}}
										onBlur={e => {setEmail(e.target.value);handleUserInput()}}
							/>
								
							)}
					</Grid>
					
					<Grid item xs={12}>
						<FormControlLabel control={<Checkbox required 
															 color="secondary" 
															 checked={dataProtection}
															 onChange={handleChangeDataProtection}
															 name="dataProtection" 
															  />}
						/>
						<a href="https://www.reimo.com/uebergreifende-datenschutzerklaerung" target="_blank">{t("Datenschutzerklärunggelesen")}</a><br/>
						{dataProtectionAlert === true ? (<span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium Mui-required css-sglxha-MuiFormHelperText-root">{t("acceptDataProtection")}</span>) : (<></>)}
						

					</Grid>
				</Grid>

		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>      			      			
    		</React.Fragment>
  		);
}