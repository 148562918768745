import * as React from 'react';
import { styled } from '@mui/material/styles';

import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DialogForm from './dialogs/DialogForm';
import Toaster from './partials/Toaster';
import {publishLatestNewsJson} from "../../helper/utils";

import latestNews from '../../db/latestNews.json';

const latestNewsCurrent = structuredClone(latestNews);  	

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ListLatestNews(props) {

	const [open, setOpen] = React.useState(false);
	const [openMsg, setOpenMsg] = React.useState(false);
	const [toasterMsg, setToasterMsg] = React.useState("");	
	const [obj, setObj] = React.useState({});	
	const [objCurrent, setObjCurrent] = React.useState({});
 	const [value, setValue] = React.useState(latestNews);
 
   	const compareObj = (index) => {
		
		if(latestNewsCurrent[index] != undefined && JSON.stringify(latestNews[index]) === JSON.stringify(latestNewsCurrent[index])) {
	 		return "";			
		}
		else
	 		return "* ";
	};

	async function publishLatestNews(params) {		
		setOpenMsg(true);
		setToasterMsg("Publizierung gestartet");
		let data = await publishLatestNewsJson(latestNews);

  	};  	

  	const handleDelete = (index) => {
		structuredClone(latestNews.splice(index, 1));
		setValue(structuredClone(latestNews));
	  	latestNewsCurrent.splice(index, 1);
	}

  	const addNews = () => {
		let o = {};
		o.label = "new";
   		o.headline = "";
    	o.slogan = "";
    	o.location = "";
    	o.buttonlabel = "Termin vereinbaren";
    	latestNews.push(o);
    	setValue(structuredClone(latestNews));
    	
    	
  	}

   	const handleClickOpen = (o, oC) => {
		setObj(o);
		setObjCurrent(oC);
    	setOpen(true);
  	};
 
  	return (<>
  				<Paper >
	  				<Box sx={{maxHeight: "70vH", minHeight: "70vH"}}>
		  				<List sx={{ width: '100%',  bgcolor: 'background.paper' }}>
							{value.map((news, index) => (
								<>
						      		<ListItem alignItems="block" sx={{display: "block"}}>
										<Stack  direction="row" justifyContent="space-between" alignItems="center">
											<Typography sx={{ flex: '1 1 100%'}}
							          					variant="h6"
							          					id="tableTitle"
							          					component="div"
							        		>
        										<Stack direction="row" alignItems="center" justifyContent="space-between">
													<Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" sx={{margin: "10px"}}/>

													<Box sx={{flexGrow: 1}}
									        		>
														<Typography sx={{ flex: '1 1 100%'}}
										          					variant="h6"
										          					component="div"
										        		>
							        		          	{compareObj(index) + news.label}
														</Typography>
														<Typography sx={{ flex: '1 1 100%'}}
										          					variant="body"
										          					component="span"
										        		>
							        		          	{news.location}
														</Typography>
													</Box>
												</Stack>		
        									</Typography>
        									<Typography>
												<IconButton onClick={e => handleClickOpen(news, latestNewsCurrent[index]) }>
													<EditIcon />
												</IconButton>
												<IconButton onClick={e => handleDelete(index) }>
													<DeleteIcon />
												</IconButton>
        									</Typography>
										</Stack>						        	
						      		</ListItem>
						      		<Divider variant="inset" component="li" />
								</>
							))}
			   			</List>	
				    </Box>	   			
					<hr/>
				  	<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
			        	<Typography gutterBottom variant="h6" component="div">
			      			<IconButton onClick={e => addNews()}><AddCircleOutlineIcon /></IconButton>
	      				</Typography>
			          	<Typography gutterBottom variant="h6" component="div">
				          	<Button onClick={e => publishLatestNews()} endIcon={<PublishIcon />}>Änderungen publizieren</Button>
			          	</Typography>
			        </Stack>


				</Paper>

				<DialogForm open={open} obj={obj} objCurrrent={objCurrent} handleClose={setOpen} />
				<Toaster open={openMsg} msg={toasterMsg} handleClose={setOpenMsg}/>
   			</>
  	);
}