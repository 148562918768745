import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import ScaleIcon from '@mui/icons-material/Scale';

import Header from "./common/Header2";
import IntroDescriptionPage from "../snippets/IntroDescriptionPage";
import ContactPersons from "../widgets/ContactPersons";
import Usefull from "../snippets/Usefull";

import models from '../db/models.json';


const images = [
  '/hero/hero1Start.jpg',
  '/hero/hero2Start.jpg',
  '/hero/hero3Start.jpg'
];


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};


export default function NotFound(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	return (<>
			  	<Header heroImages={images} label="" slogan=""/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'none',  
							lg: 'none',  
							xl: 'none',  
							
							} }}
				>
					<Box class="wrapper" style={{padding: "65px"}}>
					</Box> 
				</Box>
				<Box sx={{ flexGrow: 1, display: {
							xs: 'none',
							sm: 'none',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>



	  				<Box  sx={{paddingTop: "0px", marginTop: "40px"}} > 


						<Grid container >
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={2}>
									</Grid>
									<Grid item xs={8} >
						        		<center>
									        <Typography variant="h5" color="text.secondary">
Ups, Seite nicht gefunden
									        </Typography>
							        	</center>


									</Grid>
									
									<Grid item xs={2}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

			      																		
					</Box>

	  				<Box sx={{paddingTop: "40px"}}> 
						<ContactPersons profil={props.profil}/>
					</Box>
   			</Box>
   		</>
   	)
}
