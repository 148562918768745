import React from "react";
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

export default function IntroDescriptionPage(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
	
  	return (<>
				<Grid container sx={{paddingTop: "50px", paddingBottom: "50px"}}>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={2}>
							</Grid>
							<Grid item xs={8} sx={{marginBottom: "20px"}}>
								<Box  style={{textAlign: "justify", color: "#000"}}>					
									<p class="" style={{margin: 0}}>{props.description}</p>
								</Box>			
							</Grid>
							<Grid item xs={2}>
							</Grid>
						</Grid>
					</Grid>
				</Grid>				
			</>
	  )
}