import * as React from 'react';
import { useTranslation } from 'react-i18next';

import FeatureProducts from "../widgets/FeatureProducts";


import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function ProductDialog(props) {
  	const [ status, setStatus] = React.useState("idle");
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
	const { t, i18n } = useTranslation("",{lng: props.language});

    const handleClose = () => {
    	props.handleOpen(false);
  	};

    	

  	return (<React.Fragment>
				<BootstrapDialog 	className="animate__animated animate__zoomIn" onClose={handleClose}
									aria-labelledby="customized-dialog-title"
									hideBackdrop={true}
 									open={props.open}
      			>
						<IconButton variant="contained"
									onClick={handleClose}
									sx={{	position: "fixed",
						           			zIndex: 10005,
						           			marginTop: "-20px",
						           			marginLeft: "350px",
						           			border: "1px solid black",
						           			bgcolor: 'info.main',
								      '&:hover': {
								        bgcolor: 'info.main',
								      }
									}}
						>			
								<CloseIcon sx={{fontSize: '20px', color: 'white'}}/>
						</IconButton>

			        <DialogContent dividers sx={{margin: 0, padding: 0}}>
						<React.Fragment>
		  					<FeatureProducts title={props.product.name} slogan={props.product.slogan} image={props.product.image}/>
 						</React.Fragment>
			        </DialogContent>

      			</BootstrapDialog>
    </React.Fragment>
  );
}