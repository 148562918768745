import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import EngineeringIcon from '@mui/icons-material/Engineering';


const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
  	return <IconButton {...other} />;
	})(({ theme, expand }) => ({
  		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		    duration: theme.transitions.duration.shortest,
		 }),
}));

export default function ModelCard(props) {
	const [expanded, setExpanded] = React.useState(false);
	const [checked, setChecked] = React.useState(false);
	
  	let navigate = useNavigate(); 

	const { t, i18n } = useTranslation("",{lng: props.language});

	const handleChange = (event, code) => {
		try {
			if(event.target.checked === true) {
				props.changeConfiguration(event, "internal", code)
				props.setExtCode(code);
		    	setChecked(true);
			}
			else {
				props.setExtCode("");
				props.changeConfiguration("");
	    		setChecked(false);
			}
		}
		catch (error) {
				props.setExtCode("");
				props.changeConfiguration("");
	    		setChecked(false);
		}		
  	};

  	const handleExpandClick = () => {
    	setExpanded(!expanded);
  	};

  	return (
			<Card sx={{ maxWidth: 345 }} className="centerCarCard">
      			<CardHeader avatar={
					           <Avatar sx={{ bgcolor: "#fff" }} aria-label="recipe" src={"/assets/logos/" + props.model.producerLogo}>
					          </Avatar>
					        }
        					title={props.model.label}
							subheader={props.model.slogan}
      			/>
		      	<CardMedia 	component="img"
		        			height="194"
		        			image={props.model.image}		        			
		      	/>
      			<CardContent>
			        <Typography variant="body2" color="text.secondary">
						{t("UVP")}<br/>{t("ab")} {Number(props.model.price).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
			        </Typography>
				</CardContent>
      			<CardActions disableSpacing>
					<Checkbox 	checked={props.extCode == props.model.configCode ? true : false}
								onChange={e => handleChange(e, props.model.configCode)}/>       
				        {t("Verwenden")}
			        <ExpandMore
			          expand={expanded}
			          onClick={handleExpandClick}
			          aria-expanded={expanded}
			          aria-label="show more"
			        >
			          <ExpandMoreIcon />
			        </ExpandMore>
      			</CardActions>
				<Collapse in={expanded} timeout="auto" unmountOnExit>
		        	<CardContent>
		          		<Typography paragraph>Motor:</Typography>
	          			<Typography paragraph>
			 				<ul class="empty" sx={{listStyleType: "none"}}>
								<li><LocalGasStationIcon sx={{fontSize: "1.5em"}}/>{props.model.fuel}</li>
								<li><EngineeringIcon sx={{fontSize: "1.5em"}}/>{props.model.gears}</li>
							</ul>
						</Typography>
		          		<Typography paragraph>Ausstattung:</Typography>
	          			<Typography paragraph>
						<ul class="empty" sx={{listStyleType: "none"}}>
							<li><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{props.model.seats} + {props.model.seatsOptional} {t("seats")}</li>
							<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{props.model.sleepingplaces} + {props.model.sleepingplacesOptional} {t("sleepingPlaces")}</li>
							<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{props.model.size.outerLength}cm x {props.model.size.outerHeight}cm x {props.model.size.outerWidth}cm</li>
						</ul>											        			
						</Typography>




		        	</CardContent>
		      	</Collapse>
    		</Card>
  );
}