import React from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';

import PressCard from '../widgets/PressCard';




export default function (props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	return (
		  	<>		
				<section class="about">	  

					<center>
						<h2 style={{paddingTop: "40px"}} class="headlineNewPoint">{t("ReimoAtTheNews")}</h2>
					</center>
										
					<Box sx={{	display: 'flex', 
								flexDirection: 'row', 
								pt: 2,
								display: {
									xs: 'none',
									sm: 'none',  
									md: 'block',  
									lg: 'block',  
									xl: 'block',  
									
							}   }}>
			      		<Grid container style={{paddingTop: "0px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<PressCard  header="Reimo Caddy Camp: Wohnmobil-Test" subline="Kleiner Camper ganz groß" description="Promobil, 06. September 2023" url="https://www.promobil.de/neuheiten/reimo-triostyle-vw-t6-1-van-concept-mit-aufstelldach/" logo="/assets/addons/logo-promobil.png"/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<PressCard header="Multivan-Prototyp von Reimo" subline="Neuvorstellung auf der CamperVan" description="Autobild, 03. Juni 2019" url="https://www.autobild.de/artikel/reimo-caddy-camp-wohnmobil-test-14992263.html" logo="/assets/addons/logo-autobild.png"/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<PressCard header="Kleiner campt keiner" subline="Das funktioniert dank eines ausgeklügelten Innenraums." description="SpiegelOnline, 26. Oktober 2018" url="https://www.spiegel.de/auto/aktuell/reimo-camp-kompaktes-wohnmobil-in-pkw-groesse-im-test-a-1232872.html"  logo="/assets/addons/logo-spiegel.png"/>					        				
					        			</Paper>
						     	   </Grid>
					        		<Grid item xs={3}>
					        			<Paper>
					        				<PressCard  header="Elektro-Camper mit minimalistischem Ausbau" subline="Im Handumdrehen ein echter Campervan" description="Promobil, 02. August 2022" url="https://www.promobil.de/neuheiten/reimo-campingmodule-mercedes-benz-eqv/" logo="/assets/addons/logo-promobil.png"/>					        				
					        			</Paper>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>		
					</Box>
					<Box sx={{	display: 'flex', 
								flexDirection: 'row', 
								pt: 2,
								display: {
									xs: 'block',
									sm: 'block',  
									md: 'none',  
									lg: 'none',  
									xl: 'none',  
									
							}   }}>
			        			<Paper>
			        				<PressCard  header="Reimo Caddy Camp: Wohnmobil-Test" subline="Kleiner Camper ganz groß" description="Promobil, 06. September 2023" url="https://www.promobil.de/neuheiten/reimo-triostyle-vw-t6-1-van-concept-mit-aufstelldach/" logo="/assets/addons/logo-promobil.png"/>					        				
			        			</Paper>
			        			<Paper sx={{marginTop: 4}}>
			        				<PressCard header="Multivan-Prototyp von Reimo" subline="Neuvorstellung auf der CamperVan" description="Autobild, 03. Juni 2019" url="https://www.autobild.de/artikel/reimo-caddy-camp-wohnmobil-test-14992263.html" logo="/assets/addons/logo-autobild.png"/>					        				
			        			</Paper>
			        			<Paper sx={{marginTop: 4}}>
			        				<PressCard header="Kleiner campt keiner" subline="Das funktioniert dank eines ausgeklügelten Innenraums." description="SpiegelOnline, 26. Oktober 2018" url="https://www.spiegel.de/auto/aktuell/reimo-camp-kompaktes-wohnmobil-in-pkw-groesse-im-test-a-1232872.html"  logo="/assets/addons/logo-spiegel.png"/>					        				
			        			</Paper>
			        			<Paper sx={{marginTop: 4}}>
			        				<PressCard  header="Elektro-Camper mit minimalistischem Ausbau" subline="Im Handumdrehen ein echter Campervan" description="Promobil, 02. August 2022" url="https://www.promobil.de/neuheiten/reimo-campingmodule-mercedes-benz-eqv/" logo="/assets/addons/logo-promobil.png"/>					        				
			        			</Paper>
							
					</Box>	
			  	</section>
		  </>
	  )
}