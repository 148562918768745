import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import SyncIcon from '@mui/icons-material/Sync';

import { visuallyHidden } from '@mui/utils';

import DisplayConfig from '../dialogs/DisplayConfig';
import {getConfigurations, requestConfigPdf} from "../../../helper/utils";


const initR = await getConfigurations();

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
    	return -1;
  	}
  	if (b[orderBy] > a[orderBy]) {
    	return 1;
  	}
  	return 0;
}

function getComparator(order, orderBy) {
  	return order === 'desc'
    	? (a, b) => descendingComparator(a, b, orderBy)
    	: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
 	stabilizedThis.sort((a, b) => {
    	const order = comparator(a[0], b[0]);
    	if (order !== 0) {
      		return order;
    	}
    	return a[1] - b[1];
  	});
  	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'code',
    numeric: false,
    disablePadding: true,
    label: 'Code',
  },
  {
    id: 'created',
    numeric: false,
    disablePadding: false,
    label: 'Datum',
  },
  {
    id: 'customer',
    numeric: false,
    disablePadding: false,
    label: 'Kunde',
  },
  {
    id: 'kind',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'car',
    numeric: false,
    disablePadding: false,
    label: 'Fahrzeug',
  },
];

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
	const createSortHandler = (property) => (event) => {
    	onRequestSort(event, property);
  	};

  	return (<TableHead>
				<TableRow>
        			{headCells.map((headCell) => (
						<TableCell 	key={headCell.id}
									align={headCell.numeric ? 'right' : 'left'}
									padding={headCell.disablePadding ? 'none' : 'normal'}
									sortDirection={orderBy === headCell.id ? order : false}
          				>
	            			<TableSortLabel active={orderBy === headCell.id}
											direction={orderBy === headCell.id ? order : 'asc'}
											onClick={createSortHandler(headCell.id)}
	            			>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
	                				</Box>
	              				) : null}
	            			</TableSortLabel>
          				</TableCell>
        			))}
        			<TableCell 	>
      				</TableCell>
      			</TableRow>
			</TableHead>
  	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
  	onRequestSort: PropTypes.func.isRequired,
  	onSelectAllClick: PropTypes.func.isRequired,
  	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  	orderBy: PropTypes.string.isRequired,
  	rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  	const { numSelected } = props;

  	return (<Toolbar  sx={{ pl: { sm: 2 },
							pr: { xs: 1, sm: 1 },
 							...(numSelected > 0 && {
          					bgcolor: (theme) =>
            					alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        					}),
      					  }}
    		>      			
        		<Typography sx={{ flex: '1 1 100%' }}
          					variant="h6"
          					id="tableTitle"
          					component="div"
        		>
          			
        		</Typography>
        		<Tooltip title="Filter list">
          			<IconButton>
            			<FilterListIcon />
          			</IconButton>
        		</Tooltip>
    		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
  	numSelected: PropTypes.number.isRequired,
};

  	
export default function EnhancedTable() {
  	const [displayConfig, setDisplayConfig] = React.useState(false);
  	const [order, setOrder] = React.useState('asc');
  	const [orderBy, setOrderBy] = React.useState('calories');
  	const [selected, setSelected] = React.useState([]);
  	const [page, setPage] = React.useState(0);
  	const [pdf, setPdf] = React.useState(null);
  	const [rows, setRows] = React.useState(initR);  	
  	const [dense, setDense] = React.useState(false);
  	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [configCode, setConfigCode] = React.useState(null);
  
	async function requestConfig(id)  {
//		setOpenBD(true);		
		let data = await requestConfigPdf(null, id);
		let result = JSON.parse("{" + data + "}");
		setPdf(result.result);
		setDisplayConfig(true);

//		setOpenBD(false);

  	}; 
  
 
  
  
  	const openMenu = Boolean(anchorEl);
  	
  	const handleClickMenu = (event, id) => {
		setConfigCode(id);
    	setAnchorEl(event.currentTarget);
  	};
    	
  	const handleCloseMenu = () => {
    	setAnchorEl(null);
  	};
    	
  	const handleDisplayConfig = () => {
    	setAnchorEl(null);
    	requestConfig(configCode);
  	};

	async function RefreshRows() {
		const r = await getConfigurations();
	}

	async function RefreshRows() {
		const r = await getConfigurations();
		setRows(r);
	}

  	const handleRequestSort = (event, property) => {
    	const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
    	setOrderBy(property);
  	};

  	const handleSelectAllClick = (event) => {
    	if (event.target.checked) {
      		const newSelected = rows.map((n) => n.id);
			setSelected(newSelected);
      		return;
    	}
    	setSelected([]);
  	};

  	const handleClick = (event, id) => {
    	const selectedIndex = selected.indexOf(id);
    	let newSelected = [];

    	if (selectedIndex === -1) {
      		newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
      		newSelected = newSelected.concat(selected.slice(1));
    	} else if (selectedIndex === selected.length - 1) {
      		newSelected = newSelected.concat(selected.slice(0, -1));
    	} else if (selectedIndex > 0) {
      		newSelected = newSelected.concat(
	        	selected.slice(0, selectedIndex),
	        	selected.slice(selectedIndex + 1),
      		);
    	}
    	setSelected(newSelected);
  	};

  	const handleChangePage = (event, newPage) => {
    	setPage(newPage);
  	};

  	const handleChangeRowsPerPage = (event) => {
    	setRowsPerPage(parseInt(event.target.value, 10));
    	setPage(0);
  	};

  	const handleChangeDense = (event) => {
    	setDense(event.target.checked);
  	};

  	const isSelected = (id) => selected.indexOf(id) !== -1;

  	const emptyRows =	page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
    	() =>
      		stableSort(rows, getComparator(order, orderBy)).slice(
        	page * rowsPerPage,
        	page * rowsPerPage + rowsPerPage,
      	),
    	[order, orderBy, page, rowsPerPage],
  	);

  	return (<Box sx={{ width: '100%' }}>
				<Paper sx={{ width: '100%', mb: 2, boxShadow: "none" }}>
					<EnhancedTableToolbar numSelected={selected.length} />
					<TableContainer>
          				<Table 	stickyHeader 
          						sx={{ minWidth: 750 }}
            					aria-labelledby="tableTitle"
            					size={dense ? 'small' : 'medium'}
          				>
            				<EnhancedTableHead 	numSelected={selected.length}
              									order={order}
              									orderBy={orderBy}
              									onSelectAllClick={handleSelectAllClick}
              									onRequestSort={handleRequestSort}
              									rowCount={rows.length}
            				/>
            				<TableBody>
              					{visibleRows.map((row, index) => {
                					const labelId = `enhanced-table-checkbox-${index}`;

                					return (<TableRow  	hover
                    									tabIndex={-1}
                    									key={row.id}
                    									sx={{  }}
                  							>
                    							<TableCell 	component="th"
															id={labelId}
                      										scope="row"
                      										padding="none"
                    							>
                      								{row.code}
                    							</TableCell>
							                    <TableCell align="left">{row.created}</TableCell>
							                    <TableCell align="left">{row.customer}</TableCell>
							                    <TableCell align="left">{row.kind}</TableCell>
							                    <TableCell align="left">{row.car}</TableCell>
							                    <TableCell align="left">
							                    	<IconButton onClick={e => handleClickMenu(e, row.code)}><MoreVertIcon /></IconButton>
							                    </TableCell>
											</TableRow>
                						);
                						
              					})}
              					{emptyRows > 0 && (
									<TableRow style={{  height: (dense ? 33 : 53) * emptyRows,}}>
                  						<TableCell colSpan={6} />
                					</TableRow>
              					)}
            				</TableBody>
          				</Table>
        			</TableContainer>
        			<TablePagination	rowsPerPageOptions={[5, 10, 25]}
										component="div"
										count={rows.length}
          								rowsPerPage={rowsPerPage}
          								page={page}
          								onPageChange={handleChangePage}
          								onRowsPerPageChange={handleChangeRowsPerPage}
        			/>
      			</Paper>
			  	<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
		        	<Typography gutterBottom variant="h6" component="div">
		      			<IconButton onClick={e => RefreshRows()}><SyncIcon /></IconButton>
		      			<FormControlLabel 	control={<Switch checked={dense} 
		      								onChange={handleChangeDense} />}
		        							label="Dense padding"
		      			/>
      				</Typography>
		          	<Typography gutterBottom variant="h6" component="div">

		          	</Typography>
		        </Stack>

				<Menu 	id="demo-positioned-menu"
		        		aria-labelledby="demo-positioned-button"
		        		anchorEl={anchorEl}
		        		open={openMenu}
		        		onClose={handleCloseMenu}
		        		anchorOrigin={{
		          			vertical: 'top',
		          			horizontal: 'left',
		        		}}
		        		transformOrigin={{
		          			vertical: 'top',
		          			horizontal: 'left',
		        		}}
		      	>
		        	<MenuItem onClick={handleDisplayConfig}>Anzeigen</MenuItem>
		      </Menu>
			  
			  <DisplayConfig open={displayConfig} handleOpen={setDisplayConfig} pdf={pdf} configCode={configCode}/>

    		</Box>
  	);
}