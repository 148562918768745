// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  margin: 5px;
  border-radius: 25px;
  padding: 8px;
  border-color: blue;
  background: transparent;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/campy/components/Options/Options.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,uBAAuB;EACvB,kBAAkB;AACpB","sourcesContent":[".options-container {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.option-button {\n  margin: 5px;\n  border-radius: 25px;\n  padding: 8px;\n  border-color: blue;\n  background: transparent;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
