import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import ShareIcon from '@mui/icons-material/Share';


const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
  	return <IconButton {...other} />;
	})(({ theme, expand }) => ({
  		transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
		    duration: theme.transitions.duration.shortest,
		 }),
}));

export default function DisplayBlogImgRight(props) {
  const theme = useTheme();
	const { t, i18n } = useTranslation("",{lng: props.language});

  return (
    <Card sx={{boxShadow: "none !important", padding: 0 }}>
			<Box sx={{ display: 'flex'}}>
				<CardMedia  component="img"
					        sx={{ width: 151 }}
					        image={props.image}
					        alt="Live from space album cover"
					      />
	      		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
		        <CardContent sx={{ flex: '1 0 auto' }}>
		          <Typography component="div" variant="h5">
		            {props.title}
		          </Typography>
		        </CardContent>
		      </Box>
	      	</Box>
	      	<CardContent>
		        <Typography variant="body2" color="text.secondary">
		          Lizards are a widespread group of squamate reptiles, with over 6,000
		          species, ranging across all continents except Antarctica
		        </Typography>
	      	</CardContent>
  			<CardActions disableSpacing>
				<Button size="small">{t("Beitraglesen")}</Button>
		        <ExpandMore			        >
		          <ShareIcon />
		        </ExpandMore>
  			</CardActions>
    </Card>
  );
}