// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flashcard-container {
  padding: 15px;
  border-radius: 3px;
  background-color: #f1e6e6;
}

.flashcard-button {
  background: transparent;
  padding: 8px;
  border-radius: 3px;
  border-color: blue;
  margin: 5px;
  width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/campy/components/Quiz/Quiz.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".flashcard-container {\n  padding: 15px;\n  border-radius: 3px;\n  background-color: #f1e6e6;\n}\n\n.flashcard-button {\n  background: transparent;\n  padding: 8px;\n  border-radius: 3px;\n  border-color: blue;\n  margin: 5px;\n  width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
