import React from 'react';
import { useTranslation } from 'react-i18next';
import {v4 as uuidv4} from 'uuid';

import { useCookies } from 'react-cookie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination, Navigation } from 'swiper/modules';


import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SendIcon from '@mui/icons-material/Send';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Header from "./common/Header2";

import Alert from "../dialogs/Alert";
import RequestOffer from "../dialogs/RequestOffer";
import RequestConfig from "../dialogs/RequestConfig";

import ContactPersons from "../widgets/ContactPersons";
import ModelCard from "../widgets/ModelCard";
import ModelCardOwnConfig from "../widgets/ModelCardOwnConfig";
import ModelProductCard from "../widgets/ModelProductCard";
import ProductRoofCard from "../widgets/ProductRoofCard";

import articles from '../db/articles.json';


import {formatNumber, getExternal, calcMonth, getConfigurationCode, calcTotal} from "../helper/utils";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const newConfig =  await getConfigurationCode();


function  ConfigureContent(props) {
	var models = require('../db/models.json');	
  	const getCar = (which) => {
		let car;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		car = value;
	  })
	  return car;
  	};
	
	let model = getCar(props.props.model);


	const prepareConfig = () => {	
		let configStore = [];
		
		for (const [key, value] of Object.entries(model.configuration)) {
			let o = {};				
			o.label = key;		
			let v = [];				
			value.forEach((value1, k1) => {
				let o1 = {};				
				let v1 = [];
				let myuuid = uuidv4();
				if(value1.default)
					v1.push(value1.default);				
				value1.uuid = myuuid;
				o1.label = value1.label;
				o1.uuid = myuuid;
				o1.widget = value1.widget;
				o1.items = v1;
				v.push(o1);
	
		  	})
			o.cluster = v;
			configStore.push(o);
	
		}	
		return configStore;
	}		
	
	const { t, i18n } = useTranslation("",{lng: props.language});

	const [internalConfiguration, setInternalConfiguration] = React.useState(prepareConfig);
	const [displayAlert, setDisplayAlert] = React.useState(false);
	const [displayAlertMsg, setDisplayAlertMsg] = React.useState("");
	const [activeStep, setActiveStep] = React.useState(0);
	const [extCode, setExtCode] = React.useState("");
	const [externalConfiguration, setExternalConfiguration] = React.useState(null);
  	const [skipped, setSkipped] = React.useState(new Set());
	const [carColor, setCarColor] = React.useState("/assets/t6-weekender/reflexsilber-fortanarot.jpg");
	const [carFinal, setCarFinal] = React.useState(model.imagePlain);
	const [currentRoof, setCurrentRoof] = React.useState("");
	const [currentPad, setCurrentPad] = React.useState("");
	const [currentFloor, setCurrentFloor] = React.useState("");
	const [whichConfiguration, setWhichConfiguration] = React.useState("");
	const [currentReimoOptions, setCurrentReimoOptions] = React.useState(new Map());
	const [carPrice, setCarPrice] = React.useState(0);
  	const [config, setConfig] = React.useState(newConfig.result);
	const [requestOffer, setRequestOffer] = React.useState(false);
	const [requestConfig, setRequestConfig] = React.useState(false);
	const [userConfig, setUserConfig] = React.useState();
  	const [openBD, setOpenBD] = React.useState(false);

	const [cookies, setCookie] = useCookies(['config']);

	const steps = [t("Grund Fahrzeug"),  t("Schlafdach"), t("Zubehör"), t("Innen Design"), t("Zusammenfassung")];

  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	
  	const handleOpenBD = () => {
    	setOpenBD(true);
  	}; 

	function getItems(id) {				
		let reimoOptions = [];
		internalConfiguration[id].cluster.forEach(cluster  => {
			cluster.items.map(item  => {
				let optionObj = {};
				optionObj.label = articles[item].label;
				optionObj.price = formatNumber("de_DE", articles[item].price);
				reimoOptions.push(optionObj);
			});
		});
		return reimoOptions;
	};


	const callUrl = (url) => {
		if(url == "requestOffer")
			setRequestOffer(true);
		if(url == "requestConfig") {
			let carOptions = [];
			externalConfiguration.data[0].vehicle_configuration.options.forEach(option => {
				if(option.description) {
					let optionObj = {};
					optionObj.label = option.description;
					optionObj.price = formatNumber("de_DE", option.price);
					carOptions.push(optionObj);
				}		
			});


			let sections = [];
			let section = {};
			section.label = "Basisfahrzeug";	
			section.price = formatNumber("de_DE", carPrice);	
			section.options = carOptions;
			sections.push(section);

			let section1 = {};
			section1.label = "Dach";
			section1.options = getItems(0);
			section1.price = formatNumber("de_DE", Number(calcCluster(0)));	
			sections.push(section1);

			let section2 = {};
			section2.label = "Zubehör";
			section2.options = getItems(1);
			section2.price = formatNumber("de_DE", Number(calcCluster(1)));	
			sections.push(section2);

			let section3 = {};
			section3.label = "Dekor";
			section3.options = getItems(2);
			section3.price = formatNumber("de_DE", Number(calcCluster(2)));	
			sections.push(section3);
			
			let obj = {};
			obj.sections = sections;
			obj.model = props.props.model;
			obj.pricetotal = formatNumber("de_DE",carPrice + Number(calcCluster(0)) + Number(calcCluster(1)) + Number(calcCluster(2)) );			
			obj.downPayment = formatNumber("de_DE", (carPrice + Number(calcCluster(0)) + Number(calcCluster(1)) + Number(calcCluster(2))) / 100 * 25 );			
			obj.nettoPrice = formatNumber("de_DE", carPrice - ((carPrice + Number(calcCluster(0)) + Number(calcCluster(1)) + Number(calcCluster(2))) / 100 * 25 ));			
			obj.monthlyRate = calcMonth(carPrice - ((carPrice + Number(calcCluster(0)) + Number(calcCluster(1)) + Number(calcCluster(2))) / 100 * 25 ));
			obj.grossSum = calcTotal(carPrice - ((carPrice + Number(calcCluster(0)) + Number(calcCluster(1)) + Number(calcCluster(2))) / 100 * 25 ));
			setUserConfig(obj);
			setRequestConfig(true);
			
		}
	};
	
	const handleItem = (e, uuid, id) => {
		let sC = structuredClone(internalConfiguration);
		sC.forEach((v, k) => {		
			v.cluster.forEach((v1, k1) => {						
				if(v1.uuid == uuid) {
					if(v1.widget == "mandatorySingleChoice" || v1.widget == "mandatorySingleChoiceProductCard") {
						let oo = [];
						oo.push(id);					
						v1.items = oo
					}
					if(v1.widget == "singleChoice" || v1.widget == "singlechoiceProductCard"  || v1.widget == "singlechoiceRoofCard") {
						let oo = [];
						if(e.target.checked === true)
							oo.push(id);					
						v1.items = oo
					}
					
					if(v1.widget == "multiChoice" || v1.widget == "multiChoiceProductCard") {
						if(e.target.checked === true)
							v1.items.push(id);					
						else {
							let oo = [];
							v1.items.forEach((v2, k2) => {
								if(v2 != id)
									oo.push(v2);
							});						
							v1.items = oo
						}										
					}
				}
			});
		});
		setInternalConfiguration(sC);
	};


	const isStepOptional = (step) => {
	    return step === 19;
	  };

  	const isStepSkipped = (step) => {
    	return skipped.has(step);
  	};
  	
  	async function getNewCode() {
		const newConfig =  await getConfigurationCode();
		setConfig(newConfig.result);
	 };
  	

  	const handleNext = () => {
		setCookie('config', config);
    	let newSkipped = skipped;
    	if (isStepSkipped(activeStep)) {
      		newSkipped = new Set(newSkipped.values());
      		newSkipped.delete(activeStep);
    	}

		if(activeStep == 0 && whichConfiguration == "") {
			setDisplayAlert(true);
			setDisplayAlertMsg("Sie müssen ein Fahrzeug auswählen");
		}
		else {
			window.scrollTo(0, 0);
			getNewCode();

	    	setActiveStep((prevActiveStep) => prevActiveStep + 1);
	    	setSkipped(newSkipped);
	    }
  	};

  	const handleBack = () => {
    	setActiveStep((prevActiveStep) => prevActiveStep - 1);
		window.scrollTo(0, 0);
  	};

  	const handleSkip = () => {
    	if (!isStepOptional(activeStep)) {
      		throw new Error("You can't skip a step that isn't optional.");
    	}

    	setActiveStep((prevActiveStep) => prevActiveStep + 1);
    	setSkipped((prevSkipped) => {
      		const newSkipped = new Set(prevSkipped.values());
      		newSkipped.add(activeStep);
      		return newSkipped;
    	});
  	};

  	const handleReset = () => {
		window.scrollTo(0, 0);
    	setActiveStep(0);
  	};
  	
  	const changeCarColor = (carImage) => {
		setCarColor(carImage);
		setCarFinal(carImage);
  	};

  	const changeCarColorFloor = (carImage) => {
		setCarFinal(carImage);
  	};

  	const changeReimoOption = (e, reimoOption) => {
	  	var tmp = new Map(currentReimoOptions);
		if((tmp.has(reimoOption.artnr)))
			tmp.delete(reimoOption.artnr);
		else
			tmp.set(reimoOption.artnr, reimoOption);
		setCurrentReimoOptions(tmp);
  	};

  	const changeFloor = (e, carFloor) => {
		if(e.target.checked == false) {
			setCurrentFloor("");			
		}
		else {
			setCurrentFloor(carFloor);			
		}
  	};
  	
  	const changePad = (e, carPad) => {
		if(e.target.checked == false) {
			setCurrentPad("");			
		}
		else {
			setCurrentPad(carPad);			
		}
  	};
  	
  	const changeRoof = (e, carRoof) => {
		if(e.target.checked == false) {
			setCurrentRoof("");			
			setCarFinal(carColor);
		}
		else {
			setCurrentRoof(carRoof);	
			if(carColor == "/assets/t6-weekender/weiss-ascotgrau.jpg")		
				setCarFinal("/assets/basecars/21190XXX_T6_white_open_floor.jpg");
			else if(carColor == "/assets/t6-weekender/reflexsilber-fortanarot.jpg")		
				setCarFinal("/assets/basecars/21190XXX_T6_red_open_floor.jpg");
			else if(carColor == "/assets/t6-weekender/mojavebeige-deepblack.jpg")		
				setCarFinal("/assets/basecars/21190XXX_T6_black_open_floor.jpg");
		
		}
  	};

  	const toogleRoof = (which) => {
		if(which == "open")
				setCarFinal("/assets/basecars/21190XXX_T6_white_open_floor.jpg");
		if(which == "close")
				setCarFinal("/assets/basecars/21190XXX_T6_white_close_floor.jpg");
		
  	};

  	
  	const changeConfiguration = (e, which, extCode) => {
		try {
			if(e.target.checked && e.target.checked == false) {
				setWhichConfiguration("");			
				setExtCode("");
			}
			else {
				if(which == "internal") {
					getExternalId(extCode,  "internal");
				}
				setWhichConfiguration(which);
			}
		}			
		catch (error) {
			setWhichConfiguration("");	
			setExtCode("");
		
		}			
	};

	async function getExternalId(_extCode, _which) {
		setOpenBD(true);
		let data = await getExternal(_extCode);
		setOpenBD(false);	    	

		if(data.error_message) {
			setDisplayAlert(true);
			setDisplayAlertMsg(data.error_message);
			setExtCode("");
		}
		else {
			let tCarPrice = 0;
			data.data[0].vehicle_configuration.options.map(option => {
				if(!isNaN(option.price))
					tCarPrice += option.price;
			});		
			setWhichConfiguration(_which);
			setCarPrice(tCarPrice);
			setExternalConfiguration(data);
		}
  	};  	

  	function printReimoOption(value) {
		return (<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
		        	<Typography gutterBottom variant="h6" component="div">
		            	{value.label}
		          	</Typography>
		          	<Typography gutterBottom variant="h6" component="div">
			            {formatNumber("de_DE", value.price)}
		          	</Typography>
		        </Stack>)
	}

  	function calcReimoAddonPrice() {
		let treimoAddonPrice = 0;
		if(currentRoof != "")
			treimoAddonPrice = Number(currentRoof.price);
		for (const [key, value] of currentReimoOptions) { 
		    if(!isNaN(value.price))
		    	treimoAddonPrice += Number(value.price);	
		}
		return treimoAddonPrice;
	}
  	
  	function printReimoOptions() {
		let html = [];
		for (const [key, value] of currentReimoOptions) { 
		    html.push( printReimoOption(value));
		}
		return html;
	}
  	
  	function widgetFactory(obj) {
		if(obj.widget == "singleChoice" || obj.widget == "mandatorySingleChoice")
			return renderSingleChoice(obj);	
		else if(obj.widget == "multiChoice")
			return renderSingleChoice(obj);	
		else if(obj.widget == "singlechoiceProductCard" || obj.widget == "mandatorySingleChoiceProductCard" || obj.widget == "multiChoiceProductCard")
			return returnSinglechoiceProductCard(obj);	
			
	}

	
	function isChecked(uuid, id) {
		let checked = false;
		internalConfiguration.forEach((v, k) => {		
			v.cluster.forEach((v1, k1) => {						
				if(v1.uuid == uuid) {
					v1.items.forEach((v2, k2) => {
						if(v2 == id)
							checked = true;
					});						
				}
			});
		});		
		return checked;
	}

  	function renderSingleChoice (obj) {
		return 	<>	
					<h3>{obj.label}</h3>
						{obj.items.map((item)  => (
					        <Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
								<Typography gutterBottom variant="h6" component="div">
									<FormControlLabel
									  label={articles[item.refId].label}
									  control={
										<Checkbox 	data-type="carRoofs" 
													onClick={e => handleItem(e, obj.uuid, item.refId)}
													checked={isChecked(obj.uuid, item.refId)}
										/>	
									}
								/>
									
																		
								</Typography>
							    <Typography gutterBottom variant="h6" component="div">
								   	{formatNumber("de_DE", articles[item.refId].price)}
					          	</Typography>																			          
							</Stack>	
						))}
				</>
	}

  	function returnSinglechoiceProductCard (obj) {
		return 	<>	
					<h3>{obj.label}</h3>
					<Grid container spacing={2}>
							{obj.items.map((item)  => (
								<Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
									<ModelProductCard 	item={articles[item.refId]} 
														changeItem={handleItem} 
														uuid={obj.uuid} 
														currentArtnr={item.refId} 
														checked={isChecked(obj.uuid, item.refId)}/>																		  
								</Grid>
							))}

					</Grid>	
				</>
	}

	function calcCluster(id) {
		let sum = 0;
		internalConfiguration[id].cluster.forEach((v1, k) => {		
				v1.items.forEach((v2, k2) => {
					sum += articles[v2].price;
				});						
		});
		
		return sum;
	};

	function renderCluster(id) {
		return 	<>	
				{internalConfiguration[id].cluster.map((cluster)  => (
					<>
						{cluster.items.length > 0 ? (<>
							<h3>{cluster.label}</h3>
							{cluster.items.map((item)  => (
								<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
									<Typography gutterBottom variant="h6" component="div">
										  {articles[item].label}
																			
									</Typography>
								    <Typography gutterBottom variant="h6" component="div">
									   	{formatNumber("de_DE", articles[item].price)}
						          	</Typography>
								</Stack>
							))}
						</>):(<></>)}
					</>
				))}
				</>
	};


	function renderItems(id) {
		return 	<>	
				{internalConfiguration[id].cluster.map((cluster)  => (
					<>
						{cluster.items.length > 0 ? (<>
							{cluster.items.map((item)  => (
								<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
									<Typography gutterBottom variant="h6" component="div">
										  {articles[item].label}																			
									</Typography>
								    <Typography gutterBottom variant="h6" component="div">
									   	<nobr>{formatNumber("de_DE", articles[item].price)}</nobr>
						          	</Typography>
								</Stack>
							))}
						</>):(<></>)}
					</>
				))}
				</>
	};
		

	
	function renderSmallSumUp() {
		return <Paper className="stickyConfigurationSumUp" sx={{marginTop: "60px"}}>
					<Box>	
						<Box sx={{paddingLeft: "8px", backgroundColor: "rgb(43, 108, 176) !important",minHeight: "40px", lineHeight: "40px", color: "white"}}>
							{t("IhreAuswahl")}
						</Box>	
						<Box sx={{paddingLeft: "8px", paddingRight: "8px", paddingBottom: "8px"}}>
							<Box sx={{alignItems: "center"}}>	
								<center>
						      	<img height="120"
						        	 src="/assets/basecars/vw-caddy-plain.png"		        			
						      	/>	
								</center>
							</Box>	
					      	<hr/>
					      	
							<Accordion sx={{boxShadow1: "none"}}>
						        <AccordionSummary
						          expandIcon={<ExpandMoreIcon />}
						          aria-controls="panel1-content"
						          id="panel1-header"
						        >
   						        	<Box className="clearfix" sx={{width: "-webkit-fill-available"}}>
						        		<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
											<Typography gutterBottom variant="h6" component="div">
												{t("Basisfahrzeug")}
								 			</Typography>
								    		<Typography gutterBottom variant="h6" component="div">
									   			<nobr>&nbsp;{formatNumber("de_DE", carPrice)}</nobr>
						          			</Typography>																			          
										</Stack>	
									</Box>	
						        </AccordionSummary>
						        <AccordionDetails>															        
							    	{externalConfiguration.data[0].vehicle_configuration.options.map(option =>(
										<>
											{option.description ? (																						
												<Stack className="" direction="row" justifyContent="space-between" alignItems="center">
										       		<Typography gutterBottom variant="h6" component="div">{option.description}</Typography>
										          	<Typography gutterBottom variant="h6" component="div">
											            <nobr>{formatNumber("de_DE", option.price)}</nobr>
										          	</Typography>
							        			</Stack>
											) :(<></>)
											}																			
										</>
									))}	
						        </AccordionDetails>
							</Accordion>					      	
							<Accordion sx={{boxShadow1: "none"}}>
						        <AccordionSummary
						          expandIcon={<ExpandMoreIcon />}
						          aria-controls="panel1-content"
						          id="panel1-header"
						        >
						        	<Box className="clearfix" sx={{width: "-webkit-fill-available"}}>
						        		<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
											<Typography gutterBottom variant="h6" component="div">
												{t("Dach")}
								 			</Typography>
								    		<Typography gutterBottom variant="h6" component="div">
									   			<nobr>&nbsp;{formatNumber("de_DE", calcCluster(0))}</nobr>
						          			</Typography>																			          
										</Stack>
									</Box>	
						        </AccordionSummary>
						        <AccordionDetails>
							        {renderItems(0)}															        
						        </AccordionDetails>
							</Accordion>					      	
							<Accordion sx={{boxShadow1: "none"}}>
						        <AccordionSummary
						          expandIcon={<ExpandMoreIcon />}
						          aria-controls="panel1-content"
						          id="panel1-header"
						        >
						        	<Box className="clearfix" sx={{width: "-webkit-fill-available"}}>
						        		<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
											<Typography gutterBottom variant="h6" component="div">
												{t("Zubehör")}
								 			</Typography>
								    		<Typography gutterBottom variant="h6" component="div">
									   			<nobr>&nbsp;{formatNumber("de_DE", calcCluster(1))}</nobr>
						          			</Typography>																			          
										</Stack>	
									</Box>
						        </AccordionSummary>
						        <AccordionDetails>															        
							        {renderItems(1)}															        
						        </AccordionDetails>
							</Accordion>					      	
							<Accordion>
						        <AccordionSummary
						          expandIcon={<ExpandMoreIcon />}
						          aria-controls="panel1-content"
						          id="panel1-header"
						        >
						        	<Box className="clearfix" sx={{width: "-webkit-fill-available"}}>
						        		<Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
											<Typography gutterBottom variant="h6" component="div">
												{t("Dekor")}
								 			</Typography>
								    		<Typography gutterBottom variant="h6" component="div">
									   			<nobr>&nbsp;{formatNumber("de_DE", calcCluster(2))}</nobr>
						          			</Typography>																			          
										</Stack>
									</Box>	
						        </AccordionSummary>
						        <AccordionDetails>															        
							        {renderItems(2)}															        
						        </AccordionDetails>
							</Accordion>					      	
						</Box>	
						<Box sx={{paddingLeft: "8px", paddingRight: "8px", backgroundColor: "rgb(237, 242, 247) !important",minHeight: "40px", lineHeight: "40px"}}>
					        <Stack className="leftSpacing1" direction="row" justifyContent="space-between" alignItems="center">
								<Typography gutterBottom variant="h6" component="div" sx={{lineHeight: "40px"}}>
									{t("Gesamt")}
							 	</Typography>
							    <Typography gutterBottom variant="h6" component="div"  sx={{lineHeight: "40px"}}>
								   	{formatNumber("de_DE", (calcCluster(0) + calcCluster(1) +calcCluster(2) + carPrice) )}
					          	</Typography>																			          
							</Stack>	
						</Box>
					</Box>	
				</Paper>		
	}	
	
	
  	
  	return (<>
				{model != undefined ? (<>
				   			<Alert display={displayAlert} displayAlertMsg={displayAlertMsg} setDisplayAlert={setDisplayAlert}/>

				<Box sx={{ flexGrow: 1, display: {
							xs: 'block',
							sm: 'block',  
							md: 'block',  
							lg: 'block',  
							xl: 'block',  
							
							} }}
				>
	  				<Box sx={{paddingTop: "0px"}}> 
						<Header heroImages={model.heroImages} label={model.label} slogan={t("KonfigureDescription")} breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>
						
			      		<Grid container style={{paddingTop: "0px",paddingLeft: "8px",paddingRight: "8px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
						     	   </Grid>
					        		<Grid item xs={12} sm={12} md={10} lg={8} xl={8}>
										<Box sx={{	marginTop: "30px", 
													backgroundColor: "#fff"
												}}
										>
											<Box  style={{textAlign: "left", color: "#000"}}>					
      											<Stepper activeStep={activeStep}>
													{steps.map((label, index) => {
											        	const stepProps = {};
											          	const labelProps = {};
          												if (isStepOptional(index)) {
															labelProps.optional = (
              													<Typography variant="caption">Optional</Typography>
            												);
          												}
														if (isStepSkipped(index)) {
															stepProps.completed = false;
          												}
          												return (
															<Step key={label} {...stepProps} sx={{fontSize: "28px"}}>
																<StepLabel {...labelProps} sx={{fontSize: "42px"}}>{label}</StepLabel>
															</Step>
          												);
        											})}
												</Stepper>
 												{activeStep === steps.length ? (
        											<React.Fragment>
          												<Typography sx={{ mt: 2, mb: 1 }}>
															All steps completed - you&apos;re finished
          												</Typography>
														<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            												<Box sx={{ flex: '1 1 auto' }} />
																<Button onClick={handleReset}>Reset</Button>
															</Box>
        											</React.Fragment>
      											) : (													  
	        										<React.Fragment>
 														{activeStep === 0 ? (
			          										<Box sx={{marginTop: "20px"}}>
																<Swiper	spaceBetween={30}
																        freeMode={true}
																		navigation={false}
																		breakpoints={{
																		    640: {
																		      width: 640,
																		      slidesPerView: 1,
																		    },
																		    768: {
																		      width: 768,
																		      slidesPerView: 2,
																		    },
																		    1800: {
																		      width: 1800,
																		      slidesPerView: 3,
																		    },
																		}}																	        
																        pagination={{
																          clickable: true,
																        }}
																		modules={[ Pagination, Navigation]}
																        style={{minHeight: "550px"}}
					        									        className="carsSwiper"

																>
				          											{model.engines.map((engine) => (
																        	<SwiperSlide>
																				<ModelCard model={engine} whichConfiguration={whichConfiguration} changeConfiguration={changeConfiguration} getExternalId={getExternalId} extCode={extCode} setExtCode={setExtCode} externalConfiguration={externalConfiguration}/>
																        	</SwiperSlide>
																 	))}
																	<SwiperSlide>
																		<ModelCardOwnConfig model={model.engines[0]} label={model.ownConfigLabel} logo={model.producerLogo} whichConfiguration={whichConfiguration} changeConfiguration={changeConfiguration} getExternalId={getExternalId} extCode={extCode} setExtCode={setExtCode} externalConfiguration={externalConfiguration}/>
														        	</SwiperSlide>

																</Swiper>		
															</Box>		          											

														):(<></>)}
														{activeStep === 1 ? (															
												      		<Grid container spacing={2}>
												        		<Grid item xs={0} sm={7} md={8} lg={8} xl={8}>
																	<Box sx={{marginTop: "20px"}}>
																		<center>
																			<h2>{t("Dachwählen")}</h2>
																		</center>
																		
																		{model.configuration.roof.map((roof, index)  => (
																			<>
																				{index == 0 ? (<>
																					<Grid container spacing={2}>
																					
																						{roof.items.map((mroof) => (
																							<Grid item xs={12} sm={12} md={6} lg={6} xl={4}>
																							
																								<ProductRoofCard 	item={articles[mroof.refId]} 
																													changeItem={handleItem} 
																													uuid={roof.uuid} 
																													currentArtnr={mroof.refId} 
																													checked={isChecked(roof.uuid, mroof.refId)}
																								/>
																							
																							</Grid>
																						))}
																					</Grid>
																																							
																				</>) :(<>
																																								
																					{widgetFactory(roof)}
																					
																				</>)}																	
																			</>
																		))}
																	</Box> 															        											
													     	   	</Grid>
												        		<Grid item xs={0} sm={5} md={4} lg={4} xl={4}>
																	{renderSmallSumUp()}		
													     	   	</Grid>
												     	   </Grid>


														):(<></>)}
														{activeStep === 2 ? (
												      		<Grid container spacing={2}>
												        		<Grid item xs={0} sm={7} md={8} lg={8} xl={8}>
																	<Box sx={{marginTop: "20px"}}>
																		<center>
																			<h2>{t("Zubehörwählen")}</h2>
																		</center>
																		{model.configuration.equipment.map((obj)  => (
																			<>
																				{widgetFactory(obj)}
																			</>
																		))}
																	</Box>	          											
													     	   	</Grid>
												        		<Grid item xs={0} sm={5} md={4} lg={4} xl={4}>
																	{renderSmallSumUp()}		
													     	   	</Grid>
												     	   </Grid>


														):(<></>)}
														{activeStep === 3 ? (
												      		<Grid container spacing={2}>
												        		<Grid item xs={0} sm={7} md={8} lg={8} xl={8}>
																	<Box sx={{marginTop: "20px"}}>
																		<center>
																			<h2>{t("Dekorwählen")}</h2>
																		</center>
																		{model.configuration.decor.map((obj)  => (
																			<>
																				{widgetFactory(obj)}
																			</>
																		))}
		
																	</Box>	          											
													     	   	</Grid>
												        		<Grid item xs={0} sm={5} md={4} lg={4} xl={4}>
																	{renderSmallSumUp()}		
													     	   	</Grid>
												     	   </Grid>
      											

														):(<></>)}
														{activeStep === 4 ? (
			          										<Box sx={{paddingTop: "20px"}}>
																<Grid container>
																	<Grid item xs={12}>
																		<Grid container spacing={2}>
																			<Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
								          										<Box >
								          											<center>
								          												<h2>{t("IhrReimoCamper")}</h2>
								          												<img src={carFinal} width="400"/>
								          											</center>
								          										</Box>
								          										<Paper sx={{padding: "20px", backgroundColor: "#eee"}}>
									          										<Box sx={{padding: "20px", backgroundColor: "#eee"}}>
																						<center>
																					   		<FormControl variant="standard"
																					      				sx={{marginBottom: "10px"}}>
																					        	<Input 	id="input-with-icon-adornment"
																					        			sx={{backgroundColor: "#fff"}}
																					          			disabled="true"
																					          			value={config}
																					         			endAdornment={
																						            		<InputAdornment position="end">
																						              			<ContentCopyIcon />
																						            		</InputAdornment>
																					          			}
																					        	/>
            																				</FormControl>
              																				<br/>
																							<Button onClick={e => callUrl("requestConfig")} 
																									endIcon={<SendIcon />}
																									variant="contained">
																									{t("KonfigurationperEmailschicken")}
																							</Button>																							
																						</center>
									          										</Box>	
								          										</Paper>
			          														</Grid>	
			          																											
																			<Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{borderLeft: "1px solid #aaa"}}>
																				<Paper sx={{padding: "20px", marginBottom: "20px"}}>
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			          <Typography gutterBottom variant="h5" component="div">
																			            {t("MonatlicheRate")}
																			          </Typography>																			          																			          
																			        </Stack>		
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center" sx={{marginLeft: "30px"}}>
																			          <Typography gutterBottom variant="h6" component="div">
																				            {formatNumber("de_DE",  calcMonth((calcCluster(0) + calcCluster(1) +calcCluster(2) + carPrice)))} / Monat
																			          </Typography>
																			        </Stack>
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center"  sx={{marginLeft: "30px"}}>
																			          <Typography gutterBottom variant="h6" component="div">
																			            <CalculateIcon />&nbsp;{t("meineRateberechnen")}
																			          </Typography>
																			        </Stack>																			        		
																				</Paper >
																				<Paper sx={{padding: "20px", marginBottom: "20px"}}>
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			          <Typography gutterBottom variant="h6" component="div">
																			            {t("Fahrzeugpreis")}:
																			          </Typography>
																			          	<Typography gutterBottom variant="h6" component="div">
																				            <nobr>{formatNumber("de_DE", carPrice)}</nobr>
																			          	</Typography>																			          
																			        </Stack>		
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			          <Typography gutterBottom variant="h6" component="div">
																			           {t("PreisReimoZusatzOptionen")}:
																			          </Typography>
																			          	<Typography gutterBottom variant="h6" component="div">
																				           <nobr>{formatNumber("de_DE", (calcCluster(0) + calcCluster(1) +calcCluster(2)))}</nobr>
																			          	</Typography>																			          
																			        </Stack>
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			          <Typography gutterBottom variant="h5" component="div">
																			            {t("Gesamtpreis")}:
																			          </Typography>
																			          	<Typography gutterBottom variant="h5" component="div">
																				            <nobr>{formatNumber("de_DE",  (calcCluster(0) + calcCluster(1) +calcCluster(2) + carPrice))}</nobr>
																			          	</Typography>																			          
																			        </Stack>																			        		
																				</Paper >
																				
																				<Paper sx={{padding: "20px"}}>
																			        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			          <Typography gutterBottom variant="h6" component="div">
																			            {t("GerneSchickenWirIhnenEinUnverbindlichesAngebotZu")}:
																			          </Typography>
																			          																			          
																			        </Stack>		
																			        <Stack 	className="leftSpacing" 
																			        		direction="row" 
																			        		justifyContent="space-between"
																			        		sx={{flexDirection: "row-reverse"}}																			        		
																			        		alignItems="right">
																						<Button onClick={e => callUrl("requestOffer")}
																								endIcon={<ReceiptIcon />} 
																								variant="contained">{t("anfragen")}
																						</Button>
																			        </Stack>																			        		
																				</Paper >


																			</Grid>														
																		</Grid>														
																	</Grid>														
																</Grid>	
																<br/>
																<Accordion>
															        <AccordionSummary
															          expandIcon={<ExpandMoreIcon />}
															          aria-controls="panel1-content"
															          id="panel1-header"
															        >
															          <h3 sx={{padding: "0"}}><b>{t("Grund Fahrzeug")}</b></h3>
															        </AccordionSummary>
															        <AccordionDetails>															        
																        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																          <Typography gutterBottom variant="h6" component="div">
																            {externalConfiguration.data[0].vehicle_configuration.model_description}
																          </Typography>
																        </Stack>		
																        <Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																          <Typography gutterBottom variant="h7" component="div">
																            {t("ExterneConfiguration")}: {extCode}
																          </Typography>
																        </Stack>		
															        </AccordionDetails>
      															</Accordion>
																<Accordion>
															        <AccordionSummary
															          expandIcon={<ExpandMoreIcon />}
															          aria-controls="panel1a-content"
															          id="panel1a-header"
															        >
															          <h3 sx={{padding: "0"}}><b>{t("Grund Fahrzeug Optionen")}</b></h3>
															        </AccordionSummary>
															        <AccordionDetails>															        
																    	{externalConfiguration.data[0].vehicle_configuration.options.map(option =>(
																			<>
																				{option.description ? (																						
																					<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
																			       		<Typography gutterBottom variant="h6" component="div">{option.description}</Typography>
																			          	<Typography gutterBottom variant="h6" component="div">
																				            {formatNumber("de_DE", option.price)}
																			          	</Typography>
																        			</Stack>
																				) :(<></>)
																				}																			
																			</>
																		))}


															        </AccordionDetails>
      															</Accordion>

																<Accordion>
														        	<AccordionSummary
															          expandIcon={<ExpandMoreIcon />}
															          aria-controls="panel2-content"
															          id="panel2-header"
															        >
														          	<h3><b>{t("Dach")}</b></h3>
														        </AccordionSummary>
														        <AccordionDetails>
																	{renderCluster(0)}
														        </AccordionDetails>
														      </Accordion>
														      <Accordion>
														      	<AccordionSummary
														          expandIcon={<ExpandMoreIcon />}
														          aria-controls="panel3-content"
														          id="panel3-header"
														        >
														          	<h3><b>{t("Zubehör")}</b></h3>
														        </AccordionSummary>
														        <AccordionDetails>
																	{renderCluster(1)}
														        </AccordionDetails>
														      </Accordion>

														      <Accordion>
														      	<AccordionSummary
														          expandIcon={<ExpandMoreIcon />}
														          aria-controls="panel3-content"
														          id="panel3-header"
														        >
														          	<h3><b>{t("Dekor")}</b></h3>
														        </AccordionSummary>
														        <AccordionDetails>
																	{renderCluster(2)}
														        </AccordionDetails>
														      </Accordion>

															</Box>	          											
														):(<></>)}

														
														<Box sx={{ 	display: 'flex', 
																	flexDirection: 'row', 
																	pt: 2 
																}}
														>
		            										<Button	variant="contained" 
									        						startIcon={<ArrowBackIosIcon />}
														            disabled={activeStep === 0}
														            onClick={handleBack}
														            sx={{ mr: 1 }}
														     >
		              											{t("back")}
		            										</Button>
		            										<Box sx={{ flex: '1 1 auto' }} />		
												              	{activeStep === steps.length - 1 ? 
												              		( <>
												              		 	  <Button onClick={handleReset}
												              		 	  		  variant="contained"
												              		 	  		  endIcon={<RestartAltIcon />}
												              		 	  		  sx=	{{	marginRight: "5px", 
																							display:{	xs: 'none',
																										sm: 'inline-flex',  
																										md: 'inline-flex',  
																										lg: 'inline-flex',  
																										xl: 'inline-flex',  							
																									}
																						}}
												              		 	  >
												              		 	  	{t("erneutStarten")}		  
												              		 	  </Button>
																		  <Button onClick={e => callUrl("requestConfig")} 
												              				 endIcon={<SendIcon />}
												              				 variant="contained"
												              			  >
												              				{t("KonfigurationperEmailschicken")}
												              		 	  </Button>
																	  </>																							
																	) : 												              	
												              		(<Button variant="contained" 
											        						 endIcon={<ArrowForwardIosIcon />}
																             sx={{ mr: 1 }}onClick={handleNext}
																     >
															         	{t("next")}
																      </Button>
																)}
												        </Box>
												        
											        </React.Fragment>
												)}


											</Box>			
										</Box>	

						     	   </Grid>
					        		<Grid item xs={0} sm={0} md={1} lg={2} xl={2}>
						     	   </Grid>
					     	   </Grid>
				     	   </Grid>
			     	   	</Grid>

		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons/>
						</Box>
					</Box>
					<RequestOffer open={requestOffer} handleOpen={setRequestOffer} config={config} car={props.props.model}/>
					<RequestConfig open={requestConfig} handleOpen={setRequestConfig} config={config} car={props.props.model} userConfig={userConfig}/>
			      	<Backdrop
				        sx={{ color: '#fff', zIndex: 8000 }}
				        open={openBD}
				        onClick={handleCloseBD}
				      >
				        <CircularProgress color="inherit" />
				    </Backdrop>      			

   			</Box>
   		</>):( <>
   			   </>)
   		}
)
   			
   		</>
   	)
}

export default function ConfigurePage(props) {

  return <ConfigureContent props={props}/>;
}