import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import schema from '../forms/schema.json';




const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function ImageWidget(props) {
	console.log(props);
	return (
		<>
		<Grid container style={{paddingBottom: "10px"}}>
			<Grid item xs={10}>

				<TextField 	id="outlined-basic" 
							label={props.label} 
							defaultValue={props.value} 
							variant="outlined" 
							multiline
							sx={{minWidth:"90%", marginBottom: "10px"}}/>


				{props.currentImages.map(image  => (
					<>
						{image.name}<br/>
					</>				
				))}
				
   			</Grid>		
				
			<Grid item xs={2}>
				<InsertPhotoIcon />	

   			</Grid>		
		</Grid>		
	
		
		
		</>	
	);
}



export default function CustomizedFormDialogs(props) {
	const [currentImage, setCurrentImage] = React.useState(props.currentImage);

	const handleClose = () => {
		props.handleClose(false);
	};

  	const handleChange = () => {
		props.handleChange(currentImage);
    	props.handleClose(false);
	};

  	const handleCheckboxChange = (e) => {
    	setCurrentImage(e.target.value);
	};

 	return (<React.Fragment>
	      		<BootstrapDialog 	onClose={handleClose}
	        						aria-labelledby="customized-dialog-title"
	        						open={props.open}
	        						
	      		>
		        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
		          BildAuswahl
		        </DialogTitle>
	        		<IconButton aria-label="close"
	          					onClick={handleClose}
						          sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						          }}
	        		>
	 					<CloseIcon />
					</IconButton>
	        	<DialogContent dividers>
					<Box sx={{minHeight: "300px", minWidth: "600px"}}>	
					
						<Swiper
						        slidesPerView={3}
						        spaceBetween={30}
						        freeMode={true}
						        pagination={{
						          clickable: true,
						        }}
						        modules={[FreeMode, Pagination]}
						        className="carsSwiper"
						        style={{minHeight: "200px", backgroundColor: "hsla(0,0%,94.9%,.5)"}}
						      >
								{props.currentImages.map(image  => (	
						        	<SwiperSlide>
						        		<img src={image.path} width="200"/>
						        		<Checkbox  	onChange={e => handleCheckboxChange(e)} 
									        		value={image.path}
									        		checked={currentImage === image.path ? true : false}
						        		/>
										{image.name}
						        	</SwiperSlide>
						        ))}
						</Swiper>					
					
															
						
					</Box >	
	        	</DialogContent>
	        <DialogActions>
	          <Button autoFocus onClick={handleClose}>
	            Cancel
	          </Button>
	          <Button autoFocus onClick={handleChange}>
	            Save
	          </Button>
	        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}