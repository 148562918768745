import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import AutoStoriesIcon from '@mui/icons-material/AutoStories';

export default function ServiceCard(props) {

	const { t, i18n } = useTranslation("",{lng: props.language});



  	return (
<>
  			<img src={props.logo} style={{width: "120px", height: "40px", float: "right"}}/>
			<Card sx={{ border: "none", boxShadow: "none",
			maxWidth: {
							xs: 245,
							sm: 200,  
							md: 200,  
							lg: 245,  
							xl: 300,  							
							},
			minHeight: {
							xs: 245,
							sm: 245,  
							md: 245,  
							lg: 245,  
							xl: 200,  
							
							}
							
			
			
			}} className="centerCarCard">

      			<CardHeader titleTypographyProps={{variant:'h6' }}
        					title={props.header}
							subheader={props.subline}
							sx={{minHeight: "80px"}}
				/>
      			<CardContent sx={{minHeight: "40px"}}>
			        <Typography variant="body2" color="text.secondary">
						<em>{props.description}</em>
			        </Typography>
				</CardContent>
      			<CardActions disableSpacing 
      						 sx={{  alignSelf: "stretch",
								      	display: "flex",
								      	justifyContent: "flex-end",
								      	alignItems: "flex-start",
								      	p: 0,
					}}>
        			<Button aria-label="add to favorites"
        					variant="contained"
        					target="_blank"
        					href={props.url}
        					endIcon={<AutoStoriesIcon />}>
          				{t("zumartikel")}
        			</Button>				
				</CardActions>
    		</Card>
    		</>
  );
}