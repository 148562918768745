import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CloseIcon from '@mui/icons-material/Close';

import {sendAction} from "../helper/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function CarWizzard(props) {
  	const [ status, setStatus] = React.useState("idle");
  	const [ firstName, setFirstName] = React.useState("");
  	const [ lastName, setLastName] = React.useState("");
  	const [ address1, setAddress1] = React.useState("");
  	const [ address2, setAddress2] = React.useState("");
  	const [ zip, setZip] = React.useState("");
  	const [ city, setCity] = React.useState("");
  	const [ country, setCountry] = React.useState("");
  	const [ state, setState] = React.useState("");
  	const [ email, setEmail] = React.useState("");
  	const [ phone, setPhone] = React.useState("");
  	const [ mobil, setMobil] = React.useState("");
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };
	const { t, i18n } = useTranslation("",{lng: props.language});

    const handleClose = () => {
    	props.handleOpen(false);
  	};

	async function doSubmit(value)  {
		let obj = {};
		obj.firstName = firstName;  
		obj.lastName = lastName;  
		obj.address1 = address1;  
		obj.address2 = address2;  
		obj.zip = zip;  
		obj.city = city;  
		obj.country = country;  
		obj.state = state;  
		obj.email = email;  
		obj.phone = phone;  
		obj.mobil = mobil;  

		setStatus("progress");
		await sendAction(obj, "requestMaterial");
		setStatus("finish");	
    	props.handleOpen(false);
  	}; 
    	

  	return (<React.Fragment>
				<BootstrapDialog 	className="animate__animated animate__zoomInDown" onClose={handleClose}
									aria-labelledby="customized-dialog-title"
									hideBackdrop={true}
 									open={props.open}
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			{t("Infomaterial anfordern")}
	        		</DialogTitle>
			        <IconButton
			          aria-label="close"
			          onClick={handleClose}
			          sx={{
			            position: 'absolute',
			            right: 8,
			            top: 8,
			            color: (theme) => theme.palette.grey[500],
			          }}
			        >
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<React.Fragment>
 							<Typography variant="h6" gutterBottom>
        						Dürfen wir bei einer Vorauswahl helfen?
      						</Typography>

							
							<hr/>
 							<Typography variant="h6" gutterBottom>
        						Ihre Adresse
      						</Typography>
      						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus onClick={doSubmit}>
				            abschicken
				        </Button>
			        </DialogActions>
      			</BootstrapDialog>
    </React.Fragment>
  );
}