import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Rating from '@mui/material/Rating';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import schema from '../forms/schema.json';
import ImagePicker from './ImagePicker.js';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

function a11yProps(index) {
  	return {
    		id: `vertical-tab-${index}`,
    		'aria-controls': `vertical-tabpanel-${index}`,
  	};
}


function VTabPanel(props) {
	const { children, value, index, ...other } = props;

  	return (<div 	role="tabpanel"
      				hidden={value !== index}
      				id={`vertical-tabpanel-${index}`}
      				aria-labelledby={`vertical-tab-${index}`}
      				{...other}
      				style={{ minWidth: "60%"}}
    		>
				{value === index && (
					<Box sx={{ p: 0, pt: 1, pl: 1 }}>
          				<Typography>{children}</Typography>
        			</Box>
      			)}
    		</div>
  	);
}

function TextWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	id="outlined-basic" 
						label={props.label} 
						defaultValue={props.value} 
						onChange={e => handleChange(e)}
						variant="outlined" 
						sx={{minWidth:"90%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}

function MultiTextWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	id="outlined-basic" 
						multiline
						maxRows={5}
						label={props.label} 
						defaultValue={props.value} 
						onChange={e => handleChange(e)}
						variant="outlined" 
						sx={{minWidth:"90%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}

function NumberWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	type="number"
						step="any"
			  			label={props.label} 
						onChange={e => handleChange(e)}
			  			InputProps={{ inputProps: { min: 0, max: 40 } }}
			  			defaultValue={props.value}
			  			variant="outlined"
			 			sx={{minWidth:"50%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}

function IntegerWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};

	return (
		<>
			<TextField 	type="number"
						step="any"
			  			label={props.label} 
						onChange={e => handleChange(e)}
			  			InputProps={{ inputProps: { min: 0, max: 256 } }}
			  			defaultValue={props.value}
			  			variant="outlined"
			 			sx={{minWidth:"50%", marginBottom: "10px"}}/>
			<br/>	
		</>	
	);
}



function PdfWidget(props) {
	const [usedPdfs, setUsedPdfs] = React.useState(props.value);
	
	const handleChange = (event) => {
	    const {
	      target: { value },
	    } = event;
	    setUsedPdfs(
	      typeof value === 'string' ? value.split(',') : value,
	    );
	    props.obj[props.objKey] = typeof value === 'string' ? value.split(',') : value;

	};	

	
	return (
		<>
			<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
			  <InputLabel id="pdf-select-label">PDF Download</InputLabel>
			  <Select
		          labelId="demo-multiple-checkbox-label"
		          id="demo-multiple-checkbox"
		          multiple
		          value={usedPdfs}
		          onChange={handleChange}
		          input={<OutlinedInput label="Tag" />}
		          renderValue={(selected) => selected.join(', ')}
		          MenuProps={MenuProps}
	        >
			  {props.pdfs.map(pdf  => (
	              <MenuItem key={pdf.path} value={pdf.path}>
		              <Checkbox checked={usedPdfs.indexOf(pdf.path) > -1} />
		              <ListItemText primary={pdf.name} />
		            </MenuItem>
				))}			  
			  </Select>
			</FormControl>
		</>	
	);
}

function ProducerWidget(props) {
	const handleChange = (e) => {
		props.obj[props.objKey] = e.target.value;
	};
	
	return (
		<>
			<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
			  <InputLabel id="producer-select-label">Hersteller</InputLabel>
			  <Select
			    labelId="producer-select-label"
			    id="producer-select"
			    label="Hersteller"
	  			value={props.value}
			    onChange={e => handleChange(e)}
			  >
			    <MenuItem value={'vw-logo.png'}>VW</MenuItem>
			    <MenuItem value={'mb-logo.png'}>Mercedes</MenuItem>
			    <MenuItem value={'ford-logo.png'}>Ford</MenuItem>
			  </Select>
			</FormControl>
		</>	
	);
}

function TabWidget(props) {
	return (
		<>
			Bewertung
			<br/>	
		</>	
	);
}

function UspsWidget(props) {
	return (
		<div>
			UspsWidget
			<br/>	
		</div>	
	);
}



function BaseCarWidget(props) {
  	return (<Box sx={{mt: "10px"}}>
				{props.value.map((baseCar) => (
					<>
						<TextWidget label="Label" 
									value={baseCar.label}
									objKey="label"
									obj={baseCar}
						/>
						<TextWidget label="Konfigurationscode" 
									value={baseCar.configCode}
									objKey="label"
									obj={baseCar}
						/>
						<NumberWidget 	label="Preis" 
										value={baseCar.price}
										objKey="price"
										obj={baseCar}
									/>
						
						<hr style={{width: "90%", marginBottom: "10px"}}/>
					</>
				))}
			</Box>
	);
}
	  

function ConfigurationWidget(props) {
	var articles = require('../../../db/articles.json');	

	const [value, setValue] = React.useState(0);

  	const handleChange = (event, newValue) => {
    	setValue(newValue);
  	};

  	return (
    		<Box sx={{ flexGrow: 1, 
    					bgcolor: 'background.paper', 
    					display: 'flex', 
    					height: 224 }}
		    >
				<Tabs 	orientation="vertical"
						variant="scrollable"
        				value={value}
				        onChange={handleChange}
				        aria-label="Vertical tabs example"
				        sx={{ borderRight: 1, borderColor: 'divider' }}
				>
        			<Tab label="Dächer" {...a11yProps(0)} />
					<Tab label="Zubehör" {...a11yProps(1)} />
					<Tab label="Dekor" {...a11yProps(2)} />
      			</Tabs>
      			<VTabPanel value={value} index={0}>
        			{props.value.roof.map((v, index) => (
						<>
							
							<TextField 	id="outlined-basic" 
										label="Label" 
										defaultValue={v.label} 
										variant="outlined" 
										sx={{minWidth:"100%", marginBottom: "10px"}}
							/>
							<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
							  <InputLabel id="producer-select-label">Darstellung</InputLabel>
							  <Select
							    labelId="producer-select-label"
							    id="producer-select"
							    label="Hersteller"
					  			value={v.widget}
							  >
							  
							    <MenuItem value={'singlechoiceRoofCard'}>singlechoiceRoofCard</MenuItem>
							    <MenuItem value={'mandatorySingleChoice'}>mandatorySingleChoice</MenuItem>
							    <MenuItem value={'multiChoice'}>multiChoice</MenuItem>
							    <MenuItem value={'checkbox'}>checkbox</MenuItem>
							    <MenuItem value={'mmandatorySingleChoiceProductCard'}>mandatorySingleChoiceProductCard</MenuItem>
							    <MenuItem value={'singlechoiceProductCard'}>singlechoiceProductCard</MenuItem>
							  </Select>
							</FormControl>
							<br/>
							<FormControl sx={{minWidth:"50%", marginBottom: "10px"}}>
							  <InputLabel id="pdf-select-label">Artikel</InputLabel>
							  <Select
						          labelId="demo-multiple-checkbox-label"
						          id="demo-multiple-checkbox"
						          input={<OutlinedInput label="Tag" />}
						          renderValue={(selected) => selected.join(', ')}
						          MenuProps={MenuProps}
					        >
  							  {Object.keys(articles).map((key, index) => (
									<MenuItem key={key} value={key}>
						              <Checkbox  />
						              <ListItemText primary={articles[key].label} />
						            </MenuItem>
								))}	

							  </Select>
							</FormControl>
							
							<hr/>
						</>
					))}
      			</VTabPanel>
      			<VTabPanel value={value} index={1}>
        			{props.value.equipment.map((v, index) => (
						<>
							{v.label}
							<hr/>
						</>
					))}
      			</VTabPanel>
      			<VTabPanel value={value} index={2}>
        			{props.value.decor.map((v, index) => (
						<>
							{v.label}
							<hr/>
						</>
					))}
      			</VTabPanel>
    		</Box>
  );
}



function ImageWidget(props) {
	const [open, setOpen] = React.useState(false);
	const [val, setVal] = React.useState(props.value);

	const handleChange = (value) => {
		props.obj[props.objKey] = value;
		setVal(value);
	};


   	const handleClickOpen = () => {
    	setOpen(true);
  	};

	return (
		<>
			<Grid container style={{paddingBottom: "10px"}}>
				<Grid item xs={8}>
	
					<TextField 	id="outlined-basic" 
								label={props.label} 
								value={val} 
								variant="outlined" 
								multiline
								sx={{minWidth:"90%", marginBottom: "10px"}}/>
					
	   			</Grid>		
					
				<Grid item xs={4}>					
					<Button variant="contained" 
							onClick={handleClickOpen}
							endIcon={<InsertPhotoIcon />}>
					  auswählen
					</Button>					
	
	   			</Grid>		
			</Grid>		
			<ImagePicker open={open} handleChange={handleChange} handleClose={setOpen} currentImage={val} currentImages={props.currentImages}/>
		
		</>	
	);
}

function ImagesWidget(props) {
	const [open, setOpen] = React.useState(false);
	const [val, setVal] = React.useState(props.value);

	const handleChange = (value) => {
		props.obj[props.objKey] = value;
		setVal(value);
	};


   	const handleClickOpen = () => {
    	setOpen(true);
  	};

	return (
		<>
			<Grid container style={{paddingBottom: "10px"}}>
				<Grid item xs={8}>
	
					<TextField 	id="outlined-basic" 
								label={props.label} 
								value={val} 
								variant="outlined" 
								multiline
								sx={{minWidth:"90%", marginBottom: "10px"}}/>
					
	   			</Grid>		
					
				<Grid item xs={4}>					
					<Button variant="contained" 
							onClick={handleClickOpen}
							endIcon={<InsertPhotoIcon />}>
					  auswählen
					</Button>					
	
	   			</Grid>		
			</Grid>		
			<ImagePicker open={open} handleChange={handleChange} handleClose={setOpen} currentImage={val} currentImages={props.currentImages}/>
		
		</>	
	);
}

function RatingWidget(props) {


	const [everyday, setEveryday] = React.useState(props.value.Everyday);
	const [cityTrips, setCityTrips] = React.useState(props.value.CityTrips);
	const [suitableForFamilies, setSuitableForFamilies] = React.useState(props.value.SuitableForFamilies);
	const [longerTrips, setLongerTrips] = React.useState(props.value.LongerTrips);

	const handleChange = (e, v) => {
		props.value[e.target.name] = v;
		if(e.target.name == "Everyday")
			setEveryday(v);
		if(e.target.name == "CityTrips")
			setCityTrips(v);
		if(e.target.name == "SuitableForFamilies")
			setSuitableForFamilies(v);
		if(e.target.name == "LongerTrips")
			setLongerTrips(v);
	};
	
	
	return (
		<>
			<Typography component="legend">Everyday</Typography>			
			<Rating
		        name="Everyday"
		        value={everyday}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">CityTrips</Typography>			
			<Rating
		        name="CityTrips"
		        value={cityTrips}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">SuitableForFamilies</Typography>			
			<Rating
		        name="SuitableForFamilies"
		        value={suitableForFamilies}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<Typography component="legend">LongerTrips</Typography>			
			<Rating
		        name="LongerTrips"
		        value={longerTrips}
				onChange={(e, v) => handleChange(e, v)}
		      />			
			<br/>	
		</>	
	);
}


function Widget(props) {
	const widgetProps = schema.properties[props.objkey];
	return (<>
				{widgetProps != undefined && widgetProps.tabId == props.tab? (<>

												{widgetProps.type == "string" ?	(<>
																					<TextWidget label={widgetProps.title} 
																								value={props.value}
																								objKey={props.objkey}
																								obj={props.obj}
																					/>
																				</>) : (<></>)}
												{widgetProps.type == "stringMultiline" ?	(<>
																					<MultiTextWidget label={widgetProps.title} 
																								value={props.value}
																								objKey={props.objkey}
																								obj={props.obj}
																					/>
																				</>) : (<></>)}
												{widgetProps.type == "number" ?	(<>
																					<NumberWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "integer" ?	(<>
																					<IntegerWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "producer" ?	(<>
																					<ProducerWidget label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "image" ?	(<>
																					<ImageWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																									currentImages={props.currentImages}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "images" ?	(<>
																					<ImagesWidget 	label={widgetProps.title} 
																									objKey={props.objkey}
																									value={props.value}
																									obj={props.obj}
																									currentImages={props.currentImages}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "downloads" ?	(<>
																					<PdfWidget label={widgetProps.title} 
																								value={props.value}
																								objKey={props.objkey}
																								obj={props.obj}
																								pdfs={props.currentPdfs}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "rating" ?	(<>
																					<RatingWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "usps" ?	(<>
																					<UspsWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "baseCarTabs" ?	(<>
																					<BaseCarWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
												{widgetProps.type == "configurationTabs" ?	(<>
																					<ConfigurationWidget 	label={widgetProps.title} 
																									value={props.value}
																									objKey={props.objkey}
																									obj={props.obj}
																								/>
																				</>) : (<></>)}
							  
											 </>)
				 :(<></>)
				}
			</>	
	);
}



export default function CustomizedFormDialogs(props) {
	const [value, setValue] = React.useState("1");
	
	const handleClose = () => {
		props.obj.label = props.objCurrrent.label;
		props.handleClose(false);
	};

  	const handleChange = () => {
    	props.handleClose(false);
	};
	

  	const handleTabChange = (event, newValue) => {
    	setValue(newValue);
  	};	

 	return (<React.Fragment>
	      		<BootstrapDialog 	onClose={handleClose}
	        						aria-labelledby="customized-dialog-title"
	        						open={props.open}
	        						
	      		>
		        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
		          Datenpflege
		        </DialogTitle>
	        		<IconButton aria-label="close"
	          					onClick={handleClose}
						          sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						          }}
	        		>
	 					<CloseIcon />
					</IconButton>
	        	<DialogContent dividers>
    				<Box sx={{ width: '100%', typography: 'body1' }}>
      					<TabContext value={value}>
        					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList 	onChange={handleTabChange} 
											aria-label="xyz"
											variant="scrollable"
											scrollButtons
											allowScrollButtonsMobile
								>
            						<Tab label="Grunddaten" value="1" />
									<Tab label="Dateien und Bilder" value="2" />
									<Tab label="Marketing Texte" value="3" />
									<Tab label="Konfigurator CarVarianten" value="4" />
									<Tab label="Konfigurator Reimo" value="5" />
          						</TabList>
        					</Box>
							<TabPanel value="1">
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									 { 
									   Object.entries(props.obj).map(([k, v]) => {
									     	return <><Widget tab="1" objkey={k} value={v} obj={props.obj} currentImages={props.currentImages} currentPdfs={props.currentPdfs}/></>
									   })
									 }
								</Box >	
							</TabPanel>
							<TabPanel value="2">
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									 { 
									   Object.entries(props.obj).map(([k, v]) => {
									     	return <><Widget tab="2" objkey={k} value={v} obj={props.obj} currentImages={props.currentImages} currentPdfs={props.currentPdfs}/></>
									   })
									 }
								</Box >	
							</TabPanel>
							<TabPanel value="3">
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									 { 
									   Object.entries(props.obj).map(([k, v]) => {
									     	return <><Widget tab="3" objkey={k} value={v} obj={props.obj} currentImages={props.currentImages} currentPdfs={props.currentPdfs}/></>
									   })
									 }
								</Box >								
							</TabPanel>
							<TabPanel value="4" sx={{padding: "0px"}}>
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									 { 
									   Object.entries(props.obj).map(([k, v]) => {
									     	return <><Widget tab="4" objkey={k} value={v} obj={props.obj} currentImages={props.currentImages} currentPdfs={props.currentPdfs}/></>
									   })
									 }
								</Box >								
							</TabPanel>
							<TabPanel value="5" sx={{padding: "0px"}}>
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									 { 
									   Object.entries(props.obj).map(([k, v]) => {
									     	return <><Widget tab="5" objkey={k} value={v} obj={props.obj} currentImages={props.currentImages} currentPdfs={props.currentPdfs}/></>
									   })
									 }
								</Box >								
							</TabPanel>
      					</TabContext>
    				</Box>        	
	        	
	        	
	        	
	        	</DialogContent>
	        <DialogActions>
	          <Button autoFocus onClick={handleClose}>
	            Cancel
	          </Button>
	          <Button autoFocus onClick={handleChange}>
	            Save
	          </Button>
	        </DialogActions>
      </BootstrapDialog>

    </React.Fragment>
  );
}