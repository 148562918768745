import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';

import {sendAction} from "../helper/utils";

import 'animate.css';

function SimpleDialog(props) {
	const { onClose, selectedValue, open } = props;
  	const [ status, setStatus] = React.useState("idle");
  	const [ email, setEmail] = React.useState("");
  	const [ emailAlert, setEmailAlert] = React.useState(false);
  	const [ alert, setAlert] = React.useState(false);
  	
	const { t, i18n } = useTranslation("",{lng: props.language});

	const handleClose = () => {
	    onClose(selectedValue);
	};

  	const handleListItemClick = (value) => {
    	onClose(value);
  	};    

  	async function doSubmit(value)  {
		if(email == "") {
			setAlert(true);
			setEmailAlert(true)
		}
		else {
			setEmailAlert(false)
		}
		
		let user = {};
		user.email = email;
		
		let obj = {};
		obj.user = user; 

		if(alert === false) {
			setStatus("progress");
			await sendAction(obj, "newsletterAbo");
			setStatus("finish");	
		}
		
  };    

  return (
		<Dialog className="animate__animated animate__zoomInDown" 
	    		onClose={handleClose} 
	    		open={open} 
	    		hideBackdrop={true}
	    		overlayStyle={{backgroundColor: 'transparent'}}
	    		PaperProps={{ sx: { borderRadius: "50%",width: "600px", height: "600px"}}}
    		>
			<div class="quote-wrapper" >
				<div class="textNewsletter" style={{backgroundImage: "url('/assets/test.png')", backgroundSize: "cover", color: "#000"}}>			    
			    	<p style={{margin: 0}}>
			    		<nobr><span class="stdSubheadline sloganNewPoint">Newsletter</span></nobr>
			    		<br/><br/>
			    		<span class="stdContent">
				    		{t("NewsletterSlogan")}<br/>{t("NewsletterDescription")}
						</span>
			    		<br/><br/>
				    	{status == "idle" ? ( 
							<>
							    <TextField
									sx={{
							          	input: {
							            background: "white", width: "300px"
							          }
							        }}		
									InputLabelProps={{
									      sx: {
									        color: "#518eb9",
									        fontSize: "18px",
									        fontWeight: 1000,
									        "&.MuiOutlinedInput-notchedOutline": { fontSize: "18px" }
									      }
									    }}						                  
						          	id="outlined-error"
						          	label="Ihre Email-Adresse"
						          	placeholder="example@domain.com"					          	
									value={email}
									onChange={e => {setEmail(e.target.value);}}
	
						        /> 
						        <br/><br/>
								{email == "" ? (	<Button variant="contained" disabled onClick={doSubmit}>
												         {t("anmelden")}
													</Button>
												) : (<Button variant="contained"  onClick={doSubmit}>
												         {t("anmelden")}
													</Button>)}
							</>	) : (<></>)					
						}
				    	{status == "progress" ? ( 
							<>
							    progess
							</>	) : (<></>)					
						}
						{status == "finish" ? ( 
							<>
							    {t("NewsletterOKSlogan")}<br/>{t("NewsletterOKDescription")}
							</>	) : (<></>)					
						}
					</p>
			  	</div>
			</div>
    	</Dialog>
  );
}

export default function Newsletter(props) {
  const [open, setOpen] = React.useState(props.open);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    props.handleNewsletterDisplay(false);
  };

  return (
    <div>
		<SimpleDialog 	open={open}
        				onClose={handleClose}
      	/>
    </div>
  );
}