import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import Avatar from '@mui/material/Avatar';

import Campy from "../campy/Campy";


import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import RequestDate from '../dialogs/RequestDate';
import RequestMaterial from '../dialogs/RequestMaterial';


export default function ReimoSpeedDial(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

	const [requestDate, setRequestDate] = React.useState(false);
	const [requestMaterial, setRequestMaterial] = React.useState(false);


	const actions = [
	  { icon: <CalendarMonthIcon sx={{fontSize: '28px', color: 'white'}}/>, name: t("Terminvereinbaren") , id: "requestDate" },
	  { icon: <NewspaperIcon  sx={{fontSize: '28px', color: 'white'}}/>, name: t("InfoMaterialanfordern") , id: "requestMaterial"},
	];



	const handleAction = (e, action) => {
		if(action == "requestDate")
			setRequestDate(true);
		if(action == "requestMaterial")
			setRequestMaterial(true);
  	};
  	

	return (<>
				<Box
			          role="presentation"
			          sx={{
			            position: "fixed",
			            bottom: 0,
			            right: 0,
			            zIndex: 10000,
			            bgcolor: 'ochre.main'
			          }}
			        >
				    <Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
				      <SpeedDial
				        ariaLabel="Reimo"
				        sx={{ position: 'absolute', bottom: 16, right: 16, }}
				        FabProps={{ sx: {
								      bgcolor: 'info.main',
								      '&:hover': {
								        bgcolor: 'info.main',
								      }
								    }
								  }}
				        icon={<SpeedDialIcon />}
				      >
				          <SpeedDialAction
				            key={"mmm"}
				            tooltipTitle="Innendienst"
				                  icon={
							        <Avatar sx={{ border: "1px solid #007aff"}} src="/assets/jan-schelper.png">
							        </Avatar>
							  }
				          >
				          </SpeedDialAction>
				          <SpeedDialAction
				            key={"mmm"}
				            tooltipTitle="Innendienst"
				                  icon={
							        <Avatar sx={{ border: "1px solid #007aff"}} src="/assets/andreas-stopp.png">
							        </Avatar>
							  }
				          >
				          </SpeedDialAction>
				          <SpeedDialAction
				            key={"mmm"}
				            onClick={e => props.setChatStatus(true)}
				            tooltipTitle="Chat"
			        	    FabProps={{ sx: {
						      bgcolor: 'info.main',
						      '&:hover': {
						        bgcolor: 'info.main',
						      }
						    }
						  }}				            
				                  icon={
							        <Avatar sx={{ border: "1px solid #007aff"}} src="/assets/campy.png">
							        </Avatar>
							  }
				          >
				          </SpeedDialAction>
			
				        {actions.map((action) => (
				          <SpeedDialAction
				            key={action.name}
				            icon={action.icon}
						    onClick={e => handleAction(e, action.id)}
				            tooltipTitle={action.name}
							sx={{fontSize: "48px"}}
			        	    FabProps={{ sx: {
						      bgcolor: 'info.main',
						      '&:hover': {
						        bgcolor: 'info.main',
						      }
						    }
						  }}
			
				          />
				        ))}
				      </SpeedDial>
				      <RequestDate open={requestDate} handleOpen={setRequestDate}/>
			     	  <RequestMaterial open={requestMaterial} handleOpen={setRequestMaterial} car=""/>
				      
				    </Box>
			    </Box>
			</>
		  );
}