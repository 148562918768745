import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';


export default function AlertDialog(props) {

  	const handleClose = () => {
    	props.setDisplayAlert(false);
  	};

  	const { t, i18n } = useTranslation("",{lng: props.language});


  	return (
    	<React.Fragment>
      		<Dialog	open={props.display}
			        onClose={handleClose}
			        aria-labelledby="alert-dialog-title"
			        aria-describedby="alert-dialog-description"
			      >
				<DialogTitle id="alert-dialog-title">
	         		{t("Hinweis")}
	        	</DialogTitle>
	        	<DialogContent>
	          		<DialogContentText id="alert-dialog-description">
						{props.displayAlertMsg}
	         	 	</DialogContentText>
	        	</DialogContent>
	        	<DialogActions>
	          		<Button onClick={handleClose} autoFocus>
		         		{t("OK")}
	          		</Button>
	        	</DialogActions>
      		</Dialog>
		</React.Fragment>
  );
}