import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

	return (<React.Fragment>
				<Dialog fullScreen
		        	  	open={props.open}
		        	  	onClose={props.handleclose}
		        	  	TransitionComponent={Transition}
		      	>
					<AppBar sx={{ position: 'relative' }}>
		          		<Toolbar>
		            		<IconButton edge="start"
		              					color="inherit"
										onClick={props.handleclose}
		              					aria-label="close"
		            		>
		              			<CloseIcon />
		            		</IconButton>
							<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
		              			Bild
		            		</Typography>
		          		</Toolbar>
		        	</AppBar>
	          		<img src={props.image} />
				</Dialog>
			</React.Fragment>
  );
}