import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


import TranslationsTable from './partials/TranslationsTable';


export default function ListLeads(props) {
 
  	return (<>
  				<Paper >
	  				<Box sx={{maxHeight: "80vH", minHeight: "80vH"}}>
						<TranslationsTable />		  					
				    </Box>	   			
				</Paper>

   			</>
  	);
}