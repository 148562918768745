class ActionProvider {
  constructor(createChatBotMessage, setStateFunc) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
  }

  returnResult = (msg) => {
	const message =   this.createChatBotMessage(msg);
    this.addMessageToState(message);
  };

  greet = () => {
    const message = this.createChatBotMessage("Hello friend.");
    this.addMessageToState(message);
  };

  deleiveryInfo = () => {
    const message = this.createChatBotMessage("Aktuell können wir Ihnen nur ungefähre Lieferzeiten nennen. Direkt Lieferbar sind einige wenige Volkwagen T6 Modelle. Möchten Sie hierzu mehr Informationen?");
    this.addMessageToState(message);
  };

  storeOpenInfo = () => {
    const message = this.createChatBotMessage("Unsere Öffnungszeiten sind Montag - Samstag von 9.30 bis 18Uhr.\n Möchten Sie einen Termin mit uns vereinbaren?");
    this.addMessageToState(message);
  };

  handleJavascriptQuiz = () => {
    const message = this.createChatBotMessage(
      "Fantastic. Here is your quiz. Good luck!",
      {
        widget: "javascriptQuiz",
      }
    );

    this.addMessageToState(message);
  };

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message],
    }));
  };
}

export default ActionProvider;
