import React, {useCallback} from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import AirlineSeatFlatIcon from '@mui/icons-material/AirlineSeatFlat';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

import FullSizeImage from '../dialogs/FullSizeImage';


export default function CarCard(props) {
  	let navigate = useNavigate(); 
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const [anchorEl, setAnchorEl] = React.useState(null);
  	const [version, setVersion] = React.useState("kurzer Radstand");
  	const [currentImage, setCurrentImage] = React.useState(0);
  	const [isViewerOpen, setIsViewerOpen] = React.useState(false);  	

  	const open = Boolean(anchorEl);
  	const handleClick = (event) => {
    	setAnchorEl(event.currentTarget);
  	};
  	const handleClose = (which) => {
		setVersion(which);
    	setAnchorEl(null);
  	};
	
	const openImageViewer = useCallback((index) => {
    	setCurrentImage(index);
    	setIsViewerOpen(true);
  	}, []);

  	const closeImageViewer = () => {
    	setCurrentImage(0);
    	setIsViewerOpen(false);
  	};

	const callModel = (url) => {
		navigate(url);
	};

  	return (<>  	
			<Card sx={{ maxWidth: 345 }} className="centerCarCard">
      			<CardHeader avatar={
					          <Avatar sx={{ bgcolor: "#fff" }} aria-label="recipe" src={"/assets/logos/" + props.model.producerLogo}>					           
					          </Avatar>
					        }
        					title={props.model.label}
							subheader={props.model.slogan}
      			/>
      			
      			
      			
				<div class="img-hover-zoom--slowmo">
			      	<CardMedia 	component="img"
			        			height="194"
			        			image={props.model.imagePlain}
			        			alt="Paella dish"
			      	>
					</CardMedia>
			      	<div class="animate__animated animate__fadeInUp" style={{position: "relative", left: "300px", top: "-30px", zIndex: 3, width: "40px", height: "40px"}}>
						<IconButton variant="contained"
									onClick={ e => openImageViewer(0) }
						>			
							<ZoomInIcon sx={{fontSize: '40px', color: 'black'}}/>
						</IconButton>
			      	</div>
		      	</div>
      			<CardContent sx={{paddingTop: "0px !important"}}>
			        <Typography variant="body2" color="text.secondary">
						{t("UVP")}<br/>{t("ab")} {Number(props.model.price).toLocaleString("de-DE", {minimumFractionDigits: 2}) + " €"}
			        </Typography>
					{props.model.models.length > 0 ? (
						<Grid container >
							<Grid item xs={12} sx={{paddingLeft: "0px !important"}}>
								<Grid container spacing={2}  sx={{paddingLeft: "0px !important"}}>
									<Grid item xs={6}  sx={{paddingLeft: "0px !important"}}>
										<Box sx={{width: "100%"}}>
											{t("Version")}<br/>
											<Button id="demo-positioned-button"
											        aria-controls={open ? 'demo-positioned-menu' : undefined}
											        aria-haspopup="true"
											        aria-expanded={open ? 'true' : undefined}
											        onClick={handleClick}
											      >
											        {version}
											</Button>
										      <Menu id="demo-positioned-menu"
										        	aria-labelledby="demo-positioned-button"
										        	anchorEl={anchorEl}
										        	open={open}
										        	onClose={handleClose}
										        	anchorOrigin={{
										          		vertical: 'top',
										          		horizontal: 'left',
										        	}}
										        	transformOrigin={{
										          		vertical: 'top',
										          		horizontal: 'left',
										        	}}
										      >
												{props.model.models.map((variant) => (												        												    												        
											          	<MenuItem 	value={variant.id} 
											          				sx={{fontSize1: "14px" }}  
											          				onClick={e => handleClose(variant.label)}>
											          					{variant.label}
											          	</MenuItem>
											    ))}      
											   </Menu>
											</Box>
										</Grid>
									<Grid item xs={6} sx={{paddingLeft: "0px !important"}}>
								        <Typography variant="body2" color="text.secondary">
											<ul class="empty" style={{listStyleType: "none", marginTop: "0"}}>
												<li><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{props.model.seats} + {props.model.seatsOptional} {t("seats")}</li>
												<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{props.model.sleepingplaces} + {props.model.sleepingplacesOptional} {t("sleepingPlaces")}</li>
												<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{props.model.size.outerLength}cm x {props.model.size.outerHeight}cm x {props.model.size.outerWidth}cm</li>
											</ul>											        			
								        </Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						):(
					        <Typography variant="body2" color="text.secondary">
								<ul class="empty" style={{listStyleType: "none", marginTop: "0"}}>
									<li><AirlineSeatReclineNormalIcon sx={{fontSize: "1.5em"}}/>{props.model.seats} + {props.model.seatsOptional} {t("seats")}</li>
									<li><AirlineSeatFlatIcon sx={{fontSize: "1.5em"}}/>{props.model.sleepingplaces} + {props.model.sleepingplacesOptional} {t("sleepingPlaces")}</li>
									<li><ViewInArIcon sx={{fontSize: "1.5em"}}/>{props.model.size.outerLength}cm x {props.model.size.outerHeight}cm x {props.model.size.outerWidth}cm</li>
								</ul>											        			
					        </Typography>
							
						)}

				</CardContent>
      			<CardActions disableSpacing sx={{align: "center"}}>
        			<Button aria-label="add to favorites"
        					variant="contained"
        					onClick={e => callModel("/model/"+ props.model.url +"/")}
        					endIcon={<TravelExploreIcon />}>
          				{t("entdecken")}
        			</Button>
        			<Button aria-label="share"
        					variant="contained"
        					sx={{marginLeft: "5px"}}
        					onClick={e => callModel("/configure/"+ props.model.url +"/")}
        					endIcon={<SettingsSuggestIcon />}>
          				{t("konfigurieren")}
        			</Button>

      			</CardActions>
    	</Card>
       	<FullSizeImage open={isViewerOpen} handleclose={closeImageViewer} image={props.model.imagePlain}/>

    </>
  );
}