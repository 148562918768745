import * as React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PublishIcon from '@mui/icons-material/Publish';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import DialogForm from './dialogs/DialogForm';
import FileUpload from './dialogs/FileUpload';

import Toaster from './partials/Toaster';
import FilesTable from './partials/FilesTable';

import {requestCarImages, requestPDFs} from "../../helper/utils";


let imageFilesX = await requestCarImages({});
let pdfsFilesX = await requestPDFs({});
let pdfsFiles = {};
pdfsFiles.global = pdfsFilesX;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ListFiles(props) {	
	const [tabValue, setTabValue] = React.useState("1");	
	const [openFileUpload, setOpenFileUpload] = React.useState(false);
	const [open, setOpen] = React.useState(false);
	const [openMsg, setOpenMsg] = React.useState(false);
	const [toasterMsg, setToasterMsg] = React.useState("");	
	const [obj, setObj] = React.useState({});	
	const [objCurrent, setObjCurrent] = React.useState({});
 
   	const handleTabChange = (event, newValue) => {
    	setTabValue(newValue);
  	};
 
   	const openFileUploadDialog = () => {
    	setOpenFileUpload(true);
  	};
 
  	return (<>
  				<Paper >
	  				<Box sx={{maxHeight: "70vH", minHeight: "70vH"}}>
						<TabContext value={tabValue}>
        					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
								<TabList 	onChange={handleTabChange} 
											aria-label="xyz"
											variant="scrollable"
											scrollButtons
											allowScrollButtonsMobile
								>
            						<Tab label="PDF´s" value="1" />
									<Tab label="Auto Bilder" value="2" />
          						</TabList>
        					</Box>
							<TabPanel value="1">
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									<FilesTable files={pdfsFiles}/>					  				
								</Box >	
							</TabPanel>
							<TabPanel value="2">
								<Box sx={{minHeight: "500px", minWidth: "600px"}}>						
									<FilesTable files={imageFilesX}/>					  				
								</Box >	
							</TabPanel>
						</TabContext>
	  				
				    </Box>	   			
					<hr/>
				  	<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
			        	<Typography gutterBottom variant="h6" component="div">
				          	<Button onClick={openFileUploadDialog} 
				          			endIcon={<AddCircleOutlineIcon />}>File hinzufügen</Button>
	      				</Typography>
			          	<Typography gutterBottom variant="h6" component="div">
			          	</Typography>
			        </Stack>


				</Paper>

				<FileUpload open={openFileUpload} tabId={tabValue} handleClose={setOpenFileUpload} models={props.models}/>
				<DialogForm open={open} obj={obj} objCurrrent={objCurrent} handleClose={setOpen} />
				<Toaster open={openMsg} msg={toasterMsg} handleClose={setOpenMsg}/>
   			</>
  	);
}