import * as React from 'react';
import Box from '@mui/material/Box';

export default function CarIcon(props) {

  	return (
			<>
				<Box sx={{padding: 0, margin: 0, width: "100%", height: "50%", display: "inline-flex"}}>
					<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
					 width="45.000000pt" height="23.000000pt" viewBox="0 0 352.000000 180.000000"
					 preserveAspectRatio="xMidYMid meet">
					
						<g transform="translate(0.000000,180.000000) scale(0.100000,-0.100000)"
						fill={props.color} stroke="none">
							<path d="M2280 1660 c-19 -4 -61 -15 -92 -24 -82 -23 -160 -44 -373 -102 -55
							-14 -140 -38 -190 -51 -49 -14 -121 -33 -160 -43 -38 -10 -101 -27 -140 -38
							-38 -11 -124 -34 -190 -52 -66 -18 -176 -48 -245 -67 -69 -19 -154 -39 -190
							-45 -100 -16 -86 4 -150 -208 -17 -58 -35 -115 -41 -128 -5 -13 -9 -158 -9
							-326 l0 -304 33 -6 c17 -3 77 -10 132 -16 156 -16 160 -17 191 -62 15 -21 48
							-51 73 -66 38 -22 58 -27 116 -27 58 0 78 5 117 28 26 15 57 42 69 60 13 17
							28 35 34 39 5 4 256 8 557 8 l546 0 32 -40 c18 -22 53 -52 78 -67 39 -23 58
							-27 117 -27 59 0 78 4 117 27 25 15 56 43 68 62 12 19 31 37 43 39 12 3 40 8
							62 11 87 15 100 18 113 27 9 8 12 59 12 192 l0 181 -101 100 c-104 103 -248
							257 -354 379 -33 38 -70 80 -83 93 l-23 23 -602 1 c-700 1 -742 13 -159 45 72
							4 132 10 132 14 0 4 89 10 198 14 108 3 211 9 229 12 l32 6 3 117 3 116 34 11
							c41 13 63 41 55 68 -12 36 -27 41 -94 26z m-202 -542 c18 -18 16 -232 -2 -247
							-9 -8 -108 -11 -342 -9 l-329 3 -3 119 c-1 66 0 126 2 133 7 18 656 19 674 1z
							m454 -105 c65 -64 118 -125 118 -135 0 -17 -15 -18 -238 -18 -172 0 -241 3
							-250 12 -13 13 -18 239 -5 251 3 4 63 7 131 7 l125 0 119 -117z m-490 -288
							c17 -37 -4 -55 -63 -55 l-49 0 0 40 0 40 50 0 c44 0 52 -3 62 -25z m256 16
							c15 -9 7 -54 -13 -71 -7 -6 -35 -10 -62 -8 -44 3 -48 5 -51 31 -6 49 3 57 60
							57 29 0 59 -4 66 -9z m-1200 -295 c42 -22 72 -60 79 -99 8 -52 -2 -80 -44
							-121 -30 -29 -45 -36 -76 -36 -47 0 -87 20 -122 62 -23 28 -27 39 -23 80 10
							95 108 155 186 114z m1570 -12 c38 -29 62 -77 62 -123 0 -53 -76 -121 -135
							-121 -135 0 -184 180 -67 248 51 30 97 28 140 -4z"/>
						</g>
					</svg>

    				</Box>
    		</>
  );
}