import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import { useTranslation } from 'react-i18next';


import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';





export default function ModelCard(props) {
  	let navigate = useNavigate(); 

	const { t, i18n } = useTranslation("",{lng: props.language});

	const handleChange = (event, code) => {
		try {
			if(event.target.checked === true) {
				props.changeConfiguration(event, "external", code)
				props.setExtCode(code);
			}
			else {
				props.setExtCode("");
				props.changeConfiguration("");
			}
		}
		catch (error) {
				props.setExtCode("");
				props.changeConfiguration("");
		}		
  	};




  	return (
			<Card sx={{ maxWidth: 345 }} className="centerCarCard">
      			<CardHeader avatar={
					           <Avatar sx={{ bgcolor: "#fff" }} aria-label="recipe" src={"/assets/logos/" + props.logo}>
					          </Avatar>
					        }
        					title={props.label}
							subheader=""
      			/>
		      	<CardMedia 	component="img"
		        			height="194"
		        			image="/assets/unkown.png"
		        			alt="Paella dish"
		      	/>
      			<CardContent>
			        <Typography variant="body2" color="text.secondary">

						<FormControl variant="standard">
					        <InputLabel htmlFor="input-with-icon-adornment">
					          {t("ExterneConfiguration")}
					        </InputLabel>
					        <Input 	id="input-with-icon-adornment"					          
					          		value={props.extCode}
								    onChange={(event) => {
								          props.setExtCode(event.target.value);
								    }}
					          		endAdornment={	<InputAdornment position="end">
											            {props.whichConfiguration == "external" && props.extCode != null ? ( 
																										<CheckIcon style={{fill: "green"}} onClick={e => props.getExternalId()}/>
																									) : (
																										<CloudDownloadIcon onClick={e => props.getExternalId(props.extCode, "external")}/>																								 
																									)
														}
										            </InputAdornment>
					          }
					        />
				      	</FormControl>


			        </Typography>
				</CardContent>
      			<CardActions disableSpacing>
					{props.externalConfiguration !== null ? (
						<Checkbox 	checked={props.extCode == props.model.configCode ? true : false }
								  	onClick={e => handleChange(e, props.model.configCode)} />
					): (
						<Checkbox 	disabled={props.externalConfiguration == null ? true : false}
									checked={props.whichConfiguration == "external" ? "true" : ""}
								  	onClick={e => props.changeConfiguration(e, "external")} />
					)}
				        {t("Verwenden")}

        
        

      			</CardActions>
    		</Card>
  );
}