import React from "react";
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import RequestEventDate from '../../dialogs/RequestEventDate';

import latestNews from '../../db/latestNews.json';


export default function LatestNews(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});
	const [requestDate, setRequestDate] = React.useState(false);
	const [event, setEvent] = React.useState();

  	return (<>
				<Box style={{						  			
				  			  	backgroundImage: 'url("assets/latestNewsBG.jpg")',
				  			  	backgroundSize: "cover",
					  			backgroundRepeat: "no-repeat",
					  			display: "inline-block", 
					  			padding: "10px", 
					  			borderRadius: "5px"
					  		}}
					 >
					<Swiper	direction= 'vertical'
							slidesPerView={1}
					        spaceBetween={30}
					        freeMode={true}
					        pagination={{
					          clickable: true,
					        }}
					        modules={[FreeMode, Pagination]}
					        className="myNews"
					        style={{height: "210px",width: "350px"}}
					      >
							{latestNews.map((news) => (
					        	<SwiperSlide>
									<Box style={{display: "inline-block", width: "300px", opacity: 1}}>
										<h2 ><b>{news.headline}</b></h2>
										<h2 >{news.slogan}</h2>
										<Button variant="contained"
												endIcon={<CalendarMonthIcon/>}
												onClick={e => {setRequestDate(true); setEvent(news)}}
										>
											{news.buttonlabel}
										</Button>
									</Box>
					        	</SwiperSlide>
					        ))}

					</Swiper>					
				</Box>
				<RequestEventDate open={requestDate} handleOpen={setRequestDate} event={event}/>
			</>
	  )
}