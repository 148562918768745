import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Chip from '@mui/material/Chip';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

import ContactPersons from "../widgets/ContactPersons";
import Header from "./common/Header2";

import CarWizzard from "../dialogs/CarWizzard";
import CarCard from "../widgets/CarCard";

import models from '../db/models.json';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

const images = [
  'Slide1HeroModelFamilie',
  'Slide1HeroModelFamilie',
  'Slide1HeroModelFamilie'
];


function TabPanel(props) {
	const { children, value, index, ...other } = props;

  	return (
    	<div role="tabpanel"
			 hidden={value !== index}
			 id={`vertical-tabpanel-${index}`}
      		 aria-labelledby={`vertical-tab-${index}`}
      		 {...other}
    	>
      	{value === index && (
        	<Box sx={{ p: 3 }}>
          		<Typography>{children}</Typography>
        	</Box>
      	)}
    	</div>
  	);
}

TabPanel.propTypes = {
  	children: PropTypes.node,
  	index: PropTypes.number.isRequired,
  	value: PropTypes.number.isRequired,
};


function ModelContent(props) {

//models.models.forEach(r => r.rating.forEach(p => p.rating.sort((a, b) => a.Everyday - b.Everyday)));

	const { t, i18n } = useTranslation("",{lng: props.language});
  	const [wizzard, setWizzard] = React.useState(false);
  	const [value, setValue] = React.useState(0);
  	const [filterCars, setFilterCars] = React.useState([]);
  	const [filter, setFilter] = React.useState(['Everyday']);

  	let navigate = useNavigate(); 

  	const handleChange = (event, newValue) => {
    	setValue(newValue);
  	};  	
  	
	const callUrl = (url) => {
		navigate(url);
	};
  	
  	
	const clearFilter = () => {
		setFilter([]);
		setFilterCars([]);
	};
  	
  	
	const handleFilter = (lfilter) => {
		let o = [];
		o.push(lfilter);
		setFilter(o);		
		handleFilterFunction(lfilter);
	};

 	function handleFilterFunction(lfilter) {
		let x = [];
		models.cars.forEach((m, k) => {
			let model;
			models.models.forEach((value, keys) => {
				if(value.url == m.url && value.rating[lfilter] > 3) 
			  		model = value;
		  	})
		  	if(model == undefined ) {
				models.models.forEach((value, keys) => {
					if(value.tabs[3].baseCars != undefined ) {
				  		value.tabs[3].baseCars.forEach((value1, keys1) => {
							if(value1.url == m.url && value.rating[lfilter] > 3)
						  		model = value;
				  		})
				  	}
			  	})
		  	}
		  	if(model != undefined) {
				x.push(m);
			}
		})
		setFilterCars(x);
	  
  	}
//console.log(filterCars);
  	
  	const getCar = (which) => {
		let model;
		models.models.forEach((value, keys) => {
			if(value.url == which)
		  		model = value;
	  })
	  if(model == undefined ) {
			models.models.forEach((value, keys) => {
				if(value.tabs[3].baseCars != undefined ) {
			  		value.tabs[3].baseCars.forEach((value1, keys1) => {
						if(value1.url == which)
					  		model = value;
			  		})
			  	}
		  	})
	  }
	  return model;
  	};	

  	return (<>
			  	<Header heroImages={images} label={t("Kaufberatung")} slogan={t("KaufberatungSlogan")} breadcrumb={props.props.breadcrumb} embedded={props.props.embedded}/>
				
				<Box sx={{ flexGrow: 1 }}
				>

	  				<Box sx={{paddingTop: "0px"}}> 

			      		<Grid container sx={{paddingTop: "0px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={0}>
					        		<Grid item xs={1}>
									</Grid>
					        		<Grid item xs={10}>
								        <Typography gutterBottom variant="h6" component="div" sx={{marginBottom: "20px", marginTop: "20px"}}>
							        		<center>Hier fehlt evtl noch ein Text </center>
								       	</Typography>
										<hr/>
										<Stack className="leftSpacing" direction="row" justifyContent="space-between" alignItems="center">
									    	<Typography gutterBottom variant="h6" component="div">
					
												{filter.length > 0 ? (
													<>
														{filter.map((f) => (
															<>
														      <Chip label={t(f)} variant="outlined" />
															</>	
														))}
														
													</>
												) : (<></>)}
					
					
							      			</Typography>
									        <Typography gutterBottom variant="h6" component="div" sx={{marginBottom: "30px"}}>
									      		<Button onClick={clearFilter} 
									      				variant="contained"
									      				endIcon={<RestartAltIcon />}>{t("Filterzurücksetzen")}</Button>	
									       	</Typography>
									  	</Stack>
									</Grid>
					        		<Grid item xs={1}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						      		
							      		
			      		<Grid container sx={{paddingTop: "0px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={0}>
					        		<Grid item xs={0} sm={0} md={1} lg={1} xl={1}>
									</Grid>
					        		<Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
								        <Typography gutterBottom variant="h6" component="div" sx={{marginBottom: "20px", marginTop: "20px"}}>
							        		{t("MeinePräferenz")}
								       	</Typography>

							      		<Grid container >
							        		<Grid item xs={12}>
									      		<Grid container spacing={3}>
													{["Everyday","CityTrips","SuitableForFamilies","LongerTrips"].map((f) => (
														<>
											        		<Grid item xs={6} sm={3} md={3} lg={3} xl={3} 
											        				sx={{marginRight: "0px"}}>
												        		<Card 	sx={{	minHeight: "160px", 
												        						padding: "10px", 
												        						alignContent: "end",
												        						maxWidth: {
																					xs: 150,
																					sm: 140,  
																					md: 140,  
																					lg: 240,  
																					xl: 320,  
																				}
												        				}} 
												        			className="filterEveryday centerCarCard"
												        		>															
													      			<CardActions disableSpacing 
													      						 sx={{  alignSelf: "stretch",
																					      	display: "flex",
																					      	justifyContent: "flex-end",
																					      	alignItems: "flex-start",
																					      	p: 0,
																				 }}
																	>																														
																		<Button onClick={e => handleFilter(f)} 
																				variant="contained">
																				{t(f)}
																		</Button>
																	</CardActions>
												  				</Card>
															</Grid>
														
														</>														
													))}

												</Grid>
											</Grid>
										</Grid>



									</Grid>
					        		<Grid item xs={0} sm={0} md={1} lg={1} xl={1}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>						
						
			      		<Grid container sx={{paddingTop: "20px"}}>
			        		<Grid item xs={12}>
					      		<Grid container spacing={2}>
					        		<Grid item xs={0} sm={0} md={1} lg={1} xl={2}>
									</Grid>
					        		<Grid item  xs={12} sm={12} md={10} lg={10} xl={8}>

							      		<Grid container >
							        		<Grid item xs={12}>
									      		<Grid container spacing={2}>
													{filterCars.length > 0 ? (<>
														{filterCars.map((model) => (
											        		<Grid item xs={10} sm={6} md={6} lg={6} xl={4}>
											  					<CarCard model={model}/>
															</Grid>
														))}
														</>) : (<>
															{models.cars.map((model) => (
												        		<Grid item  xs={10} sm={6} md={6} lg={6} xl={4}>
												  					<CarCard model={model}/>
																</Grid>
															))}															
														</>)}
												</Grid>
											</Grid>
										</Grid>


									</Grid>
					        		<Grid item  xs={0} sm={0} md={1} lg={1} xl={2}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>						
						
											
						
						
						
		  				<Box sx={{paddingTop: "40px"}}> 
							<ContactPersons profil={props.props.profil}/>
						</Box>
	
					</Box>
  				
					<CarWizzard open={wizzard} handleOpen={setWizzard}/>

   			</Box>
   		</>
   	)
}

export default function ModelFamilie(props) {
	return <ModelContent props={props}/>;
}