import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';

import PersonalInputs from './common/PersonalInputs';
import Alert from './Alert';
import {sendAction} from "../helper/utils";

import models from '../db/models.json';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function RequestDate(props) {
  	const getCar = (which) => {
		let model;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		model = value;
	  })
	  return model;
  	};	

	let cars = [];
	const x = getCar(props.car);
	if(x != undefined)
		cars.push(x.label);	
	else	
		cars.push("Allgemein");
	

  	const [ userData, setUserData] = React.useState();
  	const [ car, setCar] = React.useState(cars);
  	const [ art, setArt] = React.useState('Vor Ort');
  	const [ date, setDate] = React.useState('');
  	const [ alert, setAlert] = React.useState(false);
  	const [ displayAlert, setDisplayAlert] = React.useState(false);
  	const [ openBD, setOpenBD] = React.useState(false);

  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	
  	const handleOpenBD = () => {
    	setOpenBD(true);
  	}; 
  	
	const handleCar = (event) => {
	    const {
	      target: { value },
	    } = event;
	    setCar(
	      typeof value === 'string' ? value.split(',') : value,
	    );
	};

  	const handleArt = (event) => {
    	setArt(event.target.value);
  	};
  	
  	const handleDate = (value) => {
    	setDate(value);
  	};
  
	const { t, i18n } = useTranslation("",{lng: props.language});

    const handleClose = () => {
    	props.handleOpen(false);
  	};

    const handleAlert = (value) => {
    	setAlert(value);
  	};

	async function doSubmit(value)  {				
		let mailAddon = {};
		if(date != "") {
			let o = date.toDate().toLocaleString("de-DE").split(',');
			mailAddon.date = o[0]; 			
		}
		else
			 mailAddon.date = "";

		mailAddon.car = car;  
		mailAddon.art = art;  
		mailAddon.lng = i18n.resolvedLanguage;

		let obj = {};
		obj.mailAddon = mailAddon
		obj.user = userData;

		if(alert == false && obj.user != undefined) {
			setOpenBD(true);
			await sendAction(obj, "requestDate");
	    	props.handleOpen(false);
			setOpenBD(false);	    	
			
	    }
	    else {
			setDisplayAlert(true);
		}
  	}; 
    	

  	return (<React.Fragment>
				<BootstrapDialog 	onClose={handleClose}
									aria-labelledby="customized-dialog-title"
 									open={props.open}
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			{t("Terminvereinbaren")}
	        		</DialogTitle>
			        <IconButton aria-label="close"
			          			onClick={handleClose}
			          			sx={{  	position: 'absolute',
			            				right: 8,
			            				top: 8,
			            				color: (theme) => theme.palette.grey[500],
			          			}}
			        >
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<Typography variant="h6" gutterBottom>
							{t("TerminvereinbarenDescription")}
						</Typography>
	
						<Grid container spacing={3}>
							<Grid item xs={12} sm={12}>
								<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
						        	<InputLabel id="demo-simple-select-standard-label">{t("Fahrzeug")}</InputLabel>
							        <Select
							          labelId="demo-simple-select-standard-label"
							          id="demo-simple-select-standard"
							          value={car}
							          onChange={handleCar}
							          label={t("InteresseAn")}
							          multiple
							        >
						        
							          	<MenuItem value={"Allgemein"}>
							       			{t("Allgemein")}   
							          	</MenuItem>							          
										{models.cars.map((car) => (
											<MenuItem value={car.label}>
									       		{car.label}   
									        </MenuItem>
								        ))}							        
						          
						        	</Select>
						      	</FormControl>
							</Grid>
						</Grid>			        
						<React.Fragment>
      						<Grid container spacing={3}>
        						<Grid item xs={12} sm={6}>

									<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
							        	<InputLabel id="demo-simple-select-standard-label">{t("Art")}</InputLabel>
							        <Select
							          labelId="demo-simple-select-standard-label"
							          id="demo-simple-select-standard"
							          value={art}
							          onChange={e => handleArt(e)}
							          label="Art"
							        >
							          <MenuItem value={"Vor Ort"}>
							       		{t("VorOrt")} 
							          </MenuItem>							          
							          <MenuItem value={"Online Termin"}>
							       		{t("OnlineTermin")} 
							          </MenuItem>
							          <MenuItem value={"Rückruf"}>
							       		{t("Rückruf")} 
							          </MenuItem>

							          
							          
							        </Select>
							      </FormControl>
								</Grid>
        						<Grid item xs={12} sm={6}>
        						{t("MeinWunschtermin")}
								    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
								      <DatePicker 	value={date}
										          	onChange={handleDate}
											      	variant="standard"
													placeholder="ex: DD.MM.AAAA"
											        format="DD.MM.YYYY"
									  />
								    </LocalizationProvider>
								</Grid>
							</Grid>
							
							<PersonalInputs setUserData={setUserData} handleAlert={handleAlert}/>

						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus 
								color="error"
								variant="contained"
								onClick={handleClose}
								endIcon={<CancelIcon />}>
				            {t("verwerfen")}
				        </Button>
						<Button autoFocus 
								variant="contained"
								endIcon={<SendIcon />}
								onClick={doSubmit}>
				            {t("abschicken")}
				        </Button>
			        </DialogActions>
      			</BootstrapDialog>
      			<Alert displayAlertMsg={t("AdviceMailDialog")} display={displayAlert} setDisplayAlert={setDisplayAlert}/>
		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>      			
      			
    </React.Fragment>
  );
}