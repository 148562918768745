import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Link from '@mui/material/Link';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EmailIcon from '@mui/icons-material/Email';
import EngineeringIcon from '@mui/icons-material/Engineering';
import MenuIcon from '@mui/icons-material/Menu';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PublicIcon from '@mui/icons-material/Public';

import ListConfigurations from './backend/ListConfigurations';
import ListLeads from './backend/ListLeads';
import ListModels from './backend/ListModels';
import ListNews from './backend/ListNews';
import ListFiles from './backend/ListFiles';
import ListTranslations from './backend/ListTranslations';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import Dashboard from './backend/Dashboard';
import models from '../db/models.json';

const modelsCurrent = structuredClone(models);  	


const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
  	transition: theme.transitions.create('width', {
    	easing: theme.transitions.easing.sharp,
    	duration: theme.transitions.duration.enteringScreen,
  	}),
  	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  	transition: theme.transitions.create('width', {
    	easing: theme.transitions.easing.sharp,
    	duration: theme.transitions.duration.leavingScreen,
  	}),
  	overflowX: 'hidden',
  	width: `calc(${theme.spacing(7)} + 1px)`,
  	[theme.breakpoints.up('sm')]: {
    	width: `calc(${theme.spacing(8)} + 1px)`,
  	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
  	alignItems: 'center',
  	justifyContent: 'flex-end',
  	padding: theme.spacing(0, 1),
  	// necessary for content to be below app bar
  	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer(props) {
	const theme = useTheme();
  	const [open, setOpen] = React.useState(true);
  	const [whichNavi, setWhichNavi] = React.useState("Dashboard");
  	
  	let navigate = useNavigate(); 
  	

  	const handleDrawerOpen = () => {
    	setOpen(true);
  	};

  	const handleDrawerClose = () => {
    	setOpen(false);
  	};

  	const changeMenu = (x) => {
		if(x == "Logout") {
			props.changeAuth(false);			
			navigate("/login");				

		}
		else {
	    	setWhichNavi(x);
		}
  	};
	
	const navObj = [
					{'type': 'headline','title':'Profil' }, 
					{'title':'Logout', 'icon': <EmailIcon />, 'type': 'item' }, 
					{'type': 'divider' }, 
					{'title':'Dashboard', 'icon': <DashboardIcon />, 'type': 'item',  },
					{'type': 'divider' }, 
					{'type': 'headline','title':'Managment' }, 
					{'title':'Leads', 'icon': <EmailIcon />, 'type': 'item' }, 
					{'title':'Konfigurationen', 'icon': <EngineeringIcon />, 'type': 'item' },
					{'type': 'divider' }, 
					{'type': 'headline','title':'Daten' }, 
					{'title':'Fahrzeuge', 'icon': <DirectionsCarIcon />, 'type': 'item' },
					{'title':'Aktionen', 'icon': <CalendarMonthIcon />, 'type': 'item' },
					{'title':'Dateien', 'icon': <InsertDriveFileIcon />, 'type': 'item' },
					{'title':'Übersetzungen', 'icon': <PublicIcon />, 'type': 'item' },
				   ];


  	return (
    	<Box sx={{ display: 'flex' }}>
      		<CssBaseline />
      		<AppBar position="fixed" open={open}>
        		<Toolbar sx={{background: "rgba(100,100,100,.5)"}}>
          			<IconButton color="inherit"
					            aria-label="open drawer"
					            onClick={handleDrawerOpen}
					            edge="start"
					            sx={{
					              marginRight: 5,
					              ...(open && { display: 'none' }),
					            }}
					>
            			<MenuIcon />
          			</IconButton>
          			<img src="/assets/logo-vanconcept.png" width="100" style={{marginLeft: "30px"}}/>

        		</Toolbar>
      		</AppBar>
      		<Drawer variant="permanent" open={open}>
        		<DrawerHeader>
          			<IconButton onClick={handleDrawerClose}>
			            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          			</IconButton>
        		</DrawerHeader>
        		<Divider />
        		<List>
          			{navObj.map((obj, index) => (
						<>
							{obj.type == 'divider' ? 
						  		(<Divider />) : (<></>)
						  	}
							{obj.type == 'headline' ? 
							  	(<ListItem key={obj.title} disablePadding sx={{ display: 'block' }}>
		                				<ListItemText primary={obj.title} sx={{paddingLeft: "5px", opacity: open ? 1 : 0 }} />
		            			</ListItem>
		            		): (<></>)}
							{obj.type == 'item' ? 
							  	(<ListItem key={obj.title} disablePadding sx={{ display: 'block' }}>
		              				<ListItemButton
		              					onClick={e => changeMenu(obj.title)}
						                sx={{
						                  minHeight: 48,
						                  justifyContent: open ? 'initial' : 'center',
						                  px: 2.5,
						                }}
						             >
						                <ListItemIcon
						                  sx={{
						                    minWidth: 0,
						                    mr: open ? 3 : 'auto',
						                    justifyContent: 'center',
						                  }}
						                >
		                  				{obj.icon}
					                	</ListItemIcon>
		                				<ListItemText primary={obj.title} sx={{ opacity: open ? 1 : 0 }} />
		              				</ListItemButton>
		            			</ListItem>
		            		): (<></>)}
	            		</>
          			))}
        		</List>
        		<Divider />
      		</Drawer>
	      	<Box component="main" sx={{ flexGrow: 1, p: 3 }} >
	        	<DrawerHeader />
	        	
				<Box sx={{mb: 4}}>
						{whichNavi == 'Dashboard' ? (<Typography sx={{ display: 'flex', alignItems: 'center' }}
						          								 color="text.primary"
						    						 >
						        						<DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
						          						Dashboard
						        					 </Typography>								
							) :						
							(<>
								<Breadcrumbs aria-label="breadcrumb">
							        <Link
							          underline="hover"
							          sx={{ display: 'flex', alignItems: 'center' }}
							          color="inherit"
							          onClick={e => setWhichNavi("Dashboard")}
							        >
							          <DashboardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
							          Dashboard
							        </Link>
								 
									<Typography sx={{ display: 'flex', alignItems: 'center' }}
							          			color="text.primary"
							    	>
							        	<EngineeringIcon sx={{ mr: 0.5 }} fontSize="inherit" />
							          		{whichNavi}
							        </Typography>								
							    </Breadcrumbs>

							</>)
						}
      			</Box>
	        	
	        	{whichNavi == "Dashboard" ? (
					<Dashboard models={models} changeMenu={changeMenu}/>
					):(<></>)}
	        	{whichNavi == "Ausbauten" ? (
					<ListModels models={models} type="models" modelsCurrent={{...modelsCurrent}}/>
					):(<></>)}
	        	{whichNavi == "Fahrzeuge" ? (
					<ListModels models={models}  type="cars" modelsCurrent={{...modelsCurrent}}/>
					):(<></>)}
	        	{whichNavi == "Leads" ? (
					<ListLeads />
					):(<></>)}
	        	{whichNavi == "Konfigurationen" ? (
					<ListConfigurations />
					):(<></>)}
	        	{whichNavi == "Aktionen" ? (
					<ListNews />
					):(<></>)}
	        	{whichNavi == "Dateien" ? (
					<ListFiles models={models}/>
					):(<></>)}
	        	{whichNavi == "Übersetzungen" ? (
					<ListTranslations />
					):(<></>)}
					
					
					
	      	</Box>
		</Box>
  );
}