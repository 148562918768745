import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FeatureProducts(props) {
  const [expanded, setExpanded] = React.useState(false);

  return (
	  <Card sx={{ maxWidth: 345 }}>
     	<CardHeader 
	        title={props.title}
	        subheader={props.slogan}
      	/>
		<div class="hover14 column">
			<div>
				<figure>
			    	<CardMedia 	component="img"
			        			height="194"
			        			image={props.image}
			        			alt="Paella dish"
			      	/>
		      	</figure>
		  	</div>
		</div>    
		      
      	<CardContent>
        	<Box  style={{textAlign: "justify", color: "#000"}}>
          		{props.description}
        	</Box>
      	</CardContent>

    </Card>
  );
}