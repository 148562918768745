import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';

import PersonalInputs from './common/PersonalInputs';
import Alert from './Alert';

import {sendAction} from "../helper/utils";

import models from '../db/models.json';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function RequestOffer(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

  	const getCar = (which) => {
		let model;
		models.cars.forEach((value, keys) => {
			if(value.url == which)
		  		model = value;
	  })
	  return model;
  	};	

	let cars = [];
	const x = getCar(props.car);
	if(x != undefined)
		cars.push(x.label);	
	else	
		cars.push("Allgemein");	

  	const [ userData, setUserData] = React.useState();
  	const [ car, setCar] = React.useState(cars);
  	const [ alert, setAlert] = React.useState(false);
  	const [ displayAlert, setDisplayAlert] = React.useState(false);

  	const [ config, setConfig] = React.useState(props.config);
  	const [ openBD, setOpenBD] = React.useState(false);

  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	
  	const handleOpenBD = () => {
    	setOpenBD(true);
  	};  	

    const handleAlert = (value) => {
    	setAlert(value);
  	};

    const handleClose = () => {
    	props.handleOpen(false);
  	};

	async function doSubmit(value)  {
				
		let mailAddon = {};
		mailAddon.car = car;  
		mailAddon.config = config;  
		mailAddon.lng = i18n.resolvedLanguage;

		let obj = {};
		obj.mailAddon = mailAddon
		obj.user = userData;

		if(alert == false && obj.user != undefined) {
			setOpenBD(true);			
			await sendAction(obj, "requestOffer");
	    	props.handleOpen(false);
			setOpenBD(false);
		}
		else {
			setDisplayAlert(true);
		}
  	}; 

  	return (<React.Fragment>
				<BootstrapDialog 	onClose={handleClose}
									aria-labelledby="customized-dialog-title"
 									open={props.open}
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			{t("Angebotanfordern")}
	        		</DialogTitle>
			        <IconButton aria-label="close"
						      	onClick={handleClose}
						        sx={{ position: 'absolute',
						              right: 8,
						              top: 8,
						              color: (theme) => theme.palette.grey[500],
						        }}
			        >
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<React.Fragment>
 							<Typography variant="h6" gutterBottom>
        						{t("AufBasisIhrerKonfiguration", {config: config})}
      						</Typography>							

							<PersonalInputs setUserData={setUserData} handleAlert={handleAlert}/>							
						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus 
								color="error"
								variant="contained"
								onClick={handleClose}
								endIcon={<CancelIcon />}>
				            {t("verwerfen")}
				        </Button>
						<Button autoFocus 
								variant="contained"
								endIcon={<SendIcon />}
								onClick={doSubmit}>
				            {t("abschicken")}
				        </Button>				        
			        </DialogActions>
      			</BootstrapDialog>
      			<Alert displayAlertMsg={t("AdviceMailDialog")} display={displayAlert} setDisplayAlert={setDisplayAlert}/>

		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>        			
    </React.Fragment>
  );
}