import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Document, Page, pdfjs   } from "react-pdf";


import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoneIcon from '@mui/icons-material/Done';


import {requestConfigPdf} from "../../../helper/utils";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
    	padding: theme.spacing(2),
  	},
  	'& .MuiDialogActions-root': {
    	padding: theme.spacing(1),
  	},
}));

export default function RequestOffer(props) {
  	const [ config, setConfig] = React.useState(props.config);
  	const [ openBD, setOpenBD] = React.useState(false);
  	const [ step, setStep] = React.useState(0);
  	const [ pdf, setPdf] = React.useState(props.pdf);
  	const [ currentPage, setCurrentPage] = React.useState(1);
  	const [ pageNumbers, setPageNumbers] = React.useState(0);
	
  	const handleCloseBD = () => {
    	setOpenBD(false);
  	};
  	 

	const { t, i18n } = useTranslation("",{lng: props.language});

    const handleClose = () => {
    	props.handleOpen(false);
  	};

 	  	
    const handlePDFStepBack = (e) => { 
        if(currentPage > 1)
            setCurrentPage((currentPage - 1));       
    }

    const handlePDFStepForward = (e) => { 
        if(currentPage < pageNumbers)
            setCurrentPage((currentPage + 1));       
    }  	  	
  	  	
  	const onDocumentLoadSuccess = ({ numPages }) => {
    	setPageNumbers(numPages);
  	};  


  	return (<React.Fragment>
				<BootstrapDialog 	onClose={handleClose}
									aria-labelledby="customized-dialog-title"
 									open={props.open}
      			>
	        		<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
	          			Konfiguration: {props.configCode}
	        		</DialogTitle>
			        <IconButton aria-label="close"
			          			onClick={handleClose}
						        sx={{
						            position: 'absolute',
						            right: 8,
						            top: 8,
						            color: (theme) => theme.palette.grey[500],
						        }}
					>
	          			<CloseIcon />
	        		</IconButton>
			        <DialogContent dividers>
						<React.Fragment>
                        	{t("Vorschau")}:  
	                        <IconButton variant="contained"
										onClick={e => handlePDFStepBack(e)}
							>			
								<ArrowBackIosIcon sx={{fontSize: '20px', color: 'black'}}/>
							</IconButton>
	                        <IconButton variant="contained"
										onClick={e => handlePDFStepForward(e)}
							>			
								<ArrowForwardIosIcon sx={{fontSize: '20px', color: 'black'}}/>
							</IconButton>

                            {t("Seite")} {currentPage} {t("von")} {pageNumbers} 					                        
							<Paper style={{overflow: "hide"}}> 
								<div style={{overflow: "scroll"}}>					                                       
		                            <Document file={"data:application/pdf;base64," + props.pdf}
										onLoadSuccess={onDocumentLoadSuccess}
		                            >
		                                <Page pageNumber={currentPage}  renderTextLayer={false}/>
		                            </Document>
								</div>					                                       
	                        </Paper>
						</React.Fragment>

			        </DialogContent>
	        		<DialogActions>
						<Button autoFocus 
								variant="contained"
								endIcon={<DoneIcon />}
						>
				            {t("OK")}
				        </Button>				        
			        </DialogActions>
      			</BootstrapDialog>
		      	<Backdrop
			        sx={{ color: '#fff', zIndex: 8000 }}
			        open={openBD}
			        onClick={handleCloseBD}
			      >
			        <CircularProgress color="inherit" />
			    </Backdrop>      			
      			
    </React.Fragment>
  );
}