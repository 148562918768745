import * as React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import CarIcon from "../widgets/CarIcon";


export default function Usefull(props) {
	const { t, i18n } = useTranslation("",{lng: props.language});

	const points = [{key: "Everyday"}, {key: "CityTrips"}, {key: "SuitableForFamilies"}, {key: "LongerTrips"}];
	const colors = ["#ffd100","#ffd100","#ffd100","#ffd100","#ffd100"];

  	return (
			<>
				{points.map((point, id) => (
					<Box>
						<Box sx={{padding: 0, margin: 0,  display: "inline-flex"}}>
							<Box display="flex" justifyContent="flex-start">
								<Stack spacing={{ xs: 1, sm: 2, marginBottom: "10px" }} justify="flex-end" direction="row" useFlexGap flexWrap="wrap">
									<Box sx={{fontSize: "18px", width: "150px"}}>
										{t(point.key)}
									</Box>
									<Box>
										{colors.map((color, i) => (									
											<>
												<Box sx={{	padding: 0, 
															margin: 0, 
															display: "inline-flex",
															width: {
																		xs: '31px',
																		sm: '62px',  
																		md: '62px',  
																		lg: '62px',  
																		xl: '62px',  
																		
															},
															height: {
																		xs: '16px',
																		sm: '32px',  
																		md: '32px',  
																		lg: '32px',  
																		xl: '32px',  
																		
															}}}>
													{i < props.rating[point.key] ? (<CarIcon color={color}/>) : (<CarIcon color="#ddd"/>)}												
												</Box>
											</>
										))}
									</Box>
								</Stack>
							</Box>
						</Box>
					</Box>
				))}
    		</>
  );
}