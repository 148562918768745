import * as React from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import RequestDate from '../dialogs/RequestDate';
import RequestMaterial from '../dialogs/RequestMaterial';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DownloadIcon from '@mui/icons-material/Download';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SendIcon from '@mui/icons-material/Send';

export default function ActionCard(props) {
  	let navigate = useNavigate(); 
	const { t, i18n } = useTranslation("",{lng: props.language});

	const [requestDate, setRequestDate] = React.useState(false);
	const [requestMaterial, setRequestMaterial] = React.useState(false);
  	
	const callUrl = (url) => {
		if(url == "requestDate")
			setRequestDate(true);
		else if(url == "requestMaterial")
			setRequestMaterial(true);
		else {
			navigate(url);
			window.scrollTo(0, 0);
		}
	};


  	return (<>
				<Card sx={{ maxWidth: "100%",  border: "none", boxShadow: "none"}} className="centerCarCard">
					<Box sx={{width: "40px", height: "40px", float: "right"}}>
						{props.usp == 1 ? (<NewspaperIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
						{props.usp == 2 ? (<DownloadIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
						{props.usp == 3 ? (<CalendarMonthIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}
						{props.usp == 4 ? (<EngineeringIcon sx={{width: "2em", height: "2em"}}/>) : (<></>)}						
					</Box>
	
	      			<CardHeader titleTypographyProps={{variant:'h6' }}
	        					title={props.header}
								subheader=""
	      			/>
	      			<CardContent>
				        <Typography variant="body2" color="text.secondary">
							{props.description}
				        </Typography>
					</CardContent>
					<CardActions sx={{  alignSelf: "stretch",
								      	display: "flex",
								      	justifyContent: "flex-end",
								      	alignItems: "flex-start",
								      	p: 0,
					}}>
						<Button onClick={e => callUrl(props.actionurl)} 
								variant="contained"
								endIcon={props.usp == 4 ? (<SettingsSuggestIcon/>) :(<SendIcon/>)}>
								{t("aufrufen")}
						</Button>
						
						
						
					</CardActions>
	    		</Card>
	     	   	<RequestDate open={requestDate} handleOpen={setRequestDate} car={props.car}/>
	     	   	<RequestMaterial open={requestMaterial} handleOpen={setRequestMaterial} car={props.car}/>
			</>
  );
}